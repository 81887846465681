/* eslint-disable no-unused-vars */
import { getToken } from '@/libs/firebase'
import _ from 'lodash'
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import config from '@/config'

class NodeApi {
  /** @type {AxiosInstance} */
  axios

  constructor (url) {
    this.axios = axios.create({
      baseURL: url || config.api,
    })

    this.axios.interceptors.request.use(
      async config => {
        config.headers.Authorization = `TOKEN ${await getToken()}`
        return config
      },
      error => error,
    )
  }

  /**
   * @param {string} username
   * @param {string} password
   * @param {string} role
   * @return {Promise<{uid: string}>}
   */
  createUser = async (username, password, role) => {
    const response = await this.axios.post('a/user', {
      username,
      password,
      role,
    })

    return response.data
  }

  /**
   * @param {string} id
   * @param {{ password?: string, role?: string }} params
   * @return {bool}
   */
  updateUser = async (id, params) => {
    const response = await this.axios.put(`a/user/${id}`, params)
    return response.data
  }

  /**
   *
   * @param {string} id
   * @returns
   */
  deleteUser = async (id) => {
    const response = await this.axios.delete(`a/user/${id}`)
    return response.data
  }

  /**
   * @return {{ data: IUser[] }}
   */
  getAllUsers = async () => {
    const response = await this.axios.get('a/user')
    return response.data
  }

  /**
   * @param {string} settingType
   * @return {{ enable?: number | boolean, remark?: string, delay: string }}
   */
  getSystemSettings = async (settingType) => {
    const response = await this.axios.get(`a/system/${settingType}`)
    return response.data
  }

  /**
   * @param {string} settingType
   * @param {object} params
   */
  putSystemSettings = async (settingType, params) => {
    await this.axios.put(`a/system/${settingType}`, params)
  }

  /**
   * @param {{mobile: string, amount: number, memo: string}} params
   */
  refillRiceCoin = async (params) => {
    await this.axios.post('a/refillricecoin', params)
  }

  /**
   * @param {string} merchantId
   * @return {ThunkFunction}
   */
  getMerchantQRcode = async (merchantId) => {
    const token = await getToken()
    const url = `${this.axios.defaults.baseURL}a/merchant/${merchantId}/qrcode?token=${token}`
    window.open(url)
  }

  /**
   * @param {{source: string, target: string}} params
   * @return {object}
   */
  copyMenu = async (params) => {
    const response = await this.axios.get('a/clone_merchant', { params })
    return response.data
  }

  /**
   * @param {{ merchantId: string }} params
   * @return {Blob}
   */
  exportMenu = async (params) => {
    const response = await this.axios.get('report/menu', { params, responseType: 'blob' })
    return response.data
  }

  /**
   * @return {{ data: IUser }}
   */
    getMe = async () => {
      const response = await this.axios.get('a/user/me')
      return response.data
    }
}

export default new NodeApi()
