import { CssBaseline } from '@material-ui/core'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { createBrowserHistory } from 'history'
import React from 'react'
import 'rsuite/dist/styles/rsuite-default.css'

import Main from '@/pages/Main'
import store from '@/redux/store'
import theme from '@/theme'

function App () {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={createBrowserHistory()}>
          <Main />
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default App
