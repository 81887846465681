// import { Switch } from 'react-router-dom'
import React from 'react'

import { routes } from '@/libs/routes'
import Router from '@/components/Router'

export default function AppRouter (props) {
  return (
    <Router routes={routes} />
  )
}
