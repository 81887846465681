import { TagPicker } from 'rsuite'
import { actions, useDispatch, useSelector } from '@/redux'
import React from 'react'

/**
 *
 * @param {{
 * data: unknown
 * isUpdate: () => void
 * }} props
 * @returns
 */
export default function TagSelector (props) {
  const { data, isUpdate = () => {} } = props
  const dispatch = useDispatch()
  const [tags, setTags] = React.useState([])
  const tagList = useSelector(state => state.customer.tags.data)

  React.useEffect(() => {
    if (data.tags) {
      setTags(data.tags.map(tag => tag.id))
    }
  }, [data.tags])
  React.useEffect(() => {
    if (tagList.length === 0) {
      dispatch(actions.customer.getTags())
    }
  }, [])
  const tagData = tagList.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
    }
  })

  const handleChangeSelect = (e) => {
    isUpdate(true)
    if (!e) {
      dispatch(actions.customer.deleteAllUserTags(data.id, tags))
      setTags([])
      return
    }
    const addItem = e.find(tag => !tags.includes(tag))
    const delItem = tags.find(tag => !e.includes(tag))
    if (addItem) {
      if (tagList.find(tag => tag.id === addItem)) {
        dispatch(actions.customer.addUserTags(data.id, addItem))
        setTags(e)
      } else {
        dispatch(actions.customer.addTags(addItem)).then(response => {
          dispatch(actions.customer.addUserTags(data.id, response.id))
          setTags([...tags, response.id])
        })
      }
    }
    if (delItem) {
      dispatch(actions.customer.deleteUserTags(data.id, delItem))
      setTags(e || [])
    }
  }

  return (
    <TagPicker onChange={handleChangeSelect} value={tags} creatable data={tagData} style={{ width: 300 }} menuStyle={{ width: 300 }} />
  )
}
