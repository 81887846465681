import { actions, useDispatch } from '@/redux'
import AddIconButton from '@/components/IconButton/AddIconButton'
import AddPosPayLinkDialog from './AddPosPayLinkDialog'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import PosPayLinkSearchForm from './PosPayLinkSearchForm'
import PosPayLinkTable from './PosPayLinkTable'
import React from 'react'
export default function PosPayLink (props) {
  const dispatch = useDispatch()
  const handleOpenDialog = () => {
    dispatch(actions.app.toggleDialog('addPayLink', null, {}))
  }

  return (
    <Page>
      <PageHeader text='Pay Link'>
        <AddIconButton color='primary' onClick={handleOpenDialog} />
      </PageHeader>
      <PosPayLinkSearchForm />
      <PosPayLinkTable />
      <AddPosPayLinkDialog />
    </Page>
  )
}
