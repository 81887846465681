import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IDashboardState} */
export const initialState = {
  isInit: false,
  data: {
    rate: [],
  },
  registeredUsers: [],
  activatedUsers: [],
}

export default produce(
  /**
   *  @param {IDashboardState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_DATA: {
        const { data } = action.payload
        data.rate = Object.keys(data.rate).map(r => {
          let keyString = r + '次(' + (data.rate[r] * 100).toFixed(2) + '%)'
          if (r === '10') {
            keyString = r + '次以上(' + (data.rate[r] * 100).toFixed(2) + '%)'
          }
          return {
            key: keyString,
            value: data.rate[r],
          }
        })
        draft.data = data
        break
      }
      case ActionTypes.UPDATE_REGISTERED_USERS: {
        const { data } = action.payload
        draft.registeredUsers = data
        break
      }
      case ActionTypes.UPDATE_ACTIVATED_USERS: {
        const { data } = action.payload
        draft.activatedUsers = data
        break
      }
      default:
        break
    }
  },
  initialState,
)
