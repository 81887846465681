import ActionTypes from './ActionTypes'
import AppActionTypes from '../app/ActionTypes'
import _ from 'lodash'
import quickPayApi from '@/libs/api/quickPay'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @param {IPosPayLinkQueryParams?} queryParams
 * @returns {ThunkFunction}
 */
export function getPosPayLinks (queryParams) {
  const apiName = 'getPosPayLinks'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      let params = _.isEmpty(queryParams)
        ? { ...getState().posPayLink.params }
        : { ...queryParams }
      params = _.omitBy(params, v => !v)
      const { data, total } = await quickPayApi.list(params)
      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { total },
      })
    } catch (error) {
      console.log('getPosPayLinks error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 * @param {{
 * merchantName: string
 * plan: string
 * period: string
 * amount: string
* }}
 * @returns {ThunkFunction}
 */
export function createPosPayLink (params) {
  const apiName = 'createUser'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      await quickPayApi.create(params)
    } catch (error) {
      console.log('createUser error', error)
      throw error
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 * @function
 * @param {IPosPayLinkQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}
