import { Table as MuiTable, Paper, TableContainer, TableFooter, TablePagination, TableRow } from '@material-ui/core'
import { usePagination, useTable } from 'react-table'
import PropTypes from 'prop-types'
import React from 'react'
import TableBody from './TableBody'
import TableHead from './TableHead'

Table.prototypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
}

/**
 *
 * @param {{
 *  columns: ITableColumn[]
 *  data: any[]
 * }} props
 * @returns
 */
export default function Table (props) {
  const { columns, data } = props
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, gotoPage, state: { pageIndex, pageSize } } = useTable({ columns, data }, usePagination)

  const onPageChange = (event, pageIndex) => {
    gotoPage(pageIndex)
  }

  return (
    <TableContainer component={Paper}>
      <MuiTable {...getTableProps()} stickyHeader>
        <TableHead headerGroups={headerGroups} />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rows={page}
        />
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={onPageChange}
            />
          </TableRow>
        </TableFooter>
      </MuiTable>
    </TableContainer>
  )
}
