import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IDriverState} */
const initialState = {
  isInit: false,
  data: [],
}

export default produce(
  /**
   * @param {IDriverState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_DATA: {
        const { data } = action.payload
        draft.data = data
        break
      }
      default:
        break
    }
  },
  initialState,
)
