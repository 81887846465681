import { Button } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React from 'react'

export default function ConfirmDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open, data } = useDialog('confirmDialog')
  const title = data.title || 'Warn'
  const content = data.content || 'Are you sure?'
  const handleSubmit = async () => {
    if (data.confirmCallback) {
      data.confirmCallback()
    }
    handleClose()
  }

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('confirmDialog', false, data))
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={title}
      renderActions={() => {
        return (
          <>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={handleSubmit}
            >
              OK
            </Button>
            <Button
              variant='contained'
              disableElevation
              onClick={handleClose}
            >
              Cancel
            </Button>
          </>
        )
      }}
    >
      <div className={classes.form} style={{ ...(data.formProps?.style ?? {}) }}>
        <div>{content}</div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: '75px auto',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    alignItems: 'center',
    minWidth: '250px',
  },
}))
