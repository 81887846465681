import React from 'react'

import InputControl from './InputControl'
import MultiSelectControl from './MultiSelectControl'
import SelectControl from './SelectControl'
import SwitchControl from './SwitchControl'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SettingControl (props) {
  const { setting } = props

  switch (setting.type) {
    case 'select':
      return <SelectControl setting={setting} />

    case 'input':
      return <InputControl setting={setting} />

    case 'textarea':
      return <InputControl setting={setting} options={{ multiline: true, rows: 3 }} />

    case 'switch':
      return <SwitchControl setting={setting} />

    case 'multiSelect':
      return <MultiSelectControl setting={setting} />

    case 'subtitle':
      return <div>{setting.label}</div>

    default:
      return <div>Missing type</div>
  }
}
