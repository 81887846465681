import _ from 'lodash'
import moment from 'moment'

import AppActionTypes from '@/redux/app/ActionTypes'
import goAdminApi from '@/libs/api/goAdmin'
import * as appActions from '@/redux/app/actions'

import ActionTypes from './ActionTypes'

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * 沒傳 queryParams 的話預設使用 redux 的 promoCode.params
 * @param {IPromoCodeQueryParams?} queryParams
 * @returns {ThunkFunction}
 */
export function getPromoCodes (queryParams) {
  const apiName = 'getPromoCodes'
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      const params = _.isEmpty(queryParams)
        ? { ...getState().promoCode.params }
        : { ...queryParams }
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })
      const response = await goAdminApi.getPromoCodes(params)
      const { data, total } = response

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { total },
      })
    } catch (error) {
      console.log('getPromoCodes error', error)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

/**
 * @param {IPromoCodeQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}

function inputStatePromoCodeToRequestPromoCode (promoCode) {
  let orderAmountNum = null
  if (!isNaN(promoCode.orderAmount) && promoCode.orderAmount !== null) {
    if (promoCode.orderAmount === '0' || promoCode.orderAmount === 0) {
      orderAmountNum = 0
    } else {
      orderAmountNum = Number(promoCode.orderAmount)
    }
  }
  return {
    ...promoCode,
    amount: Number(promoCode?.amount) || undefined,
    percent: Number(promoCode?.percent) || undefined,
    maxAmount: Number(promoCode?.maxAmount) || undefined,
    usedTime: Number(promoCode?.usedTime) || undefined,
    quota: Number(promoCode?.quota) || 1,
    quotaPerUser: Number(promoCode?.quotaPerUser) || undefined,
    minOrderAmount: Number(promoCode?.minOrderAmount) || undefined,
    orderAmount: orderAmountNum,
    quotaPerTime: Number(promoCode?.quotaPerTime) || null,
    deliveryType: promoCode?.deliveryType === 'ALL' ? undefined : promoCode?.deliveryType,
    validFrom: promoCode?.validFrom ? moment(promoCode?.validFrom).format() : undefined,
    validUntil: promoCode?.validUntil ? moment(promoCode?.validUntil).format() : undefined,
    claimCouponFrom: promoCode?.claimCouponFrom ? moment(promoCode?.claimCouponFrom).format() : undefined,
    claimCouponUntil: promoCode?.claimCouponUntil ? moment(promoCode?.claimCouponUntil).format() : undefined,
    claimDeliveryType: promoCode?.claimDeliveryType === 'ALL' ? undefined : promoCode?.claimDeliveryType,
    action: {
      type: promoCode?.action?.type === 'NONE' ? undefined : promoCode?.action?.type,
      payload: promoCode?.action?.payload || undefined,
    },
  }
}

/**
 *
 * @param {IPromoCode} promoCode
 * @returns {ThunkFunction}
 */
export function createPromoCode (promoCode) {
  const apiName = 'createPromoCode'
  const params = inputStatePromoCodeToRequestPromoCode(promoCode)
  const { type } = promoCode
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      const promoCode = await goAdminApi.createPromoCode(params)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Success', content: `${type === 'COUPON' ? 'Coupon' : 'PromoCode'} successfully created.` }))
      return promoCode
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.log('createPromoCode error', error)
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

/**
 *
 * @param {IPromoCode} promoCode
 * @returns {ThunkFunction}
 */
export function updatePromoCode (promoCode) {
  const apiName = 'updatePromoCode'
  const params = inputStatePromoCodeToRequestPromoCode(promoCode)
  const { type } = promoCode
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      const promoCode = await goAdminApi.updatePromoCode(params)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Success', content: `${type === 'COUPON' ? 'Coupon' : 'PromoCode'} successfully updated.` }))
      return promoCode
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.log('updatePromoCode error', error)
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

/**
 *
 * @param {IPromoCode} promoCode
 * @returns {ThunkFunction}
 */
export function deletePromoCode (promoCode) {
  const apiName = 'deletePromoCode'
  const { id, type } = promoCode
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      await goAdminApi.deletePromoCode(id)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Success', content: `${type === 'COUPON' ? 'Coupon' : 'PromoCode'} successfully deleted.` }))
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.log('updatePromoCode error', error)
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

/**
 *
 * @param {string} id
 * @param {File} fileToUpload
 * @returns {ThunkFunction}
 */
export function uploadPromoCodeImage (id, fileToUpload) {
  const apiName = 'uploadPromoCodeImage'
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      const promoCode = await goAdminApi.uploadPromoCodeImage(id, fileToUpload)
      return promoCode
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.log('updatePromoCode error', error)
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

export function deletePromoCodeImage (id) {
  const apiName = 'deletePromoCodeImage'
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      const promoCode = await goAdminApi.deletePromoCodeImage(id)
      return promoCode
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.log('updatePromoCode error', error)
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

/**
 *
 * @returns {ThunkFunction}
 */
export function exportPromoCodes () {
  return async (dispatch, getState) => {
    try {
      const params = { ...getState().promoCode.params }
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })
      const response = await goAdminApi.getPromoCodes({
        ...params,
        limit: getState().promoCode.data.total,
      })
      const promoCodes = response.data

      const headers = [
        // basic settings
        'type',
        'code',
        'offeredBy',
        'applyTo',
        'deliveryType',
        'merchantId',
        'merchantName',

        // tag settings
        'tags',

        // amount settings
        'amount',
        'percent',
        'maxAmount',
        'minOrderAmount',

        // quota settings
        'usedTime',
        'quota',
        'quotaPerUser',

        // valid settings
        'validFrom',
        'validUntil',

        // coupon claim settings
        'claimCouponFrom',
        'claimCouponUntil',

        // coupon settings
        'name_zh',
        'name_en',
        'tnc_zh',
        'tnc_en',
        'tnc_url',
        'action_type',
        'action_payload',

        // other settings
        'remarks',
        'image',
      ]
      const th = _.join(headers, ';')
      const rows = _.map(promoCodes, promoCode => {
        /** @type {IPromoCode} */
        const p = promoCode
        const columns = [
          // basic settings
          p.type,
          p.code,
          p.offeredBy,
          p.applyTo,
          p.deliveryType,
          p.merchantId,
          p.merchantName,

          // tag settings
          p.tags ? _.join(p.tags, ', ') : undefined,

          // amount settings
          p.amount,
          p.percent,
          p.maxAmount,
          p.minOrderAmount,

          // quota settings
          p.usedTime,
          p.quota,
          p.quotaPerUser,

          // valid settings
          p.validFrom ? moment(p.validFrom).format('YYYY-MM-DD HH:mm:ss') : undefined,
          p.validUntil ? moment(p.validUntil).format('YYYY-MM-DD HH:mm:ss') : undefined,

          // coupon claim settings
          p.claimCouponFrom ? moment(p.claimCouponFrom).format('YYYY-MM-DD HH:mm:ss') : undefined,
          p.claimCouponUntil ? moment(p.claimCouponUntil).format('YYYY-MM-DD HH:mm:ss') : undefined,

          // coupon settings
          p.name?.zh,
          p.name?.en,
          p.tnc?.zh,
          p.tnc?.en,
          p.tnc?.url,
          p.action?.type,
          p.action?.payload,

          // other settings
          p.remarks,
          p.image,
        ]

        const row = _
          .chain(columns)
          .map(col => {
            if (col == null) return ''
            const value = String(col)
            return _.chain(value).replace('\n', '').replace(';', ',').value() // avoid csv display error
          })
          .join(';')
          .value()
        return row
      })

      const csv = _.join([th, ...rows], '\n')
      const file = new Blob(['\uFEFF' + csv], { type: 'application/csv;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = 'promocodes.csv'
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (error) {
      console.log('exportPromoCodes error', error)
    }
  }
}
