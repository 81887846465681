/* eslint-disable no-unused-vars */
import { camelCase, snakeCase } from 'change-case'
import _ from 'lodash'
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { getToken } from '@/libs/firebase'
import changeKeys from '@/libs/changeKeys'
import config from '@/config'

class CustomerApi {
  /** @type {AxiosInstance} */
  axios

  constructor (url) {
    this.axios = axios.create({
      baseURL: url || config.customerApi,
    })

    this.axios.interceptors.request.use(
      async config => {
        config.data = changeKeys(config.data, snakeCase)
        config.params = changeKeys(config.params, snakeCase)
        config.headers.Authorization = `TOKEN ${await getToken()}`
        return config
      },
      error => error,
    )

    this.axios.interceptors.response.use(
      response => {
        response.data = changeKeys(response.data, camelCase)
        return response
      },
    )
  }

  /**
   * @param {IRiceCoinQueryParams} params
   * @returns {Promise<PaginationResponseData<ILedger>>}
   */
  getLedgers = async (params) => {
    /** @type {AxiosResponse<PaginationResponseData<ILedger>>} */
    const response = await this.axios.get('a/ledgers', {
      params,
    })
    if (response.data.data) {
      response.data.data = response.data.data.map(ledger => changeKeys(ledger, camelCase))
    }
    return response.data
  }

  /**
   * @param {{mobile: string, amount: number, memo: string}} params
   */
  refillRiceCoin = async (params) => {
    await this.axios.post('a/ledgers', params)
  }

  /**
   * @param {ICustomerQueryParams} params
   * @returns {Promise<PaginationResponseData<ICustomer>>}
   */
  getCustomers = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/users', {
      params,
    })
    response.data.data = response.data.data.map(customer => changeKeys(customer, camelCase))

    return response.data
  }

  /**
   * @returns {IStatistics}
   */
  getStatistics = async (params) => {
    const response = await this.axios.get('a/statistics', {
      params,
    })
    return response.data
  }

  /**
   * @param {} params
   * @returns {}
   */
  getActivatedUsers = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/statistics/activated-users', {
      params,
    })
    return response.data
  }

  /**
   * @param {} params
   * @returns {}
   */
  getRegisteredUsers = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/statistics/registered-users', {
      params,
    })
    return response.data
  }

  /**
   * @param {{userId: string}} params
   * @returns {}
   */
  getDeliveryTypes = async (params) => {
    /** @type {} */
    const response = await this.axios.get('/a/statistics/delivery-types', {
      params,
    })
    return response.data
  }

  /**
   * @returns {Promise<PaginationResponseData<ITag>>}
   */
  getTags = async () => {
    const response = await this.axios.get('a/tags')
    return response.data
  }

  /**
   * @param {{name: string}} params
   * @returns {{id: string, name: string}}
   */
  addTags = async (params) => {
    const response = await this.axios.post('a/tags', params)
    return response.data
  }

  /**
   * @param {string} tagId
   */
  deleteTag = async (tagId) => {
    await this.axios.delete(`a/tags/${tagId}`)
  }

  /**
   * @param {string} userId
   * @param {{tag_ids: string}} params
   */
  addUserTags = async (userId, params) => {
    await this.axios.post('a/users/' + userId + '/tags', params)
  }

  /**
   * @param {string} userId
   * @param {string} tagsId
   */
  deleteUserTags = async (userId, tagsId) => {
    await this.axios.delete('a/users/' + userId + '/tags/' + tagsId)
  }

  /**
   * @param {{text: string}} params
   */
  sentNotification = async (params) => {
    return await this.axios.post('a/notifications/', params)
  }

  /**
   * @param {ISentSMSParam} params
   */
  sentSMS = (params) => {
    return this.axios.post('/a/notifications', params)
  }

  /**
   * @param {ISentSMSParam} params
   */
  getSMSInformation = async (params) => {
    return await this.axios.post('/a/notifications/inquiry', params)
  }

  /**
   * @param {ISentSMSParam} params
   */
  getSMS = async (params) => {
    const response = await this.axios.get('/a/notifications', { params })
    return response.data
  }

  /**
   * @param {string} id
   */
  getSMSResult = async (id) => {
    const params = {
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }
    const response = await this.axios.get('/a/notifications/' + id + '/results', { params })
    return response.data
  }

  /**
   * @param {{startedAt: string, endedAt: string}} params
   */
  getSMSOutbound = async (params) => {
    const response = await this.axios.get('a/statistics/outbound-sms', { params })
    return response.data
  }

  /**
   * @param {ICustomerQueryParams} params
   * @returns {Promise<PaginationResponseData<ICustomer>>}
   */
  getRefunds = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/refunds', {
      params,
    })
    // response.data.data = response.data.data.map(customer => changeKeys(customer, camelCase))
    return response.data
  }

  /**
   * @param {{mobile: string, amount: number, memo: string}} params
   */
  postRefund = async (params) => {
    await this.axios.post('a/refunds', params)
  }

  /**
   * @param { string } refundId
   * @param {{status: string}} params
   */
  approveRefund = async (refundId, params) => {
    await this.axios.patch(`a/refunds/${refundId}`, params)
  }
}

export default new CustomerApi()
