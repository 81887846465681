import { toggleDialog } from '@/redux/app/actions'
import ActionTypes from './ActionTypes'
import goAdminApi from '@/libs/api/goAdmin'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @param {ICreateJobParams} param
 * @returns {ThunkFunction}
 */
export function createJob (param) {
  return async () => {
    try {
      return (await goAdminApi.createJob(param)).status === 204
    } catch (error) {
      console.log('createJob error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getReportJobs () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getReportJobs' },
    })
    try {
      const params = { ...getState().report.job.params, limit: getState().report.job.meta.perPage }
      Object.keys(params).forEach(key => {
        if (params[key] === '') {
          delete params[key]
        }
      })
      const response = await goAdminApi.getReportJobs(params)
      const { data, total } = response
      dispatch({
        type: ActionTypes.UPDATE_JOB_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_JOB_META,
        payload: { total },
      })
    } catch (error) {
      console.log('getReportJobs error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getReportJobs' },
      })
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getReportMerchants (params) {
  return async (dispatch, getState) => {
    try {
      const prevRequests = getState().app.loadingApis.filter(o => o.apiName === 'getReportMerchants')
      prevRequests.forEach(r => {
        // cancel the last prevReq
        r.controller.abort()
      })
      const controller = new AbortController()
      const request = goAdminApi.getReportMerchants(params, controller.signal)
      dispatch({
        type: 'APP/CREATE_API_REQUEST',
        payload: {
          apiName: 'getReportMerchants',
          request,
          controller,
        },
      })
      const response = await request.then(o => {
        dispatch({
          type: 'APP/FINISH_API_REQUEST',
          payload: { apiName: 'getReportMerchants' },
        })
        return o
      }).catch(e => {
        dispatch({
          type: 'APP/FINISH_API_REQUEST',
          payload: { apiName: 'getReportMerchants' },
        })
        throw e
      })
      return response
    } catch (error) {
      console.log('getReportJobs error', error)
      return []
    }
  }
}

/**
 * @function
 * @returns {{id: string, name: string visible: bool}[]}
 */
export function getReportVisiblePortal (jobId) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getReportVisiblePortal' },
    })
    try {
      return await goAdminApi.getReportVisiblePortal(jobId)
    } catch (error) {
      console.log('getReportJobs error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getReportVisiblePortal' },
      })
    }
  }
}

/**
 * @function
 * @param {jobId: string, merchants: {id: string, visible: boolean}[]} params
 * @returns {}
 */
export function setReportVisiblePortal (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'setReportVisiblePortal' },
    })
    try {
      return await goAdminApi.setReportVisiblePortal(params)
    } catch (error) {
      console.log('getReportJobs error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'setReportVisiblePortal' },
      })
    }
  }
}

/**
 * @function
 * @param {IReportJobQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateJobQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_JOB_PARAMS,
        payload: { params },
      })
      // 更新params後，重新抓取
      dispatch(getReportJobs())
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}

/**
 * @function
 * @param {*} params
 * @returns {ThunkFunction}
 */
export function updateMeta (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_JOB_META,
        payload: params,
      })
    } catch (error) {
      console.log('updateMeta error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @param {object} data
 * @returns {ThunkFunction}
 */
export function updateReportJob (id, data) {
  return async (dispatch, getState) => {
    try {
      const result = await goAdminApi.updateReportJob(id, data)
      return result
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: updateReportJob', content: error.message }))
      console.error('updateReportJob error', error)
    }
  }
}
