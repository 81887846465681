import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'

/**
 *
 * @param {{
 * open: boolean
 * handleClose: () => void
 * selectedOrder?: IDeliveryOrder
 * }} props
 * @returns
 */
export default function CancelDeliveryOrderDialog (props) {
  const { open, handleClose, selectedOrder } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const disabledLalamoveCancel = React.useMemo(() => {
    if (!_.includes(['ASSIGNING_DRIVER', 'ON_GOING'], selectedOrder?.providers?.LALAMOVE?.status)) return true
    return false
  }, [selectedOrder?.providers?.LALAMOVE?.status])

  const disabledDimorderCancel = React.useMemo(() => {
    if (!_.includes(['ASSIGNING_DRIVER', 'ON_GOING'], selectedOrder?.providers?.DIMORDER?.status)) return true
    return false
  }, [selectedOrder?.providers?.DIMORDER?.status])

  return (
    <Dialog
      title='Delivery Cancellation'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <>
            <Button
              disabled={disabledLalamoveCancel}
              onClick={() => {
                if (selectedOrder?.providers?.LALAMOVE?.status === 'PENDING') {
                  dispatch(actions.deliveryOrder.updateDeliveryOrder(selectedOrder?.id, {
                    'providers.LALAMOVE.status': 'CANCELED',
                    'providers.LALAMOVE.updatedAt': moment().toISOString(),
                  }))
                } else {
                  dispatch(actions.deliveryOrder.cancelLalamoveDelivery(selectedOrder?.providers?.LALAMOVE?.orderId))
                }
                handleClose()
              }}
            >
              Cancel Lalamove Delivery
            </Button>
            <Button
              disabled={disabledDimorderCancel}
              onClick={() => {
                dispatch(actions.deliveryOrder.cancelDimorderDelivery(selectedOrder?.id))
                handleClose()
              }}
            >
              Cancel Dimorder Delivery
            </Button>
            <Button
              disabled={disabledLalamoveCancel || disabledDimorderCancel}
              onClick={() => {
                dispatch(actions.deliveryOrder.cancelDeliveryOrder(selectedOrder?.id))
                handleClose()
              }}
            >
              Cancel All Deliveries
            </Button>
          </>
        )
      }}
    >
      <div className={classes.dialogContent}>
        要取消什麼？
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
  },
}))
