import _ from 'lodash'

import { auth } from '@/libs/firebase'

import ActionTypes from './ActionTypes'

const getUserName = user => {
  return user.displayName || _.get(user.email.match(/wing\+(.*)@dimorder\.com/), '1', '')
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}
/**
 * @callback simpleCallback
 */

/**
 * @function
 * @param {string} username
 * @param {string} password
 * @param {simpleCallback} callback
 * @returns {ThunkFunction}
 */
export function signin (username, password, callback) {
  return async (dispatch) => {
    try {
      const eamil = `wing+${username}@dimorder.com`
      const { user } = await auth.signInWithEmailAndPassword(eamil, password)
      dispatch(signinWithUser(user))
      callback && callback()
    } catch (error) {
      dispatch({
        type: ActionTypes.SIGNIN_ERROR,
        payload: error,
      })
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function signout () {
  return async (dispatch) => {
    try {
      await auth.signOut()
      dispatch({
        type: ActionTypes.SIGNOUT_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.SIGNOUT_ERROR,
        payload: error,
      })
    }
  }
}

/**
 * @function
 * @param {IUser} user
 * @returns {ThunkFunction}
 */
export function signinWithUser (user) {
  return async (dispatch) => {
    if (user) {
      const result = await user.getIdTokenResult()
      // const token = _.get(result, 'token')
      const role = _.get(result, 'claims.role')

      if (!role) {
        throw new Error("User doesn't have any role.")
      }

      dispatch({
        type: ActionTypes.SIGNIN_SUCCESS,
        payload: {
          user: {
            name: getUserName(user),
            uid: user.uid,
            role,
          },
        },
      })
    }
  }
}
