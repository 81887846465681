import { combineReducers } from 'redux'

import app from './app/reducer'
import auth from './auth/reducer'
import customer from './customer/reducer'
import dashboard from './dashboard/reducer'
import deliveryOrder from './deliveryOrder/reducer'
import dimbox from './dimbox/reducer'
import driver from './driver/reducer'
import merchant from './merchant/reducer'
import order from './order/reducer'
import posPayLink from './posPayLink/reducer'
import promoCode from './promoCode/reducer'
import refund from './refund/reducer'
import report from './report/reducer'
import riceCoin from './riceCoin/reducer'
import setting from './setting/reducer'
import sms from './sms/reducer'
import user from './user/reducer'

export default combineReducers({
  app,
  auth,
  customer,
  dashboard,
  deliveryOrder,
  dimbox,
  driver,
  merchant,
  order,
  promoCode,
  refund,
  report,
  riceCoin,
  sms,
  user,
  setting,
  posPayLink,
})
