import * as app from './app'
import * as auth from './auth'
import * as posPayLink from './posPayLink'
import * as riceCoin from './riceCoin'
import * as user from './user'

export default {
  app,
  auth,
  riceCoin,
  user,
  posPayLink,
}
