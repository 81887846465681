import { Button, FormControl, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { actions, useDispatch, useSelector } from '@/redux'
import PageHeader from '@/components/Page/PageHeader'

const settingsConfig = {
  takeaway: {
    inputName: 'remark',
  },
  storedelivery: {
    inputName: 'remark',
  },
  busymode: {
    inputName: 'delay',
  },
  payme_gateway: {
    inputName: 'gateway',
  },
  credit_card_gateway: {
    inputName: 'gateway',
  },
}

const InputWithSwitchControl = (prop = {}) => {
  const { settingsState, classes, checkEnable, handleSwitchChange, handleChange, name, label } = prop
  if (!settingsState[name]) return (<></>)
  return (
    <div className={classes.controlLine}>
      <Typography>{label}</Typography>
      <Switch checked={checkEnable(name)} name={name} onChange={handleSwitchChange} color='primary' />
      <TextField
        variant='outlined'
        size='small'
        name={name}
        fullWidth
        disabled={!checkEnable(name)} // 不可編輯
        classes={{ root: classes.textField }}
        value={settingsState[name][settingsConfig[name].inputName]}
        onChange={handleChange}
      />
    </div>
  )
}

const PullDownMenu = ({ menuItem, classes, label, name, settingsState, handleChange }) => {
  if (!settingsState[name]) return null
  return (
    <div className={clsx(classes.controlLine, classes.pullDownControlLine)}>
      <Typography>{label}</Typography>
      <FormControl variant='outlined'>
        <InputLabel>{label}</InputLabel>
        <Select
          name={name}
          label={label}
          className={classes.selectControl}
          onChange={handleChange}
          value={settingsState[name][settingsConfig[name].inputName]}
        >
          {menuItem.map((item) => {
            return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  )
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function Setting (props) {
  const dispatch = useDispatch()
  const settings = useSelector(state => state.setting.data)
  const classes = useStyles(props)
  const [settingsState, setSettingsState] = React.useState(settings)
  const handleSave = () => {
    Promise.all(
      Object.entries(settingsState).map(([settingType, params]) => {
        return dispatch(actions.setting.putSystemSettings(settingType, params))
      }),
    ).then(() => {
      dispatch(actions.app.toggleDialog('alertDialog', true, { title: 'Success', content: 'Update successfully.' }))
    }).finally(() => {
      dispatch(actions.setting.getSettings())
    })
  }

  React.useEffect(() => {
    dispatch(actions.setting.getSettings())
  }, [])
  React.useEffect(() => {
    setSettingsState(settings)
  }, [settings])
  const handleChange = (event) => {
    const { name, value } = event.target
    const temp = _.cloneDeep(settingsState)
    temp[name][settingsConfig[name].inputName] = value
    setSettingsState(temp)
  }
  const handleSwitchChange = (event) => {
    const { name, checked } = event.currentTarget
    const temp = _.cloneDeep(settingsState)
    temp[name].enable = checked
    setSettingsState(temp)
  }
  const checkEnable = (name) => {
    return !!settingsState[name]?.enable
  }

  return (
    <div className={classes.page}>
      {/* content */}
      <PageHeader text='Setting' />
      <InputWithSwitchControl label='開啟外賣自取' name='takeaway' settingsState={settingsState} classes={classes} checkEnable={checkEnable} handleSwitchChange={handleSwitchChange} handleChange={handleChange} />
      <InputWithSwitchControl label='開啟外送' name='storedelivery' settingsState={settingsState} classes={classes} checkEnable={checkEnable} handleSwitchChange={handleSwitchChange} handleChange={handleChange} />
      <InputWithSwitchControl label='開啟繁忙模式' name='busymode' settingsState={settingsState} classes={classes} checkEnable={checkEnable} handleSwitchChange={handleSwitchChange} handleChange={handleChange} />
      <PullDownMenu
        label='PayMe Gateway' name='payme_gateway' settingsState={settingsState} classes={classes} handleChange={handleChange}
        menuItem={[{ value: '2c2p', label: '2c2p' }, { value: 'qfpay', label: 'qfpay' }]}
      />
      <PullDownMenu
        label='Credit Card Gateway' name='credit_card_gateway' settingsState={settingsState} classes={classes} handleChange={handleChange}
        menuItem={[{ value: 'fiserv', label: 'fiserv' }, { value: '2c2p', label: '2c2p' }]}
      />
      <div className={classes.btn}>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={handleSave}
        >
          SAVE
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  textField: {
    background: theme.palette.common.white,
  },
  page: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 640px)',
    gap: theme.spacing(1),
    background: 'white',
    maxWidth: '640px',
  },
  controlLine: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '0.4fr 0.1fr 0.5fr',
    rowGap: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  pullDownControlLine: {
    gridTemplateColumns: '0.5fr 0.5fr',
  },
  btn: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectControl: {
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
}))
