/**
 * @export
 * @param {*} input
 * @return {Number}
 */
export default function fixPrecision (input) {
  const num = Number(input)
  if (Number.isNaN(num)) return num

  return parseFloat(num.toPrecision(12))
}
