import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMerchant } from '@/hooks/merchantContext'
import React from 'react'

/**
 * @param {*} props
 * @returns
 */
export default function CopyMenuButton (props) {
  const classes = useStyles(props)
  const [, setMerchant] = useMerchant()
  const handleClick = () => {
    setMerchant('openCopyMenuDialog', true)
  }

  return (
    <Button
      disableElevation
      variant='contained'
      color='primary'
      className={classes.createButton}
      onClick={handleClick}
    >COPY MENU
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  createButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
}))
