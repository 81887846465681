import { actions } from '@/redux'
import { exportXlsx } from '@/libs/xlsx'
import { saveAs } from 'file-saver'
import ActionTypes from './ActionTypes'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./qrcode.worker.js'
import _ from 'lodash'
import config from '@/config'
import goAdminApi from '@/libs/api/goAdmin'
import moment from 'moment'
import nodeApi from '@/libs/api/node'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getMerchants () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getMerchants' },
    })
    try {
      const params = { ...getState().merchant.params }
      Object.keys(params).forEach(key => {
        if (params[key] === '') {
          delete params[key]
        }
      })
      const response = await goAdminApi.queryMerchant(params)
      const { data, total } = response

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { total },
      })
    } catch (error) {
      console.log('getMerchants error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getMerchants' },
      })
    }
  }
}

/**
 * @function
 * @param {IMerchantQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function resetMerchants () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DATA,
      payload: {},
    })
    dispatch(init())
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function exportMerchants () {
  return async (dispatch, getState) => {
    try {
      const query = getState().merchant.params.query
      const response = await goAdminApi.queryMerchant({ query })
      const merchants = response.data ?? []

      console.log(merchants)
      const headers = [
        'Merchant Name',
        'Contact',
        'Address',
        'Staff App',
        'Takeaway',
        'Sdelivery',
        'Remark',
        'Short Id',
      ]
      const rows = _.map(merchants, merchant => {
        /** @type {IMerchant} */
        const columns = [
          merchant.name,
          merchant.contact,
          merchant.address,
          merchant.setting?.staff?.toString().toUpperCase(),
          (!merchant.takeawayDisabled).toString().toUpperCase(),
          merchant.setting?.storeDelivery?.toString().toUpperCase(),
          merchant.setting?.remark,
          merchant.id.split('-')[0],
        ]
        const row = _
          .chain(columns)
          .map(col => {
            if (col == null) return ''
            const value = String(col)
            return _.chain(value).replace('\n', '').replace(';', ',').value() // avoid csv display error
          })
          .value()
        return row
      })
      exportXlsx(`merchant_export_${moment().format('YYYYMMDDHHmmss')}.xlsx`, headers, rows)
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.error') || error
      if (errorMessage) {
        window.alert(errorMessage)
      }
      console.error('exportMerchantMerchants error', error)
    }
  }
}

/**
 * @param {string} merchantId merchant.id
 * @param {boolean} disable
 * @returns {ThunkFunction}
 */
export function disableMerchant (merchantId, disable) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'disableMerchant' },
    })
    try {
      const params = {
        disabled: disable,
      }
      await goAdminApi.disableMerchant(merchantId, params)
    } catch (error) {
      console.log('disableMerchant error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'disableMerchant' },
      })
    }
  }
}

/**
 * @param {string} merchantId merchant.id
 * @returns {ThunkFunction}
 */
export function deleteMerchant (merchantId) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'deleteMerchant' },
    })
    try {
      await goAdminApi.deleteMerchant(merchantId)
    } catch (error) {
      console.log('deleteMerchant error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'deleteMerchant' },
      })
    }
  }
}

/**
 * @param {string} merchantId merchant.id
 * @returns {ThunkFunction}
 */
export function getMerchantQRcodeFromAPI (merchantId) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getMerchantQRcode' },
    })
    try {
      await nodeApi.getMerchantQRcode(merchantId)
    } catch (error) {
      console.log('getMerchantQRcode error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getMerchantQRcode' },
      })
    }
  }
}
/**
 * @param {{merchantName: string, merchantId: string, tableGroups: Object, setLoading: function}} params
 * @returns {ThunkFunction}
 */
export function getMerchantQRcode (params) {
  return async (dispatch, getState) => {
    const { merchantName, merchantId, tableGroups, setLoading } = params
    if (!tableGroups) {
      setLoading(false)
      return dispatch(actions.app.toggleDialog(
        'alertDialog',
        true,
        {
          title: '資料不存在',
          content: '檯號資料不存在',
        },
      ))
    }

    const worker = new Worker()
    worker.postMessage({ tableGroups, merchantId, merchantName, clientNewUrl: config.clientNewUrl })
    worker.addEventListener('message', async (message) => {
      const { data } = message
      worker.terminate()
      saveAs(data, `${merchantName}_qrcode.zip`)
      return setLoading(false)
    })
  }
}

/**
 * @param {ICreateMerchant} param
 * @returns {ThunkFunction}
 */
export function createMerchant (param) {
  const { username, password, name, address, contact } = param
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'createMerchant' },
    })
    try {
      const params = {
        username,
        password,
      }
      const data = await goAdminApi.regMerchant(params)
      const params2 = {
        id: data.id,
        shortCode: username,
        name,
        address,
        contact,
      }
      const result = await goAdminApi.createMerchantInfo(params2)
      return result
    } catch (error) {
      console.log('createMerchant error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'createMerchant' },
      })
    }
  }
}

/**
 * @param {{source: string, target: string }} params
 * @returns {ThunkFunction}
 */
export function copyMenu (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'copyMenu' },
    })
    try {
      const result = await nodeApi.copyMenu(params)
      return result
    } catch (error) {
      console.log('copyMenu error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'copyMenu' },
      })
    }
  }
}

/**
 * @param {string} merchantId target merchant.id
 * @returns {ThunkFunction}
 */
export function copyToBeta (merchantId) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'copyToBeta' },
    })
    try {
      const result = await goAdminApi.copyToBeta(merchantId)
      return result
    } catch (error) {
      console.log('copyToBeta error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'copyToBeta' },
      })
    }
  }
}

/**
 * @param {string} merchantId merchant.id
 * @param {object} params
 * @returns {ThunkFunction}
 */
export function updateMerchant (merchantId, params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'updateMerchant' },
    })
    try {
      await goAdminApi.updateMerchantSetting(merchantId, params)
    } catch (error) {
      console.log('updateMerchant error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'updateMerchant' },
      })
    }
  }
}

export function updateMerchantShortCode (merchantId, shortCode) {
  return async (dispatch) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'updateMerchantShortCode' },
    })
    try {
      await goAdminApi.updateMerchantShortCode(merchantId, shortCode)
    } catch (error) {
      console.log('updateMerchantShortCode error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'updateMerchantShortCode' },
      })
    }
  }
}

export function updateMerchantPassword (merchantId, username, password) {
  return async (dispatch) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'updateMerchantPassword' },
    })
    try {
      await goAdminApi.updateMerchantPassword(merchantId, username, password)
    } catch (error) {
      console.log('updateMerchantPassword error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'updateMerchantPassword' },
      })
    }
  }
}

/**
 * @param {{ merchantId: string }} merchantId
 * @returns {ThunkFunction}
 */
export function exportMenu (merchantId) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'exportMenu' },
    })
    try {
      const blob = await nodeApi.exportMenu({ merchantId })
      const a = document.createElement('a')
      const objectUrl = window.URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = `menu_report_${moment().format('YYYYMMDDHHmmss')}.xlsx`
      a.click()
      window.URL.revokeObjectURL(objectUrl)
    } catch (error) {
      console.log('exportMenu error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'exportMenu' },
      })
    }
  }
}

/**
 * @returns {ILocals}
 */
export function getBatchLocales () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getBatchLocales' },
    })
    try {
      return await goAdminApi.getBatchLocales()
    } catch (error) {
      console.log('getBatchLocales error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getBatchLocales' },
      })
    }
  }
}
