// import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'

const { TYPE_RICECOIN, TYPE_GATEWAY, TYPE_BYPASS, TYPE_DEDUCT_MERCHANT, TYPE_OTHER } = {
  TYPE_RICECOIN: 'ricecoin',
  TYPE_GATEWAY: 'gateway',
  TYPE_BYPASS: 'Bypass Payment',
  TYPE_DEDUCT_MERCHANT: 'Deduct Merchant',
  TYPE_OTHER: 'other',
}

const defaultState = {
  orderId: '',
  amount: '',
  reason: 'Missed Order Item(s)',
  costBy: 'Merchant',
  receiver: 'Customer',
  type: TYPE_RICECOIN,
  remark: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function AddRefundDialog (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const { open, data } = useDialog('addRefund')
  const [inputState, setInputState] = React.useState(defaultState)

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('addRefund'))
  }

  React.useEffect(async () => {
    setInputState({ ...inputState, orderId: data.id })
  }, [open])

  const handleSelect = (event) => {
    const { name, value } = event.target
    setInputState({ ...inputState, [name]: value })
  }

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState({ ...inputState, [name]: value })
  }

  const handleSubmit = async () => {
    const param = {
      ...inputState,
    }
    await dispatch(actions.refund.postRefund(param))
    await dispatch(actions.app.toggleDialog('addRefund'))
    history.push('/refund')
  }
  return (
    <Dialog
      title='New Refund'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )
      }}
    >
      <div className='d-flex justify-center'>
        <div>
          <div className={classes.box}>
            <div>訂單編號 Order Serial:</div>
            <div>#{data.orderSerial}</div>
            <div>商戶名稱 Merchant Name:</div>
            <div>{data.merchantName}</div>
            <div>交易日期 Transaction Date:</div>
            <div>{moment(data.transactionDate).format('YYYY-MM-DD HH:mm')}</div>
            <div>訂單金額 Original Order Amount:</div>
            <div>{data.orderAmount}</div>
          </div>
          <hr />
          <div className={classes.box}>
            <div>退款金額 Refund Amount:</div>
            <TextField
              variant='outlined'
              size='small'
              name='amount'
              fullWidth
              classes={{ root: classes.textField }}
              value={inputState.amount}
              onChange={handleChange}
            />
            <div>退款原因 Refund Reason:</div>
            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                className={classes.selectControl}
                value={inputState.reason}
                onChange={handleSelect}
                name='reason'
              >
                <MenuItem value='Missed Order Item(s)'>
                  Missed Order Item(s)
                </MenuItem>
                <MenuItem value='Merchant canceled after confirming order'>
                  Merchant canceled after confirming order
                </MenuItem>
                <MenuItem value='Food Issue (e.g Spillage etc.)'>
                  Food Issue (e.g Spillage etc.)
                </MenuItem>
                <MenuItem value='Item sold out'>Item sold out</MenuItem>
                <MenuItem value='Revised Order'>Revised Order</MenuItem>
                <MenuItem value='Food Quality Issue'>
                  Food Quality Issue
                </MenuItem>
                <MenuItem value='cancelled order'>cancelled order</MenuItem>
                <MenuItem value='system error'>system error</MenuItem>
                <MenuItem value='Late'>Late</MenuItem>
                <MenuItem value='other reason'>other reason</MenuItem>
              </Select>
            </FormControl>
            <div>負責方 Refund Cost By:</div>
            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                className={classes.selectControl}
                value={inputState.costBy}
                onChange={handleSelect}
                name='costBy'
              >
                <MenuItem value='Merchant'>Merchant</MenuItem>
                <MenuItem value='DimOrder'>DimOrder</MenuItem>
              </Select>
            </FormControl>
            <div>接受方 Refund To:</div>
            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                className={classes.selectControl}
                value={inputState.receiver}
                onChange={handleSelect}
                name='receiver'
              >
                <MenuItem value='Customer'>Customer</MenuItem>
                <MenuItem value='Merchant'>Merchant</MenuItem>
                <MenuItem value='DimOrder'>DimOrder</MenuItem>
              </Select>
            </FormControl>
            <div>退款類型 Refund Type:</div>
            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                className={classes.selectControl}
                value={inputState.type}
                onChange={handleSelect}
                name='type'
              >
                <MenuItem value={TYPE_RICECOIN}>RiceCoin</MenuItem>
                <MenuItem value={TYPE_GATEWAY}>原付款方式</MenuItem>
                <MenuItem value={TYPE_BYPASS}>Bypass Payment</MenuItem>
                <MenuItem value={TYPE_DEDUCT_MERCHANT}>Deduct Merchant</MenuItem>
                <MenuItem value={TYPE_OTHER}>Other</MenuItem>
              </Select>
            </FormControl>
            <div>備註 Remarks:</div>
            <TextField
              variant='outlined'
              classes={{ root: classes.textField }}
              value={inputState.remark}
              multiline
              rows={5}
              name='remark'
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(1),
    padding: theme.spacing(3),
    alignItems: 'center',
  },
  selectControl: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
}))
