import { FormControl, FormHelperText } from '@material-ui/core'
import AsyncSelect from 'react-select/async'
import React from 'react'
import _ from 'lodash'
import goAdmin from '@/libs/api/goAdmin'

/**
 *
 * @param {{
 * value: Merchant
 * onChange?: (newValue: Merchant) => void
 * disabled?: boolean
 * helperText?: string
 * formControlProps?: object
 * onlyRemark?: boolean
 * }} props
 * @returns
 */
export default function MerchantSearch (props) {
  const {
    value, // 被選擇的 merchant
    onChange,
    disabled,
    helperText,
    formControlProps,
    onlyRemark = true,
  } = props

  const debouncedLoadOptions = React.useCallback(
    _.debounce((v, cb) => {
      queryMerchant(v).then(o => cb(o))
    }, 300),
    [],
  )

  const queryMerchant = async (value) => {
    try {
      const merchants = await goAdmin.queryMerchant({ query: value, limit: 5, ...(onlyRemark && { onlyRemark: onlyRemark }) })
      return merchants?.data?.map(o => ({
        ...o,
        value: o.id,
        label: o.name,
      })) || []
    } catch (error) {
      console.log('queryMerchant error', error, 'value', value)
      return []
    }
  }

  return (
    <>
      <FormControl variant='outlined' margin='dense' fullWidth {...formControlProps}>
        <AsyncSelect
          styles={{
            menu: provided => ({
              ...provided,
              zIndex: 999,
            }),
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#E89845',
            },
          })}
          isDisabled={disabled}
          isClearable
          placeholder='Merchant Name'
          loadOptions={debouncedLoadOptions}
          value={value}
          onChange={onChange}
        />
        {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  )
}
