import { Provider as PromotionContextProvider } from '@/hooks/PromotionContext'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import CreatePromoCodeButton from './CreatePromoCodeButton'
import ExportButton from '@/components/ExportButton'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import PromotionDialog from './PromotionDialog'
import PromotionSearchForm from './PromotionSearchForm'
import PromotionTable from './PromotionTable'
import React from 'react'

/**
 * 優惠碼 promoCode 和優惠券 coupon 頁面
 * @param {*} props
 * @returns
 */
export default function Promotion (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)

  const handleExport = () => {
    dispatch(actions.promoCode.exportPromoCodes())
  }
  React.useEffect(() => {
    dispatch(actions.promoCode.getPromoCodes())
  }, [])

  return (
    <PromotionContextProvider>
      <Page>
        <PageHeader text='Promotion Page'>
          <div className={classes.headerButtonWrap}>
            <CreatePromoCodeButton />
            <ExportButton onClick={handleExport} />
          </div>
        </PageHeader>
        <PromotionSearchForm />
        <PromotionTable />
        <PromotionDialog />
      </Page>
    </PromotionContextProvider>
  )
}

const useStyles = makeStyles(theme => ({
  headerButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
}))
