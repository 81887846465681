import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useImmer } from 'use-immer'
import React from 'react'
import Select from '@material-ui/core/Select'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

const defaultSendSMSParam = {
  body: '',
  isDirectMarketing: true,
  conditions: {},
  recipients: [],
  channel: 'push',
  data: {
    type: 'ACTION',
    payload: {
      type: 'NONE',
      payload: '',
    },
  },
}
const defaultCustomizeParam = { operator: 'over', amount: 500 }
const defaultSMSInformation = { delivered: 0, price: 0, smsPerUser: 0 }

/**
 *
 * @param {*} props
 * @returns
 */
export default function NewSMS (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const history = useHistory()
  const [userType, setUserType] = React.useState('ALL')
  const [userList, setUserList] = React.useState('')
  const [customizeParam, setCustomizeParam] = React.useState(defaultCustomizeParam)
  const [smsInformation, setSmsInformation] = React.useState(defaultSMSInformation)
  const [sendSMSParam, setSendSMSParam] = useImmer(defaultSendSMSParam)
  const updateSMSParam = (path, value) => {
    setSendSMSParam((draft) => {
      _.set(draft, path, value)
    })
  }

  const smsPerUserCalc = (body) => Math.ceil(Buffer.byteLength(body) / 170)

  React.useEffect(
    () => {
      const fetchSMSInformation = async () => {
        if (sendSMSParam.channel !== 'sms') return
        const params = _.omit(sendSMSParam, 'data') // sms inquiry不傳data
        const { data } = await dispatch(actions.sms.getSMSInformation(params))
        const { delivered, price, body } = data
        const smsPerUser = smsPerUserCalc(body)
        setSmsInformation({ delivered, price, smsPerUser })
      }
      fetchSMSInformation()
    },
    [
      smsInformation.smsPerUser, // 當字數超過每上限時會變成每人多封，所以要重拿 sms information
      sendSMSParam.channel,
      sendSMSParam.recipients,
      sendSMSParam.conditions,
      sendSMSParam.isDirectMarketing,
    ],
  )

  const handleSendSMS = () => {
    const params = { ...sendSMSParam }

    if (sendSMSParam.channel === 'sms') {
      params.data = undefined
    }

    if (sendSMSParam.channel === 'push') {
      params.data = {
        ...sendSMSParam.data,
        payload: JSON.stringify(sendSMSParam.data.payload),
      }
    }

    dispatch(actions.app.toggleDialog('confirmDialog', null, {
      content: 'Send immediately',
      confirmCallback: async () => {
        await dispatch(actions.sms.sentSMS(params))
        history.push('/SMS')
      },
    }))
  }

  const handleUserTypeChange = (event) => {
    const { value } = event.target
    let { recipients, conditions } = sendSMSParam

    if (value === 'LIST') {
      conditions = {}
    } else {
      recipients = []
      setUserList('')
    }

    if (value === 'ALL') {
      conditions = {}
    }

    if (value === 'ACTIVE') {
      conditions = { amount: { gt: 0 } }
    }

    if (value === 'CUSTOMIZE') {
      const { operator, amount } = customizeParam
      conditions = operator === 'over'
        ? { amount: { gt: amount } }
        : { amount: { lt: amount } }
    }

    if (value === 'INACTIVE') {
      conditions = { amount: { eq: null } }
    }

    setUserType(value)
    setSendSMSParam(draft => {
      draft.recipients = recipients
      draft.conditions = conditions
    })
  }

  const handleChannelChange = (event) => {
    const { value } = event.target
    updateSMSParam('channel', value)
    if (value === 'push') {
      setSmsInformation({ ...smsInformation, price: 0 })
    }
  }

  const handleDirectMarketingChange = (event) => {
    const { checked } = event.target
    updateSMSParam('isDirectMarketing', checked)
  }

  const handleCustomizedChange = (event) => {
    const { name, value } = event.target
    let { operator, amount } = customizeParam

    if (name === 'customizeOperator') {
      operator = value
      setCustomizeParam({ ...customizeParam, operator })
    }

    if (name === 'customizeAmount') {
      amount = parseInt(value)
      setCustomizeParam({ ...customizeParam, amount })
    }

    const conditions = operator === 'over'
      ? { amount: { gt: amount } }
      : { amount: { lt: amount } }

    updateSMSParam('conditions', conditions)
  }

  const handleSMSContentChange = (event) => {
    const { value } = event.currentTarget
    const smsPerUser = smsPerUserCalc(value)
    updateSMSParam('body', value)
    setSmsInformation({ ...smsInformation, smsPerUser })
  }

  const handleUserListChange = (event) => {
    const { value } = event.currentTarget
    setUserList(value)
  }

  const handleUserListBlur = (event) => {
    const { value } = event.currentTarget
    const recipients = value.split(/[\n,;]/) // 接受換行、逗號、分號分隔
      .map(phone => phone.trim()) // 避免前後有空格
      .filter(phone => phone && phone !== '') // 清除空白
    updateSMSParam('recipients', recipients)
  }

  const handleActionChange = (event) => {
    const { value } = event.target
    updateSMSParam('data.payload.type', value)
    if (value === 'NONE') {
      updateSMSParam('data.payload.payload', '')
    }
  }

  const handlePayloadChange = (event) => {
    const { value } = event.currentTarget
    updateSMSParam('data.payload.payload', value)
  }

  return (
    <Page>
      {/* content */}
      <PageHeader text='Send SMS/Notification' />
      <div className={classes.data}>
        <div className='d-flex justify-center'>
          <div className={classes.formGrid}>
            <h5 className='mt-2 mr-6'>Channel:</h5>
            <div>
              <FormControl component='fieldset'>
                <RadioGroup name='channel' value={sendSMSParam.channel} onChange={handleChannelChange}>
                  <FormControlLabel value='sms' control={<Radio color='primary' />} label='SMS' />
                  <FormControlLabel value='push' control={<Radio color='primary' />} label='Notification' />
                </RadioGroup>
              </FormControl>
            </div>
            <h5 className='mt-2 mr-6'>TO:</h5>
            <div>
              <FormControl component='fieldset'>
                <RadioGroup name='userType' value={userType} onChange={handleUserTypeChange}>
                  <FormControlLabel value='ALL' control={<Radio color='primary' />} label='All user' />
                  <FormControlLabel value='ACTIVE' control={<Radio color='primary' />} label='All active users' />
                  <div className='d-flex align-center'>
                    <FormControlLabel value='CUSTOMIZE' control={<Radio color='primary' />} label='Active users of purchased' />
                    <FormControl>
                      <Select
                        value={customizeParam.operator}
                        name='customizeOperator'
                        onChange={handleCustomizedChange}
                        disabled={userType !== 'CUSTOMIZE'}
                      >
                        <MenuItem value='over'>over</MenuItem>
                        <MenuItem value='less'>less</MenuItem>
                      </Select>
                    </FormControl>
                    than HK
                    <TextField
                      disabled={userType !== 'CUSTOMIZE'}
                      name='customizeAmount'
                      variant='standard'
                      type='number'
                      classes={{ root: classes.textField }}
                      size='small'
                      value={customizeParam.amount}
                      onChange={handleCustomizedChange}
                      onWheel={(e) => e.target.blur()}
                      InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                    />
                  </div>
                  <FormControlLabel value='INACTIVE' control={<Radio color='primary' />} label='Non-active users' />
                  <FormControlLabel value='LIST' control={<Radio color='primary' />} label='Users list' />
                  <div className={classes.userList}>
                    <FormControl fullWidth>
                      <TextField
                        label='Phone Numbers'
                        variant='outlined'
                        placeholder='+85299999999; +85299988888'
                        helperText='電話號碼包含國碼，多個號碼時可用逗號分號或換行分隔'
                        value={userList}
                        multiline
                        rows={3}
                        onChange={handleUserListChange}
                        onBlur={handleUserListBlur}
                        disabled={userType !== 'LIST'}
                      />
                    </FormControl>
                  </div>
                </RadioGroup>
                <FormControlLabel
                  className='mt-2'
                  control={
                    <Checkbox
                      checked={sendSMSParam.isDirectMarketing}
                      onChange={handleDirectMarketingChange}
                      color='primary'
                      name='directMarketing'
                    />
                      }
                  label='Only users allow SMS marketing'
                />
              </FormControl>
            </div>
            <h5 className='mr-3'>Content:</h5>
            <FormControl fullWidth>
              <TextField
                label='Content'
                variant='outlined'
                value={sendSMSParam.body}
                multiline
                rows={5}
                onChange={handleSMSContentChange}
              />
            </FormControl>
            {sendSMSParam.channel === 'push' &&
              <>
                <h5 className='mr-3'>Action:</h5>
                <FormControl fullWidth>
                  <Select
                    name='action'
                    variant='outlined'
                    fullWidth
                    value={sendSMSParam.data.payload.type ?? 'NONE'}
                    onChange={handleActionChange}
                  >
                    <MenuItem value='NONE'>NONE</MenuItem>
                    <MenuItem value='MERCHANT'>MERCHANT</MenuItem>
                    <MenuItem value='CATEGORY'>CATEGORY</MenuItem>
                    <MenuItem value='CUISINE'>CUISINE</MenuItem>
                    <MenuItem value='SEARCH'>SEARCH</MenuItem>
                    <MenuItem value='LINK'>LINK</MenuItem>
                  </Select>
                </FormControl>
                <h5 className='mr-3'>Payload:</h5>
                <FormControl fullWidth>
                  <TextField
                    label='payload'
                    name='payload'
                    variant='outlined'
                    type='text'
                    value={sendSMSParam.data.payload.payload}
                    rows={1}
                    onChange={handlePayloadChange}
                    disabled={sendSMSParam.data.payload.type === 'NONE'}
                  />
                </FormControl>
              </>}
            <div className={classes.gridColumn2 + ' mt-5 d-flex justify-center'}>
              <div>
                <p>Number of users: {smsInformation.delivered}</p>
                <p>SMS Quantity: {smsInformation.delivered * smsInformation.smsPerUser}</p>
                <p>Price: US$ {smsInformation.price}</p>
              </div>
            </div>
            <div className={classes.gridColumn2 + ' d-flex justify-center mt-5'}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSendSMS}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  data: {
    background: '#FFFFFF',
    padding: '12px',
  },
  textField: {
    width: '120px',
  },
  userList: {
    marginLeft: '30px',
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: '100px auto',
    rowGap: theme.spacing(1),
  },
  gridColumn2: {
    gridColumn: '1 /  span 2',
  },
}))
