import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {ICustomerQueryParams} */
export const initialParams = {
  page: 1,
  name: '',
  mobile: '',
  referral: '',
  createdAt: '',
  tags: '',
}

export const initialTags = {
  data: [],
  meta: {},
}
/** @type {ICustomerState} */
export const initialState = {
  isInit: false,
  data: [],
  meta: {},
  params: initialParams,
  tags: initialTags,
  referrerName: '',
}

export default produce(
  /**
   *  @param {ICustomerState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_DATA: {
        const { data } = action.payload
        draft.data = data
        break
      }
      case ActionTypes.UPDATE_META: {
        const { meta } = action.payload
        draft.meta = meta
        break
      }
      case ActionTypes.UPDATE_PARAMS: {
        const { params } = action.payload
        draft.params = params
        break
      }
      case ActionTypes.UPDATE_TAGS: {
        const { data, meta } = action.payload
        draft.tags.data = data
        draft.tags.meta = meta
        break
      }
      case ActionTypes.UPDATE_REFERRER_NAME: {
        const { name } = action.payload
        draft.referrerName = name
        break
      }
      default:
        break
    }
  },
  initialState,
)
