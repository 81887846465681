import { InputAdornment, TextField } from '@material-ui/core'
import { TagPicker } from 'rsuite'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import TableSearchForm from '@/components/Table/TableSearchForm'

const defaultState = {
  page: 1,
  mobile: '',
  name: '',
  referral: '',
  createdAt: '',
  tags: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function CustomerSearchForm (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [inputState, setInputState] = React.useState(defaultState)
  const params = useSelector(state => state.customer.params)
  const tagList = useSelector(state => state.customer.tags.data)
  const [tags, setTags] = React.useState([])
  const tagData = tagList.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
    }
  })
  React.useEffect(() => {
    if (params) {
      setInputState(params)
    }
  }, [params])

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState({ ...inputState, [name]: value })
  }

  const handleSearch = (event) => {
    event.preventDefault()

    dispatch(actions.customer.updateQuery({ ...inputState, referrer: params.referrer, page: 1 }))
  }

  const handleClear = (event) => {
    event.preventDefault()
    dispatch(actions.customer.updateQuery(defaultState))
    setTags([])
  }

  const handleChangeSelect = (e) => {
    e = e || []
    setInputState({ ...inputState, tags: e.join() })
    setTags(e)
  }
  return (
    <TableSearchForm
      handleSearch={handleSearch}
      handleClear={handleClear}
    >
      {params.referrer &&
        <TextField
          label='Referrer'
          variant='outlined'
          size='small'
          fullWidth
          classes={{ root: classes.textField }}
          value={params.referrer}
          disabled
        />}
      <TextField
        label='Mobile'
        name='mobile'
        type='tel'
        variant='outlined'
        size='small'
        fullWidth
        inputProps={{ maxLength: 8 }}
        classes={{ root: classes.textField }}
        value={inputState.mobile}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputProps={{ startAdornment: <InputAdornment position='start'>+852</InputAdornment> }}
      />
      <TextField
        label='Name'
        name='name'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.name}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
      />
      <TextField
        label='Invitation code'
        name='referral'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.referral}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
      />
      <TextField
        label='Join Date'
        name='createdAt'
        type='date'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.createdAt}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TagPicker onChange={handleChangeSelect} value={tags} data={tagData} style={{ width: 300 }} menuStyle={{ width: 300 }} />
    </TableSearchForm>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  textField: {
    '& .MuiInputBase-formControl': {
      background: theme.palette.common.white,
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  fileds: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: theme.spacing(1),
  },
}))
