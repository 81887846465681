import { Avatar, Button, Chip, FormControl, FormHelperText, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import { useInputState } from '@/hooks/inputStateContext'
import CollapsibleList from '@/components/CollapsibleList'
import DeleteIconButton from '@/components/IconButton/DeleteIconButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OtherSettings (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [expand, setExpand] = React.useState(false)
  const [inputState, updateInputState] = useInputState()
  const [preview, setPreview] = React.useState()
  const [tag, setTag] = React.useState('')

  const id = inputState.id
  const image = inputState.image
  const selectedFile = inputState.selectedFile

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
  }

  const handleDeleteImage = async () => {
    if (preview) {
      setPreview(undefined)
      return
    }

    dispatch(actions.app.toggleDialog('confirmDialog', null, {
      content: 'Are you sure you want to delete this image?',
      confirmCallback: async () => {
        await dispatch(actions.promoCode.deletePromoCodeImage(id))
        updateInputState('image', undefined)
        dispatch(actions.promoCode.getPromoCodes())
      },
    }))
    // await goAdmin.deletePromoCodeImage(inputState.id)
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      updateInputState('selectedFile', undefined)
      return
    }
    updateInputState('selectedFile', e.target.files[0])
  }

  const handleAddTag = () => {
    const tags = !_.isEmpty(inputState.tags) ? _.clone(inputState.tags) : []
    if (!tag || _.includes(tags, tag)) {
      // 沒有輸入 tag 或 tag 已存在不新增
      return
    }
    tags.push(tag)
    updateInputState('tags', tags)
    setTag('')
  }

  const handleDeleteTag = (tag) => {
    const tags = _.clone(inputState.tags)
    updateInputState('tags', _.pull(tags, tag))
  }

  return (
    <CollapsibleList title='其他' expand={expand} setExpand={setExpand}>
      <div className={classes.tagInputWrap}>
        {/* Input */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <TextField
            label='TAG'
            name='tag'
            type='text'
            variant='outlined'
            size='small'
            fullWidth
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
          <FormHelperText>限制 order 要有這些 tag 才可使用</FormHelperText>
        </FormControl>
        <Button color='primary' onClick={handleAddTag}>Add</Button>
      </div>

      {/* Tags */}
      <div className={classes.chips}>
        {_.map(inputState.tags, (tag, index) => {
          return <Chip key={index} label={tag} onDelete={() => handleDeleteTag(tag)} />
        })}
      </div>

      {/* Remarks */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Remarks'
          name='remarks'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          multiline
          value={inputState.remarks ?? ''}
          onChange={handleChange}
        />
      </FormControl>

      {/* Image */}
      {(preview || image)
        ? (
          <div className={classes.imageWrap}>
            <Avatar alt='image' variant='rounded' src={preview || image} className={classes.image} />
            <DeleteIconButton
              color='secondary'
              className={classes.deleteIconButton}
              onClick={handleDeleteImage}
            />
          </div>
          )
        : (
          <Button
            disableElevation
            variant='contained'
            component='label'
          >
            <Typography>Upload Image</Typography>
            <input accept='image/*' type='file' multiple={false} hidden onChange={onSelectFile} />
          </Button>
          )}
    </CollapsibleList>
  )
}

const useStyles = makeStyles(theme => ({
  imageWrap: {
    position: 'relative',
    width: 240,
  },
  image: {
    width: 240,
    height: 240,
  },
  deleteIconButton: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  tagInputWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  },
}))
