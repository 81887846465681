import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import JobTable from './JobTable'
import ReportJobSearchForm from './ReportJobSearchForm'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Report (props) {
  const classes = useStyles(props)
  const history = useHistory()
  const handleNew = () => {
    history.push('/report/new')
  }
  return (
    <Page>
      {/* content */}
      <PageHeader text='Report History'>
        <Button
          variant='contained'
          color='primary'
          className={classes.newBtn}
          onClick={handleNew}
        >
          New Report
        </Button>
      </PageHeader>
      <ReportJobSearchForm />
      <JobTable />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  newBtn: {
    marginLeft: 'auto',
  },
}))
