import { useSelector } from '@/redux'

/**
 * @function
 * @returns {ILedger[]}
 */
export function useLedgers () {
  return useSelector(state => state.riceCoin.data)
}

/**
 * @function
 * @returns {IPaginationMeta}
 */
export function usePaginationMeta () {
  return useSelector(state => state.riceCoin.meta)
}

/**
 * @function
 * @returns {IRiceCoinQueryParams}
 */
export function useQueryParams () {
  return useSelector(state => state.riceCoin.params)
}
