import { makeStyles } from '@material-ui/core/styles'
import ProgressLine from './ProgressLine'
import React from 'react'

/**
 *
 * @param {{
 * step: number
 * deliveryOrder: IDeliveryOrder
 * setLalamoveOprationDialogOpen: () => void
 * setSelectedOrderId: () => void
 * }} props
 * @returns
 */
export default function ProgressLines (props) {
  const classes = useStyles(props)

  return (
    <div className={classes.lines}>
      <ProgressLine provider='LALAMOVE' {...props} />
      <ProgressLine provider='DIMORDER' {...props} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  lines: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))
