import { TagPicker } from 'rsuite'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useInputState } from '@/hooks/inputStateContext'
import React from 'react'
import _ from 'lodash'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MultiSelectControl (props) {
  const { setting } = props
  const [inputState, updateInputState] = useInputState()
  const classes = useStyles(props)
  const handleChange = (e) => {
    updateInputState(setting.paramaterName, e)
  }
  const value = _.get(inputState, setting.paramaterName, [])
  return (
    <div className={classes.tagLine}>
      <Typography className={classes.label}>{setting.label}</Typography>
      <TagPicker
        onChange={handleChange}
        value={value}
        data={setting.items}
        placeholder={setting.label}
        style={{ width: 300 }}
        {...setting.inputProps}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  tagLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
}))
