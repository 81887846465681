import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import format from 'format-number'
import goAdminApi from '@/libs/api/goAdmin'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import ControlledTable from '@/components/Table/ControlledTable'
import ReceiptButton from '@/components/ReceiptButton'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderTable (props) {
  const classes = useStyles(props)
  const history = useHistory()
  const dispatch = useDispatch()
  const orders = useSelector(state => state.order.data)
  const meta = useSelector(state => state.order.meta)
  const params = useSelector(state => state.order.params)
  const [sendingSMS, setSendingSMS] = useState(false)

  React.useEffect(() => {
    dispatch(actions.order.getOrders())
  }, [])

  function OrderActionButtons (order) {
    const isDimOrderTakeaway = order.deliveryType === 'takeaway' && ['CUSTOMER_WEB', 'CUSTOMER_APP', 'SERVER'].includes(order.from)
    const isDimOrderDelivery = order.deliveryType === 'storeDelivery' && ['CUSTOMER_WEB', 'CUSTOMER_APP', 'SERVER'].includes(order.from)
    const isDimOrderTakeawayOrDelivery = isDimOrderTakeaway || isDimOrderDelivery

    // show action buttons 的條件
    const refundButton = false
    const showCancelButton = isDimOrderTakeawayOrDelivery && order.status === 'paid'
    const showDelayButton = isDimOrderDelivery && order.status === 'paid' && order.takeawayStatus !== 'delivered'

    return (
      <>
        {refundButton && (<div>{RefundButton(order)}</div>)}
        {showDelayButton && (<div>{SendDelaySMSButton(order)}</div>)}
        {showCancelButton && (<div>{CancelButton(order)}</div>)}
      </>
    )
  }

  function CancelButton (order) {
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        onClick={() => {
          dispatch(actions.app.toggleDialog('orderCancelDialog', null, {
            order,
          }))
        }}
      >
        Cancel
      </Button>
    )
  }
  function SendDelaySMSButton (order) {
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        disabled={sendingSMS}
        onClick={async () => {
          setSendingSMS(true)
          await toast.promise(
            goAdminApi.sendDelaySMS(order.id),
            {
              loading: 'Sending...',
              success: <b>Sent Delay SMS!</b>,
              error: <b>Could not send.</b>,
            },
          ).finally(() => {
            setSendingSMS(false)
          })
        }}
      >
        Send Delay SMS
      </Button>
    )
  }
  function RefundButton (order) {
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        onClick={() => {
          const data = {
            id: order.id,
            orderSerial: order.orderSerial,
            merchantName: order.merchantName,
            transactionDate: order.paidAt,
            orderAmount: order.roundedTotal,
            payment: order.payment,
          }
          dispatch(actions.app.toggleDialog('addRefund', null, data))
        }}
      >
        Refund
      </Button>
    )
  }

  const columns = [
    { accessor: row => <ReceiptButton orderSerial={row.serial} orderId={row.id} />, Header: 'Serial #' },
    {
      accessor: (o, i) => {
        return (
          <div>
            {moment(o.createdAt).format('YYYY-MM-DD HH:mm')}
            {o.pickupAt && (
              <><br /> 客人取餐{moment(o.pickupAt).format('YYYY-MM-DD HH:mm')}</>
            )}
            {o?.shipping?.lalamove?.scheduleAt && (
              <><br /> 司機取餐{moment(o.shipping.lalamove.scheduleAt).format('YYYY-MM-DD HH:mm')}</>
            )}
          </div>
        )
      },
      Header: 'Date',
    },
    {
      Header: 'Merchant',
      accessor: function accessorMerchant (row, i) {
        return (
          <div>
            <span className={classes.merchantName}>{row.merchantName}</span>
            <br />
            {row.merchantAddress}
            {row.table !== '' && (
              <> <br />枱號: {row.table} </>
            )}
            {row?.shipping?.lalamove?.startContactPhone && (
              <> <br />
                <Typography color='primary'>餐廳電話: {row.shipping.lalamove.startContactPhone}</Typography>
              </>
            )}
          </div>
        )
      },
      id: 1,
    },
    {
      Header: 'Customer',
      accessor: (o, i) => {
        return (
          <div onClick={() => handleCustomerClick(o)} className={cx(classes.hover, classes.underline)}>
            {o.name}
            {o.phone && (
              <><br />{o.phone}</>
            )}
            {o.address && (
              <><br />{o.address}</>
            )}
          </div>
        )
      },
      // id: 2,
    },
    {
      Header: 'Status',
      accessor: (o, i) => {
        return (
          <div>
            {o.status}
            {o.takeawayStatus && (
              <> ({o.takeawayStatus})</>
            )}
            {o.cancelReason && (
              <><br />{o.cancelReason}</>
            )}
          </div>
        )
      },
    },
    {
      accessor: 'roundedTotal',
      Header: 'Total',
      Cell: ({ value }) => format({ prefix: '$', suffix: '' })(value),
    },
    {
      Header: 'Payment',
      accessor: (row, i) => {
        const payment = _.sortBy(row?.payments, [o => o.status === 'paid' ? -1 : 1])[0]
        if (payment?.paymentMethod) {
          return (
            // TODO:
            <div>
              {payment.aliasName ? `${payment.aliasName.trim()} - ${payment.paymentMethod}` : payment.paymentMethod}
              {payment.gateway && (
                <> ({payment.gateway})</>
              )}
              {payment.status && (
                <><br />{payment.status}</>
              )}
            </div>
          )
        }
        return ''
      },
    },
    {
      accessor: row => {
        return OrderActionButtons(row)
      },
      Header: 'Action',
    },
  ].filter(o => o)

  const handleCustomerClick = (data) => {
    const { phone } = data
    dispatch(actions.customer.updateQuery({ mobile: phone.replace('+852', ''), page: 1 }))
    history.push('/customer')
  }

  const onPageChange = async (event, page) => {
    await dispatch(actions.order.updateQuery({ ...params, skip: page * meta.perPage }))
    dispatch(actions.order.getOrders())
  }

  const handleRowsPerPageChange = (event) => {
    dispatch(actions.order.updateMeta({
      perPage: event.target.value,
      currentPage: 1,
    }))
    dispatch(actions.order.getOrders())
  }

  return (
    <ControlledTable
      columns={columns}
      data={orders}
      pageSize={meta.perPage}
      currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
      rowCount={meta.total}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      rowsPerPageOptions={[10, 25, 50]}
    />
  )
}

const useStyles = makeStyles(() => ({
  merchantName: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 1.6,
  },
  underline: {
    textDecoration: 'underline',
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      color: '#1675e0',
    },
  },
}))
