import { Button, FormControl, Slide, Snackbar, Switch, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import qs from 'qs'

import { useMerchant } from '@/hooks/merchantContext'
import Dialog from '@/components/Dialog'
import config from '@/config'

const links = [
  {
    type: 'id',
    title: 'Merchant ID',
    link: '',
    v2: true,
    v1: true,
  },
  {
    type: 'dashboard',
    title: 'Merchant Dashboard',
    link: '',
    v2: true,
    v1: true,
  },
  {
    type: 'dinein',
    title: 'Default dine-in table order link',
    link: '',
    v1: true,
  },
  {
    type: 'V2dinein',
    title: 'Dine-in Static QR link',
    link: '',
    v2: true,
  },
  {
    type: 'V2share',
    title: 'Share link',
    link: '',
    v2: true,
  },
  {
    type: 'V2preorder',
    title: 'Pre order link',
    link: '',
    v2: true,
  },
  {
    type: 'waiter',
    title: 'Staff ordering link',
    link: '',
    v1: true,
  },
  {
    type: 'report',
    title: 'Report link',
    link: '',
    v1: true,
  },
  {
    type: 'preorder',
    title: 'Pre order link',
    link: '',
    v1: true,
  },
  {
    type: 'takeaway',
    title: 'Takeaway link',
    link: '',
    v1: true,
  },
  {
    type: 'delivery',
    title: 'Delivery link',
    link: '',
    v1: true,
  },
  {
    type: 'directory',
    title: 'Directory link',
    link: '',
    v1: true,
  },
  {
    type: 'V2takeaway',
    title: 'Takeaway link',
    link: '',
    v2: true,
  },
]

const compositeLink = (type, merchant) => {
  let params = qs.stringify({})
  let baseUrl = config.clientUrl
  if (type === 'id') {
    return merchant.id
  }
  if (type === 'dinein') {
    params = qs.stringify({
      m: merchant.id,
      ...merchant.tables?.length && { t: merchant.tables[0] },
    })
  }
  if (type === 'dashboard') {
    params = qs.stringify({
      m: merchant.id,
    })
    baseUrl = config.dashboardUrl
  }
  if (type === 'V2dinein') {
    params = qs.stringify({
      ...merchant.tables?.length && { t: merchant.tables[0] },
    })
    baseUrl = config.clientNewUrl + `/d2c/${merchant.id}`
  }
  if (type === 'V2share') {
    params = qs.stringify({
      init: true,
    })
    baseUrl = config.clientNewUrl + `/d2c/${merchant.id}`
  }
  if (type === 'V2preorder') {
    params = qs.stringify({
      init: true,
      dineIn: true,
    })
    baseUrl = config.clientNewUrl + `/d2c/${merchant.id}`
  }
  if (type === 'V2takeaway') {
    params = qs.stringify({
      init: true,
      takeaway: true,
    })
    baseUrl = config.clientNewUrl + `/d2c/${merchant.id}`
  }
  if (type === 'waiter') {
    params = qs.stringify({
      requireWaiterLogin: true,
    })
    baseUrl = config.clientWaiterUrl
  }
  if (type === 'takeaway') {
    params = qs.stringify({
      m: merchant.id,
      takeaway: true,
    })
  }
  if (type === 'report') {
    params = qs.stringify({
      m: merchant.id,
    })
    baseUrl = config.reportUrl
  }
  if (type === 'preorder') {
    params = qs.stringify({
      m: merchant.id,
    })
  }
  if (type === 'delivery') {
    params = qs.stringify({
      m: merchant.id,
      sdelivery: true,
    })
  }
  if (type === 'directory') {
    params = qs.stringify({
      m: merchant.id,
      dir: true,
    })
  }
  return `${baseUrl}?${params}`
}

const SlideTransition = (props) => {
  return <Slide {...props} direction='up' />
}
const MerchantLinkItem = (props) => {
  const { link, setSnackBarText } = props
  const classes = useStyles(props)
  return (
    <div>
      <Typography>{link.title}</Typography>
      <div className={classes.linkLine}>
        <FormControl variant='outlined' margin='dense' fullWidth>
          <TextField
            type='text'
            variant='outlined'
            size='small'
            fullWidth
            value={link.link || ''}
          />
        </FormControl>
        <Button
          disableElevation
          variant='contained'
          color='primary'
          onClick={async () => {
            await navigator.clipboard.writeText(link.link)
            setSnackBarText(`${link.title} copied`)
          }}
        >COPY
        </Button>
      </div>
    </div>
  )
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function LinkDialog (props) {
  const classes = useStyles(props)
  const [merchantState, setMerchantState] = useMerchant()
  const [snackBarText, setSnackBarText] = React.useState(null)
  const [validLinks, setValidLinks] = React.useState([])
  const [useV2Link, setUseV2Link] = React.useState(false)
  const [merchant, setMerchant] = React.useState({})

  React.useEffect(() => {
    setMerchant(merchantState.merchant)
    setUseV2Link(!!merchantState?.merchant?.setting?.useCustomerWebV2)
  }, [merchantState.merchant])

  React.useEffect(() => {
    const validLinks = links.filter(link => useV2Link ? link.v2 : link.v1)
    setValidLinks(validLinks)
  }, [useV2Link, merchant])

  React.useEffect(() => {
    links.forEach((link) => {
      link.link = compositeLink(link.type, merchant)
    })
  }, [merchant])

  const open = merchantState.openLinkDialog

  const handleClose = () => {
    setMerchantState('openLinkDialog', false)
  }
  // exited is used in order to prevent a flash of content
  const handleExited = () => {
    setMerchantState('merchant', {})
  }
  const handleSwitchChange = async (event) => {
    const { checked } = event.target
    setUseV2Link(checked)
  }

  return (
    <Dialog
      title={merchantState.merchant?.name}
      open={open}
      TransitionProps={{ onExited: handleExited }}
      handleClose={handleClose}
    >
      <div className={classes.content}>
        <div className={classes.switchLine}>
          <Typography variant='h5'>{useV2Link ? '2.0 Link' : '1.0 Link'}</Typography><Switch checked={useV2Link} onChange={(event) => handleSwitchChange(event)} color='primary' />
        </div>
        {
          validLinks.map(link => {
            return (
              <MerchantLinkItem key={link.title} link={link} setSnackBarText={setSnackBarText} />
            )
          })
        }
      </div>
      <Snackbar
        open={Boolean(snackBarText)}
        onClose={() => setSnackBarText(null)}
        autoHideDuration={2000}
        TransitionComponent={SlideTransition}
        message={snackBarText}
        key={snackBarText}
      />
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    gap: 16,
  },
  linkLine: {
    display: 'flex',
    gap: 8,
  },
  switchLine: {
    display: 'flex',
    alignItems: 'center',
  },
}))
