import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useImmer } from 'use-immer'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Provider as ErrorsStateContextProvider } from '@/hooks/ErrorsStateContext'
import { Provider as InputStateContextProvider } from '@/hooks/inputStateContext'
import { usePromoCode } from '@/hooks/PromotionContext'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'
import goAdminApi from '@/libs/api/goAdmin'

import AmountSettings from './settings/AmountSettings'
import BasicSettings from './settings/BasicSettings'
import ClaimCouponSettings from './settings/ClaimCouponSettings'
import CouponSettings from './settings/CouponSettings'
import OtherSettings from './settings/OtherSettings'
import QuotaSettings from './settings/QuotaSettings'
import ValidSettings from './settings/ValidSettings'

const initialState = {
  type: 'PROMOCODE',
  applyTo: 'ALL',
  action: { type: 'NONE', payload: '' },
  quota: 1,
  validFrom: '',
  validUntil: '',
  claimCouponFrom: '',
  claimCouponUntil: '',
  onlyRegisteredUser: false,
  claimNewUser: false,
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function PromotionDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [errors, setErrors] = React.useState({})
  const [promoCode, setPromoCode] = usePromoCode()
  const [inputState, setInputState] = useImmer({})
  const updateInputState = (path, value) => {
    setInputState((draft) => {
      _.set(draft, path, value)
    })
  }

  const validateInput = React.useCallback(() => {
    if (!promoCode) {
      return false
    }

    const temp = {}
    if (inputState.type === 'PROMOCODE') {
      if (!inputState.code) {
        const message = 'type 選擇 PROMOCODE 時，code 必填'
        temp.code = message
      }
    }

    if (inputState.type === 'COUPON') {
      if (!inputState.name?.zh || !inputState.name?.en) {
        const message = 'type 選擇 COUPON 時，name 必填'
        if (!inputState.name?.zh) {
          temp['name.zh'] = message
        }
        if (!inputState.name?.en) {
          temp['name.en'] = message
        }
      }
      if (inputState.quotaPerUser > 10) {
        const message = 'type 選擇 COUPON 時 quotaPerUser 最大值為 10'
        temp.quotaPerUser = message
      }
      if (inputState.action?.type !== 'NONE' && !inputState.action?.payload) {
        const message = 'action.type 有設定時，action.payload 必填'
        temp['action.payload'] = message
      }
    }

    if (inputState.amount < 0 || inputState.amount > 999) {
      const message = 'amount 最小值為 0 最大值為 999'
      temp.amount = message
    }
    if (inputState.percent < 0 || inputState.percent > 100) {
      const message = 'percent 最小值為 0 最大值為 100'
      temp.percent = message
    }
    if (inputState.maxAmount < 0) {
      const message = 'max amount 最小值為 0'
      temp.maxAmount = message
    }
    if (inputState.minOrderAmount < 0) {
      const message = 'min order amount 最小值為 0'
      temp.minOrderAmount = message
    }
    if (inputState.quota < 0 || inputState.quota > 99999) {
      const message = 'quota 最小值為 1 最大值為 99999'
      temp.quota = message
    }

    if (!Number(inputState.amount) && !Number(inputState.percent)) {
      const message = 'amount 及 percent 必填其中一種'
      temp.amount = message
      temp.percent = message
    }

    if (!inputState.validFrom || !inputState.validUntil) {
      const message = 'validFrom 與 validUntil 為必填'
      temp.validFrom = message
      temp.validUntil = message
    }

    if (!_.isEmpty(temp)) {
      setErrors(temp)

      let message = ''
      if (_.size(temp) === 1) {
        message = _.map(temp, message => `* ${message}`)
      } else {
        message = _
          .chain(temp)
          .pickBy() // // pickBy() 預設使用 _.identity 來 pick，要注意的是這也會把 object 中任何 falsy value 給去掉，在這個情況是允許使用的，因為 temp 裡面只會有 string 或 undefined
          .map(message => `* ${message}`)
          .uniq() // 去除重複的錯誤訊息
          .join('\n')
          .value()
      }

      dispatch(actions.app.toggleDialog('alertDialog', true, {
        title: 'Error',
        content: message,
      }))

      return false
    } else {
      return true
    }
  }, [inputState, promoCode])

  React.useEffect(() => {
    setInputState((draft) => {
      // 初始 inputState
      const newState = {
        ...initialState,
        ..._.cloneDeep(promoCode),
      }
      if (promoCode?.validFrom) {
        newState.validFrom = moment(promoCode.validFrom).format(moment.HTML5_FMT.DATETIME_LOCAL)
      }
      if (promoCode?.validUntil) {
        newState.validUntil = moment(promoCode.validUntil).format(moment.HTML5_FMT.DATETIME_LOCAL)
      }
      if (promoCode?.claimCouponFrom) {
        newState.claimCouponFrom = moment(promoCode.claimCouponFrom).format(moment.HTML5_FMT.DATETIME_LOCAL)
      }
      if (promoCode?.claimCouponUntil) {
        newState.claimCouponUntil = moment(promoCode.claimCouponUntil).format(moment.HTML5_FMT.DATETIME_LOCAL)
      }

      return newState
    })

    // 沒有選擇 type 時，讓 type 是NONE
    if (promoCode?.action?.type === '') {
      updateInputState(['action', 'type'], 'NONE')
    }

    async function restoreMerchantName () {
      const merchantId = promoCode.action.payload
      const merchant = await goAdminApi.getMerchant(merchantId)
      updateInputState(['action', 'merchantName'], merchant?.name ?? '')
      updateInputState(['action', 'inputMerchantName'], merchant?.name ?? '')
    }

    if (
      promoCode?.action?.type === 'MERCHANT' &&
      promoCode?.action?.payload !== '' &&
      !inputState.action.inputMerchantName
    ) {
      restoreMerchantName()
    }
  }, [promoCode])

  const open = Boolean(promoCode)
  const disabled = _.isEqual(promoCode, inputState)

  const handleClose = () => {
    setPromoCode()
    setErrors({})
  }

  const handleSubmit = async () => {
    const id = inputState?.id
    const selectedFile = inputState?.selectedFile

    const valid = validateInput()
    if (!valid) return

    try {
      if (!id) {
        const result = await dispatch(actions.promoCode.createPromoCode(inputState))
        if (selectedFile) {
          await dispatch(actions.promoCode.uploadPromoCodeImage(result?.id, selectedFile))
        }
      } else {
        await dispatch(actions.promoCode.updatePromoCode(inputState))
        if (selectedFile) {
          await dispatch(actions.promoCode.uploadPromoCodeImage(id, selectedFile))
        }
      }
      dispatch(actions.promoCode.getPromoCodes())
    } catch (error) {
      dispatch(actions.app.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
    } finally {
      handleClose()
    }
  }

  return (
    <InputStateContextProvider value={[inputState, updateInputState]}>
      <ErrorsStateContextProvider value={[errors, setErrors]}>
        <Dialog
          fullHeight
          title='Promotion Dialog'
          open={open}
          handleClose={handleClose}
          renderActions={() => {
            return (
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disableElevation
                disabled={disabled}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )
          }}
        >
          <div className={classes.form}>
            <BasicSettings />
            <AmountSettings />
            <QuotaSettings />
            <ValidSettings />
            <CouponSettings />
            <ClaimCouponSettings />
            <OtherSettings />
          </div>
        </Dialog>
      </ErrorsStateContextProvider>
    </InputStateContextProvider>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}))
