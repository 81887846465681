import { TextField } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import TableSearchForm from '@/components/Table/TableSearchForm'

const defaultState = {
  limit: 10,
  skip: 0,
  query: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function MerchantSearchForm (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [inputState, setInputState] = React.useState(defaultState)
  const params = useSelector(state => state.merchant.params)

  React.useEffect(() => {
    if (params) {
      setInputState(params)
    }
  }, [params])

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState({ ...inputState, [name]: value })
  }

  const handleSearch = async (event) => {
    event.preventDefault()

    await dispatch(actions.merchant.updateQuery({ ...inputState, skip: 0 }))
    dispatch(actions.merchant.getMerchants())
  }

  const handleClear = (event) => {
    event.preventDefault()
    dispatch(actions.merchant.updateQuery(defaultState))
    dispatch(actions.merchant.getMerchants())
  }

  return (
    <TableSearchForm
      handleSearch={handleSearch}
      handleClear={handleClear}
    >
      <TextField
        label='ID or Name'
        name='query'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.query}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
      />
    </TableSearchForm>
  )
}

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiInputBase-formControl': {
      background: theme.palette.common.white,
    },
  },
}))
