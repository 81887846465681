import { Button, Slide, Snackbar, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import ControlledTable from '@/components/Table/ControlledTable'
import React from 'react'
import format from 'format-number'
import hooks from '@/hooks'
import moment from 'moment'

function SlideTransition (props) {
  return <Slide {...props} direction='up' />
}

export default function UserTable (props) {
  const dispatch = useDispatch()
  const posPayLinks = hooks.posPayLink.usePosPayLinks()
  const resultPosPayLinks = posPayLinks// .filter(o => o.username.includes(params.name))
  const [snackBarText, setSnackBarText] = React.useState(null)
  const meta = useSelector(state => state.posPayLink.meta)
  const params = useSelector(state => state.posPayLink.params)

  const classes = useStyles(props)

  const onPageChange = async (event, page) => {
    await dispatch(actions.posPayLink.updateQuery({ ...params, skip: page * meta.perPage }))
    dispatch(actions.posPayLink.getPosPayLinks())
  }

  const Status = (row) => {
    return (
      <div className={classes.actionsCol}>
        {row.status}
      </div>
    )
  }
  const Url = (row) => {
    return (
      <div className={classes.actionsCol}>
        <Button
          disableElevation
          variant='contained'
          color='primary'
          onClick={async () => {
            await navigator.clipboard.writeText(row.url)
            setSnackBarText('URL Copied')
          }}
        >COPY
        </Button>
      </div>
    )
  }

  const columns = [
    {
      accessor: (o, i) => {
        return (
          <div>
            {moment(o.createdAt).format('YYYY-MM-DD HH:mm')}
          </div>
        )
      },
      Header: 'Date',
    },
    {
      accessor: (row, i) => {
        return (
          <div>
            <Typography variant='h6'>{row.merchantName}</Typography>
            <Typography className={classes.caption} variant='caption'>{row.plan}</Typography>
            <Typography className={classes.caption} variant='caption'>{row.period}</Typography>
          </div>
        )
      },
      Header: 'Description',
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      Cell: ({ value }) => format({ prefix: '$', suffix: '' })(value),
    },
    {
      Header: 'URL',
      accessor: row => Url(row),
    },
    {
      Header: 'Status',
      accessor: row => Status(row),
    },
    {
      accessor: 'expiryDate',
      Header: 'Link Expiry Date',
      Cell: ({ value }) => value && moment(value, 'DDMMYYYY').format('YYYY/MM/DD'),
    },
  ]

  React.useEffect(() => {
    dispatch(actions.posPayLink.getPosPayLinks())
  }, [])

  return (
    <>
      <ControlledTable
        columns={columns}
        data={resultPosPayLinks}
        pageSize={meta.perPage}
        currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
        rowCount={meta.total}
        onPageChange={onPageChange}
      />
      <Snackbar
        open={Boolean(snackBarText)}
        onClose={() => setSnackBarText(null)}
        autoHideDuration={2000}
        TransitionComponent={SlideTransition}
        message={snackBarText}
        key={snackBarText}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  nameCol: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  actionsCol: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))
