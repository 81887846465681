import CollapsibleList from '@/components/CollapsibleList'
import React from 'react'
import SettingControl from '../SettingControl'

const SETTINGS = [
  { label: 'Directory Broadcast Message', paramaterName: 'directoryBroadcastMessage', type: 'input' },
  { label: 'Directory Tags', paramaterName: 'directoryTags', type: 'input' },
  { label: 'Directory Delivery Time Text', paramaterName: 'directoryDeliveryTimeText', type: 'textarea' },
  { label: 'Directory Takeaway Time Text', paramaterName: 'directoryTakeawayTimeText', type: 'textarea' },
  { label: 'Directory Opening Time Text', paramaterName: 'directoryOpeningTimeText', type: 'textarea' },
  { label: 'Directory Background Image', paramaterName: 'directoryBackgroundImage', type: 'input' },
  { label: 'Google Map Link', paramaterName: 'mapLink', type: 'input' },
  { label: 'Custom Directory Takeaway Link', paramaterName: 'customDirectoryTakeawayLink', type: 'input' },
  { label: 'Custom Directory Sdelivery Link', paramaterName: 'customDirectorySdeliveryLink', type: 'input' },
  { label: 'whatsapp 連結', paramaterName: 'whatsappLink', type: 'input' },
  { label: 'line 連結', paramaterName: 'lineLink', type: 'input' },
  { label: 'wechat 連結', paramaterName: 'wechatLink', type: 'input' },
  { label: 'instagram 連結', paramaterName: 'instagramLink', type: 'input' },
  { label: 'facebook 連結', paramaterName: 'facebookLink', type: 'input' },
]

/**
 *
 * @param {*} props
 * @returns
 */
export default function DirectorySettings (props) {
  const [expand, setExpand] = React.useState(false)

  return (
    <CollapsibleList title='Directory' expand={expand} setExpand={setExpand}>
      {
        SETTINGS.map(setting => (
          <SettingControl key={setting.label} setting={setting} />
        ))
      }
    </CollapsibleList>
  )
}
