import config from '@/config'
import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = config.firebase

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app() // if already initialized, use that one
}

export const auth = firebase.auth()

export const getToken = async () => {
  const firebaseUser = firebase.auth().currentUser
  if (firebaseUser) {
    const token = await firebaseUser.getIdToken()
    return token
  }
  return ''
}
