import _ from 'lodash'
import * as XLSX from 'xlsx'

export const exportXlsx = (name, headers, data) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const characters = 'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,AO,AP,AQ,AR,AS,AT,AU,AV,AW,AX,AY,AZ'.split(',')
  if (worksheet[`${characters[0]}1`]) {
    _.forEach(headers, (title, index) => {
      worksheet[`${characters[index]}1`].v = title
    })
  }

  const workbook = {
    Sheets: {
      data: worksheet,
    },
    SheetNames: [
      'data',
    ],
  }
  XLSX.writeFile(workbook, name)
}
