const roles = [
  { id: 'SUPER_ADMIN', name: 'Super Admin' },
  { id: 'ADMINISTRATOR', name: 'Administrator' },
  { id: 'TECHNICAL', name: 'Technical' },
  { id: 'SALES_DIRECTOR', name: 'Sales Director' },
  { id: 'MARKETING', name: 'Marketing' },
  { id: 'SALES', name: 'Sales' },
  { id: 'ADMINISTRATION', name: 'Administration' },
  { id: 'RICECOIN_ADMIN', name: 'RiceCoin Admin' },
  { id: 'FINANCING', name: 'Financing' },
  { id: 'SYSTEM_CONFIG_MANAGER', name: 'System Config Manager' },
  { id: 'DIMBOX_MANAGER', name: 'Dimbox Manager' },
]
const plans = [
  { id: 'DimPOS', name: ' DimPOS' },
  { id: 'DimSHOP', name: 'DimSHOP' },
  { id: 'DimPOS+QR', name: 'DimPOS+QR' },
  { id: 'DimPOS+SHOP', name: 'DimPOS+SHOP' },
  { id: 'DimPOS 星級', name: 'DimPOS 星級' },
  { id: 'DimTouch', name: 'DimTouch' },
]
export {
  roles,
  plans,
}
