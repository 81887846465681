import React from 'react'
import _ from 'lodash'

import AddIconButton from '@/components/IconButton/AddIconButton'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import { useSelector } from '@/redux'

import AddDriverDialog from './AddDriverDialog'
import DriverSearchForm from './DriverSearchForm'
import DriverTable from './DriverTable'

export const initialFilters = {
  name: '',
  contact: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function Driver (props) {
  const [addDriverDialogOpen, setAddDriverDialogOpen] = React.useState(false)
  const driversData = useSelector(state => state.driver.data)
  const [drivers, setDrivers] = React.useState(driversData)
  const [filters, setFilters] = React.useState(initialFilters)

  React.useEffect(() => {
    const result = _
      .chain(driversData)
      .filter(driver => {
        return (
          driver.name.toUpperCase().match(filters.name.toUpperCase()) &&
          driver.contact.match(filters.contact)
        )
      })
      .map(driver => ({ ...driver, disabled: Boolean(driver.disabled) })) // driver.disabled 是後來加入的，有可能是 undefined，undefinde desc 排序會在最前面
      .orderBy(['onDuty', 'disabled'], ['desc', 'asc']) // onDuty 的排前面，disabled 的排最後面
      .value()
    setDrivers(result)
  }, [driversData, filters])

  return (
    <Page>
      {/* content */}
      <PageHeader text='Driver'>
        <AddIconButton color='primary' onClick={() => setAddDriverDialogOpen(true)} />
      </PageHeader>
      <DriverSearchForm onApplyFilter={setFilters} />
      <DriverTable drivers={drivers} />
      <AddDriverDialog open={addDriverDialogOpen} handleClose={() => setAddDriverDialogOpen(false)} />
    </Page>
  )
}
