import { actions, useDispatch } from '@/redux'
import AddIconButton from '@/components/IconButton/AddIconButton'
import AddUserDialog from './AddUserDialog'
import EditUserDialog from './EditUserDialog'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import React from 'react'
import UserSearchForm from './UserSearchForm'
import UserTable from './UserTable'
export default function User (props) {
  const dispatch = useDispatch()
  const handleOpenDialog = () => {
    dispatch(actions.app.toggleDialog('addUser', null, {}))
  }

  return (
    <Page>
      <PageHeader text='User'>
        <AddIconButton color='primary' onClick={handleOpenDialog} />
      </PageHeader>
      <UserSearchForm />
      <UserTable />
      <AddUserDialog />
      <EditUserDialog />
    </Page>
  )
}
