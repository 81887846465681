/**
 * use [change-case]{@link https://www.npmjs.com/package/change-case} casing function to change object's key (deep)
 * @param {Object<T>} obj
 * @param {(v: string, ops?: any) => string} casingFunction `change-case` casing function
 * @returns {Object<T>}
 */
export default function changeKeys (
  obj,
  casingFunction,
) {
  if (!obj || (obj.constructor !== Object && obj.constructor !== Array)) {
    return obj
  }
  if (obj.constructor === Object) {
    return Object.keys(obj).reduce((all, key) => {
      // stripRegexp 預設為 /[^A-Z0-9]/gi
      // 為了處理 amount[gte], amount[lte], 所以要把 [] 加進去避免被分割
      // https://github.com/blakeembrey/change-case#options
      const newKey = casingFunction(key, { stripRegexp: /[^A-Z0-9[\]]/gi })
      all[newKey] = obj[key] && (obj[key].constructor === Object || obj[key].constructor === Array)
        ? changeKeys(obj[key], casingFunction)
        : obj[key]
      return all
    }, {})
  } else {
    return obj.map(e => changeKeys(e, casingFunction))
  }
}
