import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IPosPayLinkQueryParams} */
export const initialParams = {
  description: '',
  limit: 10,
  skip: 0,
}

/** @type {IPosPayLinkState} */
export const initialState = {
  isInit: false,
  data: [],
  meta: {
    perPage: 10,
    currentPage: 1,
    total: 0,
  },
  params: initialParams,
}

export default produce(
  /**
   *  @param {IPosPayLinkState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_DATA: {
        const { data } = action.payload
        draft.data = data
        break
      }
      case ActionTypes.UPDATE_META: {
        const { total } = action.payload
        draft.meta = { ...draft.meta, total }
        break
      }
      case ActionTypes.UPDATE_PARAMS: {
        const { params } = action.payload
        draft.params = params
        draft.meta.currentPage = (params.skip / draft.meta.perPage) + 1
        break
      }
      default:
        break
    }
  },
  initialState,
)
