/* eslint-disable no-unused-vars */
import _ from 'lodash'
import axios, { AxiosInstance } from 'axios'

import { getToken } from '@/libs/firebase'
import config from '@/config'
import qs from 'qs'

class QuickPayApi {
  /** @type {AxiosInstance} */
  axios

  constructor (url) {
    this.axios = axios.create({
      baseURL: url || config.quickPayApi,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    })

    this.axios.interceptors.request.use(
      async config => {
        config.headers.Authorization = `TOKEN ${await getToken()}`
        return config
      },
      error => error,
    )
  }

  /**
   * @param {IPosPayLinkQueryParams} params
   * @returns {{
   * data: IPosPayLink[]
   * total: number
   * }}
   */
  list = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/quickpaylinks', {
      params,
    })
    return response.data
  }

  /**
   * @param {ICreatePosPayLinkParams} params
   * @returns {}
   */
  create = async (params) => {
    /** @type {} */
    const response = await this.axios.post('a/quickpaylinks',
      params,
    )
    return response.data
  }
}
export default new QuickPayApi()
