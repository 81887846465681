import React from 'react'

import CollapsibleList from '@/components/CollapsibleList'
import SettingControl from '../SettingControl'

const SETTINGS = [
  { label: '外賣自取現金支付功能', paramaterName: 'takeawayCashPayment', type: 'switch' },
  { label: '外賣自取信用卡支付功能', paramaterName: 'takeawayCreditCardPayment', type: 'switch' },
  { label: '信用卡免驗證', paramaterName: 'skipCreditCardVerification', type: 'switch' },
  { label: '微信支付', paramaterName: 'enableWechatPay', type: 'switch' },
  { label: '支付寶', paramaterName: 'enableAliPay', type: 'switch' },
  { label: 'FPS', paramaterName: 'enableFps', type: 'switch' },
  { label: 'PayMe', paramaterName: 'enablePayMe', type: 'switch' },
  { label: 'Octopus Online', paramaterName: 'enableOctopusOnline', type: 'switch' },
  { label: '堂食客人付款', paramaterName: 'enableDineInCustomerPay', type: 'switch' },
  { label: '堂食客人只限 App 付款', paramaterName: 'dineInPayOnAppOnly', type: 'switch' },
  { label: '堂食信用卡付款', paramaterName: 'enableDineInCreditCardPayment', type: 'switch' },
  { label: '堂食信用卡付款最低消費', paramaterName: 'dineInCreditCardPaymentMinCharge', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '堂食微信支付', paramaterName: 'enableDineInWechatPay', type: 'switch' },
  { label: '堂食支付寶', paramaterName: 'enableDineInAliPay', type: 'switch' },
  { label: '堂食 FPS', paramaterName: 'enableDineInFps', type: 'switch' },
  { label: '堂食 PayMe', paramaterName: 'enableDineInPayMe', type: 'switch' },
  { label: '堂食 Octopus Online', paramaterName: 'enableDineInOctopusOnline', type: 'switch' },
]

/**
 *
 * @param {*} props
 * @returns
 */
export default function PaymentSettings (props) {
  const [expand, setExpand] = React.useState(false)

  return (
    <CollapsibleList title='付款方式設定' expand={expand} setExpand={setExpand}>
      {
        SETTINGS.map(setting => {
          return (
            <SettingControl key={setting.label} setting={setting} />
          )
        })
      }
    </CollapsibleList>
  )
}
