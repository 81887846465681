import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { useInputState } from '@/hooks/inputStateContext'
import AddIconButton from '@/components/IconButton/AddIconButton'
import CollapsibleList from '@/components/CollapsibleList'
import DeleteIconButton from '@/components/IconButton/DeleteIconButton'

const weekdayFrom0 = [0, 1, 2, 3, 4, 5, 6]
const defaultTimeRange = { start: undefined, startMin: undefined, end: undefined, endMin: undefined, startTime: '', endTime: '' }

/**
 *
 * @param {*} props
 * @returns
 */
export default function ClaimCouponSettings (props) {
  const classes = useStyles(props)
  const [expand, setExpand] = React.useState(false)
  const [inputState, updateInputState] = useInputState()

  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
  }

  const handleAddTimeRange = (weekday) => {
    const claimCouponOpenings = inputState.claimCouponOpenings ?? {}
    const timeRanges = _.cloneDeep(claimCouponOpenings[weekday]) ?? []
    timeRanges.push(defaultTimeRange)
    if (!inputState.claimCouponOpenings) {
      // claimCouponOpenings 必須要是 object，若這裡沒有初始成 object 會自動變成 array
      updateInputState('claimCouponOpenings', {})
    }
    updateInputState(['claimCouponOpenings', weekday], timeRanges)
  }

  const handleTimeChange = (e, weekday, index) => {
    const { name, value } = e.target
    const time = value
    const hh = Number(time.split(':')[0])
    const mm = Number(time.split(':')[1])
    if (name === 'startTime') {
      updateInputState(['claimCouponOpenings', weekday, index, 'start'], hh)
      updateInputState(['claimCouponOpenings', weekday, index, 'startMin'], mm)
      updateInputState(['claimCouponOpenings', weekday, index, name], value)
    }

    if (name === 'endTime') {
      updateInputState(['claimCouponOpenings', weekday, index, 'end'], hh)
      updateInputState(['claimCouponOpenings', weekday, index, 'endMin'], mm)
      updateInputState(['claimCouponOpenings', weekday, index, name], value)
    }
  }

  const handle24h = (e, weekday, index) => {
    const { checked } = e.target
    if (checked) {
      updateInputState(['claimCouponOpenings', weekday, index, 'start'], 0)
      updateInputState(['claimCouponOpenings', weekday, index, 'startMin'], 0)
      updateInputState(['claimCouponOpenings', weekday, index, 'end'], 0)
      updateInputState(['claimCouponOpenings', weekday, index, 'endMin'], 0)
      updateInputState(['claimCouponOpenings', weekday, index, 'startTime'], '00:00')
      updateInputState(['claimCouponOpenings', weekday, index, 'endTime'], '00:00')
    } else {
      const { start, startMin, end, endMin, startTime, endTime } = defaultTimeRange
      updateInputState(['claimCouponOpenings', weekday, index, 'start'], start)
      updateInputState(['claimCouponOpenings', weekday, index, 'startMin'], startMin)
      updateInputState(['claimCouponOpenings', weekday, index, 'end'], end)
      updateInputState(['claimCouponOpenings', weekday, index, 'endMin'], endMin)
      updateInputState(['claimCouponOpenings', weekday, index, 'startTime'], startTime)
      updateInputState(['claimCouponOpenings', weekday, index, 'endTime'], endTime)
    }
  }

  const handleDelete = (weekday, index) => {
    const timeRanges = _.cloneDeep(inputState.claimCouponOpenings?.[weekday])
    timeRanges.splice(index, 1)
    updateInputState(['claimCouponOpenings', weekday], timeRanges)
  }

  if (inputState.type !== 'COUPON') {
    // 非 Coupon 不顯示
    return null
  }

  return (
    <CollapsibleList title='Coupon 領取時間' expand={expand} setExpand={setExpand}>
      {/* Claim Coupon From */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Claim Coupon From'
          name='claimCouponFrom'
          type='datetime-local'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.claimCouponFrom ?? ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <FormHelperText>可領券日期時間:開始</FormHelperText>
      </FormControl>

      {/* Claim Coupon Until */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Claim Coupon Until'
          name='claimCouponUntil'
          type='datetime-local'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.claimCouponUntil ?? ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <FormHelperText>可領券日期時間:結束</FormHelperText>
      </FormControl>

      {_.map(weekdayFrom0, (weekday) => {
        return (
          <div key={weekday}>
            <div className={classes.weekdayTitleWrap}>
              <Typography>{moment().weekday(weekday).format('dddd')}</Typography>
              <AddIconButton color='primary' onClick={() => handleAddTimeRange(weekday)} />
            </div>
            <div>
              {_.map(inputState.claimCouponOpenings?.[weekday], (timeRange, index) => {
                const is24h = _.every([
                  timeRange.start,
                  timeRange.startMin,
                  timeRange.end,
                  timeRange.endMin,
                ], number => number === 0)

                const timeRangeKey = ['claimCouponOpenings', weekday, index]
                const startH = _.get(inputState, [...timeRangeKey, 'start'])
                const startM = _.get(inputState, [...timeRangeKey, 'startMin'])
                const startTime = _.get(inputState, [...timeRangeKey, 'startTime']) || (
                  !_.isNil(startH) && !_.isNil(startM)
                    ? `${String(startH).padStart(2, '0')}:${String(startM).padStart(2, '0')}`
                    : ''
                )
                const endH = _.get(inputState, [...timeRangeKey, 'end'])
                const endM = _.get(inputState, [...timeRangeKey, 'endMin'])
                const endTime = _.get(inputState, [...timeRangeKey, 'endTime']) || (
                  !_.isNil(endH) && !_.isNil(endM)
                    ? `${String(endH).padStart(2, '0')}:${String(endM).padStart(2, '0')}`
                    : ''
                )

                return (
                  <div key={index} className={classes.row}>
                    <FormControl variant='outlined' margin='dense' fullWidth>
                      <TextField
                        label='Start Time'
                        name='startTime'
                        type='time'
                        variant='outlined'
                        size='small'
                        fullWidth
                        disabled={is24h}
                        value={startTime}
                        onChange={(e) => handleTimeChange(e, weekday, index)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl variant='outlined' margin='dense' fullWidth>
                      <TextField
                        label='End Time'
                        name='endTime'
                        type='time'
                        variant='outlined'
                        size='small'
                        fullWidth
                        disabled={is24h}
                        value={endTime}
                        onChange={(e) => handleTimeChange(e, weekday, index)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControlLabel
                      control={<Checkbox checked={is24h} onChange={(e) => handle24h(e, weekday, index)} />}
                      label='24H'
                    />
                    <DeleteIconButton
                      color='secondary'
                      onClick={() => handleDelete(weekday, index)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </CollapsibleList>
  )
}

const useStyles = makeStyles(theme => ({
  weekdayTitleWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
}))
