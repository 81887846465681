import { DialogActions, DialogContent, DialogTitle, Dialog as MuiDialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

Dialog.prototypes = {
  open: PropTypes.bool,
  fullHeight: PropTypes.bool, // 巨大的表單，需要捲動時建議使用，會固定 100% 高度並持續顯示 scroll bar
  handleClose: PropTypes.func,
  title: PropTypes.string, // render in DialogTitle
  children: PropTypes.node, // render in DialogContent
  renderActions: PropTypes.func, // render in DialogAction
  dialogProps: PropTypes.object, // 傳給 Mui Dialog
  dialogClasses: PropTypes.object, // 傳給 Mui Dialog classes
  childrenClasses: PropTypes.object, // 用來自定 Dialog childrens: DialogTitle, DialogContent, DialogActions
}

export default function Dialog (props) {
  const classes = useStyles(props)
  const { dialogClasses, childrenClasses, children, open, handleClose, title, dialogProps, renderActions } = props

  return (
    <MuiDialog
      open={open}
      fullWidth
      maxWidth='md'
      onClose={handleClose}
      classes={{ paper: classes.rootPaper, ...dialogClasses }}
      {...dialogProps}
    >
      <DialogTitle className={clsx(classes.title, childrenClasses?.title)}>
        {title}
      </DialogTitle>
      <DialogContent className={clsx(classes.content, childrenClasses?.content)}>
        {children}
      </DialogContent>
      {renderActions && (
        <DialogActions className={clsx(classes.actions, childrenClasses?.actions)}>
          {renderActions()}
        </DialogActions>
      )}
    </MuiDialog>
  )
}

const useStyles = makeStyles(theme => ({
  rootPaper: (props) => {
    const classes = {
      borderRadius: theme.spacing(3),
      margin: 16,
      width: 'calc(100% - 32px)',
    }
    if (props.fullHeight) {
      classes.height = '100%'
    }
    return classes
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    overflow: (props) => props.fullHeight ? 'scroll' : 'auto',
  },
  actions: {
    padding: theme.spacing(2, 4),
  },
}))
