import { Switch } from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import RouteWithSubRoutes from './RouteWithSubRoutes'

Router.prototypes = {
  routes: PropTypes.array,
}

/**
 *
 * @param {{
 *  route: IRoute[]
 * }} props
 * @returns
 */
export default function Router (props) {
  const { routes } = props
  return (
    <Switch>
      {_.map(routes, (route) => <RouteWithSubRoutes key={route.path} {...route} />)}
    </Switch>
  )
}
