import { Button } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-hot-toast'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React, { useState } from 'react'
import ReceiptButton from '../ReceiptButton'
import goAdminApi from '@/libs/api/goAdmin'

export default function OrderCancelDialog (props) {
  const dialogName = 'orderCancelDialog'
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open, data } = useDialog(dialogName)
  const [loading, setLoading] = useState(false)
  const [approver, setApprover] = useState('')
  const [reason, setReason] = useState('')
  const order = data.order
  const handleSubmit = async () => {
    try {
      setLoading(true)
      await toast.promise(
        goAdminApi.cancelOrder(
          order.id,
          {
            reason,
            approver,
          },
        ),
        {
          loading: 'Sending...',
          success: <b>Order Cancelled!</b>,
          error: <b>Could not save.</b>,
        },
      )
      dispatch(actions.order.getOrders())
      closeDialog()
    } catch (error) {
      setLoading(false)
      if (error.error) {
        window.alert(error.error)
      } else {
        window.alert(error)
      }
    }
  }

  const closeDialog = () => {
    setApprover('')
    setReason('')
    setLoading(false)
    dispatch(actions.app.toggleDialog(dialogName, false, data))
  }

  if (!order) return (<></>)

  return (
    <Dialog
      open={open}
      handleClose={closeDialog}
      title='是否要取消訂單？'
    >
      <div className={classes.form}>
        <div className={classes.content}>
          <>
            <div>Merchant：{order.merchantName}</div>
            <div>
              <ReceiptButton orderSerial={order.serial} orderId={order.id} />
            </div>
            <div />
            <div>
              <label>
                取消原因：
                <input
                  autoFocus
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                批准者：
                <input
                  value={approver}
                  onChange={e => setApprover(e.target.value)}
                />
              </label>
            </div>
          </>
        </div>
        <div className='d-flex justify-center'>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            disabled={loading || !approver.length || !reason.length}
            onClick={() => { handleSubmit() }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: '500px',
    margin: '0 auto',
    gridAutoFlow: 'row',
    gridTemplateRows: '75px auto',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    alignItems: 'center',
    minWidth: '250px',
  },
  content: {
    // whiteSpace: 'pre',
    marginBottom: 24,
  },
}))
