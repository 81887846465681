import ControlledTable from '@/components/Table/ControlledTable'
import React from 'react'
import _ from 'lodash'

/**
 *
 * @param {{
 * data: []
 * }} props
 * @returns
 */
export default function SummaryTable (props) {
  const { data } = props

  const columns = [
    {
      Header: 'Merchant',
      accessor: function accessorMerchant (row, i) {
        return (<div>{row.merchantName}<br />{row.merchantAddress}</div>)
      },
      id: 1,
    },
    { accessor: row => _.join(row.itemSummary, '\n '), Header: 'Item Summary' },
  ]

  return (
    <ControlledTable
      columns={columns}
      data={data}
      pageSize={data.length}
      currentPage={0}
      rowCount={data.length}
      onPageChange={() => { }}
    />
  )
}
