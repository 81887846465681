import { TableHead as MuiTableHead, TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

TableHead.prototypes = {
  headerGroups: PropTypes.array,
}

/**
 *
 * @param {{
 *  headerGroups: any[]
 * }} props
 * @returns
 */
export default function TableHead ({ headerGroups }) {
  return (
    <MuiTableHead>
      {_.map(headerGroups, headerGroup => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {_.map(headerGroup.headers, column =>
            <TableCell {...column.getHeaderProps()}>
              {column.render('Header')}
            </TableCell>)}
        </TableRow>))}
    </MuiTableHead>
  )
}
