import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Dialog from '@/components/Dialog'

import SubmitButton from './SubmitButton'

/**
 * 使用情境：
 * 當客服手動下單時會導致外送單與 lalamove order 脫勾，訂單的狀態將不會更新
 * 需使用此視窗來手動更新外送單的 lalamove order id
 * 1. 當沒有輸入值時不可送出
 * 2. 當輸入值等於初始值時不可送出
 * 3. 按下送出時跳確認視窗
 * 4. 按下確認時 get lalamove order 檢查輸入的 orderId 是否存在
 *  a. 若存在則更新 delivery order 的 lalamove orderId
 *  b. 若不存在則跳 alert
 * @param {{
 * open: boolean
 * handleClose: () => void
 * selectedOrder?: IDeliveryOrder
 * }} props
 * @returns
 */
export default function ReplaceLalamoveOrderIdDialog (props) {
  const { open, handleClose, selectedOrder } = props
  const classes = useStyles(props)

  const deliveryOrderId = selectedOrder?.id
  const lalamoveOrderId = selectedOrder?.providers?.LALAMOVE?.orderId ?? ''
  const [loading, setLoading] = React.useState(false)
  const [input, setInput] = React.useState('')
  const handleChange = (e) => {
    setInput(e.target.value)
  }

  const disabled = React.useMemo(() => {
    // 當沒有輸入值時不可送出
    // 當輸入值等於初始值時不可送出
    if (!input) return true
    if (lalamoveOrderId === input) return true
    return false
  }, [input, lalamoveOrderId])

  return (
    <Dialog
      title='Replace Lalamove Order Id'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <SubmitButton
            deliveryOrderId={deliveryOrderId}
            lalamoveOrderId={lalamoveOrderId}
            input={input}
            disabled={disabled || loading}
            handleClose={handleClose}
            setLoading={setLoading}
          />
        )
      }}
    >
      <div className={classes.content}>
        <TableContainer className={classes.container}>
          <Table aria-label='simple table'>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cell} size='small' component='th' scope='row'>
                  ORIGINAL ID
                </TableCell>
                <TableCell className={classes.cell} size='small' align='right'>
                  <Typography>{lalamoveOrderId}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cell} size='small' component='th' scope='row'>
                  NEW ID
                </TableCell>
                <TableCell className={classes.cell} size='small' align='right'>
                  <TextField
                    label='New OrderId'
                    name='newOrderId'
                    type='text'
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={input}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <Typography variant='caption'><i>loading...</i></Typography>}
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme, props) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
  },
  container: {
    padding: '0 24px',
  },
  cell: {
    borderBottom: 'none',
  },
}))
