import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'
import React from 'react'
import _ from 'lodash'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SelectControl (props) {
  const { setting } = props
  const [inputState, updateInputState] = useInputState()
  const [errors, setErrors] = useErrors()
  const classes = useStyles(props)
  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
    setErrors({ ...errors, [name]: undefined })
  }

  const value = _.get(inputState, setting.paramaterName, '')

  return (
    <FormControl
      variant='outlined' margin='dense' fullWidth
      classes={{ root: classes.container }}
    >
      <InputLabel margin='dense'>{setting.label}</InputLabel>
      <Select
        label={setting.label}
        name={setting.paramaterName}
        variant='outlined'
        fullWidth
        value={value}
        onChange={handleChange}
        {...setting.inputProps}
      >
        {
        setting.items.map(item => {
          return (
            <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
          )
        })
      }
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    background: 'white !important',
  },
}))
