import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { initialFilters } from '@/pages/Driver'
import TableSearchForm from '@/components/Table/TableSearchForm'

/**
 * @typedef DriverSearchFormProps
 * @property {() => void} onApplyFilter
 */

/**
 *
 * @param {DriverSearchFormProps} props
 * @returns
 */
export default function DriverSearchForm (props) {
  const { onApplyFilter } = props
  const classes = useStyles(props)

  const [inputState, setInputState] = React.useState(initialFilters)

  const handleSearch = (event) => {
    event.preventDefault()
    onApplyFilter(inputState)
  }

  const handleReset = (event) => {
    event.preventDefault()
    setInputState(initialFilters)
    onApplyFilter(initialFilters)
  }

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState({ ...inputState, [name]: value })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  return (
    <TableSearchForm
      handleSearch={handleSearch}
      handleReset={handleReset}
    >
      {/* Driver Name Input */}
      <TextField
        label='Name'
        name='name'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.name}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
      />

      {/* Driver Contact Input */}
      <TextField
        label='Contact'
        name='contact'
        type='tel'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.contact}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        InputProps={{ startAdornment: <InputAdornment position='start'>+852</InputAdornment> }}
        inputProps={{ maxLength: 8 }}
      />
    </TableSearchForm>
  )
}

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiInputBase-formControl': {
      background: theme.palette.common.white,
    },
  },
}))
