import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/firestore'

import deliveryApi from '@/libs/api/delivery'
import nodeApi from '@/libs/api/node'
import * as appActions from '@/redux/app/actions'

import ActionTypes from './ActionTypes'
import AppActionTypes from '../app/ActionTypes'

const firestore = firebase.firestore()
let unsub = () => {}

/**
 *
 * @returns {ThunkFunction}
 */
export function unsubscribe () {
  return (dispatch, getState) => {
    unsub()
  }
}

/**
 *
 * @returns {ThunkFunction}
 */
export function getDrivers () {
  const apiName = 'createDriver'
  return async (dispatch, getState) => {
    try {
      dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
      unsub()
      unsub = firestore
        .collection('drivers')
        .onSnapshot((collection) => {
          const drivers = _.map(
            collection.docs,
            doc => {
              return doc.data()
            })
          dispatch({
            type: ActionTypes.UPDATE_DATA,
            payload: { data: drivers },
          })
        })
    } catch (error) {
      console.error('getDrivers error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 *
 * @param {{
 * name: string
 * contact: string
 * password: string
 * }}
 * @returns {ThunkFunction}
 */
export function createDriver ({ name, contact, password }) {
  const apiName = 'createDriver'
  const role = 'DRIVER'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      const { uid } = await nodeApi.createUser('driver' + contact, password, role)
      firestore
        .collection('drivers')
        .doc(uid)
        .set({
          id: uid,
          name: name,
          contact: contact,
          role: role,
          avatar: '',
          expoPushToken: '',
          onDuty: false,
          disabled: false,
        })
    } catch (error) {
      console.log('createDriver error', error)
      const message = error.message + '\n' + error.response?.data?.message
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: message }))
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 *
 * @param {IDriver} driver
 * @returns {ThunkFunction}
 */
export function addDriver (driver) {
  const apiName = 'addDriver'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      const ref = firestore.collection('drivers').doc()
      ref.set(_.chain(driver).clone().set('id', ref.id).value())
    } catch (error) {
      console.error('updateDriver error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 *
 * @param {string} id
 * @param {object} data
 * @returns {ThunkFunction}
 */
export function updateDriver (id, data) {
  const apiName = 'updateDriver'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      firestore
        .collection('drivers')
        .doc(id)
        .update(data)
    } catch (error) {
      console.error('updateDriver error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 *
 * @param {string} id
 * @param {boolean} enable
 * @returns {ThunkFunction}
 */
export function enableDriver (id, enable) {
  const apiName = 'enableDriver'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      const result = await deliveryApi.enableDriver(id, enable)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Success', content: `Driver successfully ${enable ? 'enabled' : 'disabled'}.` }))
      return result
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.error('enableDriver error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}
