import { useImmer } from 'use-immer'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'

import CollapsibleList from '@/components/CollapsibleList'
import SettingControl from '../SettingControl'

const SETTINGS = {
  mutedPrinter: { label: '靜音列印', paramaterName: 'mutedPrinter', type: 'switch' },
  hideTimeInReport: { label: '報表 日期格式隱藏時間', paramaterName: 'hideTimeInReport', type: 'switch' },
  batchLocaleOptions: { label: '廚房單 可選語言', paramaterName: 'batchLocaleOptions', type: 'multiSelect', items: [] },
  sdeliveryAutoPrintKitchenReceipt: { label: '廚房單 外送單自動列印', paramaterName: 'sdeliveryAutoPrintKitchenReceipt', type: 'switch' },
  printSerialOnReceiptTextStyle: { label: '廚房單、確認單 單號字型', paramaterName: 'printSerialOnReceiptTextStyle', type: 'select', items: [{ label: '0', value: 0 }, { label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }] },
  printSerialOnReceipt: { label: '廚房單、確認單 列印單號', paramaterName: 'printSerialOnReceipt', type: 'switch' },
  printCustomerCountOnKitchenReceipt: { label: '廚房單 列印人數', paramaterName: 'printCustomerCountOnKitchenReceipt', type: 'switch' },
  showPickupTimeInKitchenReceipt: { label: '廚房單 列印取餐時間', paramaterName: 'showPickupTimeInKitchenReceipt', type: 'switch' },
  showBatchIndexInKitchenReceipt: { label: '廚房單 列印點餐次數', paramaterName: 'showBatchIndexInKitchenReceipt', type: 'switch' },
  largerLineSpacingInKitchenReceipt: { label: '廚房單 增加項目行距', paramaterName: 'largerLineSpacingInKitchenReceipt', type: 'switch' },
  kitchenReceiptGroupOptionSet: { label: '廚房單 選項組合列印', paramaterName: 'kitchenReceiptGroupOptionSet', type: 'switch' },
  kitchenReceiptOptionSeparator: { label: '廚房單 選項分隔符', paramaterName: 'kitchenReceiptOptionSeparator', type: 'input', inputOption: { placeholder: 'use \\n for newline' } },
  printWithNoOptions: { label: '廚房單 沒有選項時 列印 **(正常)', paramaterName: 'printWithNoOptions', type: 'switch' },
  kitchenReceiptSetMenuHideLabel: { label: '廚房單 套餐隱藏(餐)', paramaterName: 'kitchenReceiptSetMenuHideLabel', type: 'switch' },
  largerSerialInReceipt: { label: '收據 放大訂單號碼', paramaterName: 'largerSerialInReceipt', type: 'switch' },
  printCustomerCountOnReceipt: { label: '收據 列印人數', paramaterName: 'printCustomerCountOnReceipt', type: 'switch' },
  receiptHideCustomerPhone: { label: '收據 隱藏客人電話', paramaterName: 'receiptHideCustomerPhone', type: 'switch' },
  hideReceiptAddress: { label: '收據 隱藏客人地址', paramaterName: 'hideReceiptAddress', type: 'switch' },
  receiptHideFreeItem: { label: '收據 隱藏 $0 項目', paramaterName: 'receiptHideFreeItem', type: 'switch' },
  setMenuHideFreeItem: { label: '收據 隱藏 $0 套餐項目', paramaterName: 'setMenuHideFreeItem', type: 'switch' },
  sortReceiptItemByCategory: { label: '收據 項目跟分類排序', paramaterName: 'sortReceiptItemByCategory', type: 'switch' },
  printPayQrOnReceipt: { label: '收據 列印付款 Barcode', paramaterName: 'printPayQrOnReceipt', type: 'switch' },
  enableCheckoutReceipt: { label: '收據 啓用「結帳單」', paramaterName: 'enableCheckoutReceipt', type: 'switch' },
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function PrinterSettings (props) {
  const dispatch = useDispatch()
  const [expand, setExpand] = React.useState(false)
  const [settings, updateSettings] = useImmer(SETTINGS)

  React.useEffect(() => {
    const getBatchLocalesSetting = async () => {
      const response = await dispatch(actions.merchant.getBatchLocales())
      const options = _.map(response, option => ({ value: option.value, label: option.name }))
      updateSettings((draft) => {
        draft.batchLocaleOptions.items = options
      })
    }
    getBatchLocalesSetting()
  }, [])

  return (
    <CollapsibleList title='列印設定' expand={expand} setExpand={setExpand}>
      {
        _.map(settings, setting => (
          <SettingControl key={setting.label} setting={setting} />
        ))
      }
    </CollapsibleList>
  )
}
