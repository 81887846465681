import ControlledTable from '@/components/Table/ControlledTable'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { boxesIdReducer, itemsReducer } from '@/libs/dimbox'
import ReceiptButton from '@/components/ReceiptButton'

/**
 *
 * @param {{
 * data: []
 * }} props
 * @returns
 */
export default function DimobxTable (props) {
  const { data } = props

  const columns = [
    { accessor: row => <ReceiptButton orderSerial={row.serial} orderId={row.id} />, Header: 'Serial #' },
    {
      Header: 'Merchant',
      accessor: function accessorMerchant (row, i) {
        return (<div>{row.merchantName}<br />{row.merchantAddress}</div>)
      },
      id: 1,
    },
    { accessor: row => _.join(boxesIdReducer(row.boxes), '\n'), Header: 'Boxes' },
    { accessor: row => _.join(itemsReducer(row.batches), '\n '), Header: 'Items' },
    { accessor: 'takeawayStatus', Header: 'Takeaway Status' },
    {
      accessor: 'createdAt',
      Header: 'Created Date',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
  ]

  return (
    <ControlledTable
      columns={columns}
      data={data}
      pageSize={data.length}
      currentPage={0}
      rowCount={data.length}
      onPageChange={() => { }}
    />
  )
}
