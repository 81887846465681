import { Button, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import ControlledTable from '@/components/Table/ControlledTable'

import RemarksEditDialog from './RemarksEditDialog'

const getAdminName = (mail) => {
  const [,, name = ''] = mail?.match(/^(.+)\+(.+)@dimorder.com/) ?? []
  return name
}
const ReportTimeRange = (report) => {
  return (
    <div>
      {moment(report.startedAt).format('YYYY-MM-DD HH:mm')} ~ {moment(report.endedAt).format('YYYY-MM-DD HH:mm')}
    </div>
  )
}
const reportType = (type) => {
  if (!type) {
    return ''
  }
  const types = type.join()
  if (types === 'table') {
    return 'Dine-in'
  }
  if (types === 'takeaway,storeDelivery') {
    return 'Takeaway / Delivery'
  }
  return types
}

const handleSummaryDownload = (report) => {
  const summary = report.summary
  const getField = () => {
    if (reportType(report.deliveryType) === 'Dine-in') {
      return {
        adjustedNet: 'dineInAdjustedNet',
        adjustmentAmount: 'dineInAdjustmentAmount',
        refundAmount: 'dineInRefundAmount',
      }
    } else {
      return {
        adjustedNet: 'adjustedNet',
        adjustmentAmount: 'adjustmentAmount',
        refundAmount: 'refundAmount',
      }
    }
  }
  // create csv. 2d array
  const csvArr = []
  csvArr.push([
    'Merchant ID',
    'Company Name',
    'Restaurant Name',
    'Registered Address',
    'Transaction Amount',
    'Dine-In Service Fee',
    'Delivery Service Fee',
    'Takeaway Service Fee',
    'Shipping Discount',
    'Adjustment Amount',
    'Refund Amount',
    'Adjusted Net',
  ])
  summary.forEach(e => {
    csvArr.push([
      e.id, // Merchant ID
      e.companyName, // Company Name
      e.restaurantName, // Restaurant Name
      e.registeredAddress, // Registered Address
      e.totalTransactionAmount, // Transaction Amount
      e.dineInServiceFee, // Dine-In Service Fee
      e.deliveryServiceFee, // Delivery Service Fee
      e.takeawayServiceFee, // Takeaway Service Fee
      e.shippingDiscount, // Shipping Discount
      e[getField().adjustmentAmount], // Adjustment Amount
      e[getField().refundAmount], // Refund Amount
      e[getField().adjustedNet], // Adjusted Net
    ])
  })
  const csvString = csvArr
    .map(row => row.map(o => `"${o || ''}"`)) // wrap with "", falsy fallback to empty string
    .join('\n') // join with newline
  // download
  const file = new Blob(['\uFEFF' + csvString], { type: 'application/csv;charset=utf-8' })
  const fileName = moment(report.createdAt).format('YYYY-MM-DD-hh-mm-ss') + '-' + 'summary.csv'
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(file)
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(link.href)
  // document.body.removeChild(link)
}

const Summary = (report) => {
  if (report.status !== 'finished' || !report.summary) {
    return ''
  } else {
    return (
      <Button
        variant='contained'
        color='primary'
        onClick={() => handleSummaryDownload(report)}
      >Download
      </Button>
    )
  }
}

const DownloadButton = (value) => {
  if (value === '') {
    return 'pending'
  } else {
    return (
      <Button
        variant='contained'
        color='primary'
        href={value}
        target='_blank'
      >Download
      </Button>
    )
  }
}

const ColoredDot = (props) => {
  const classes = useStyles(props)

  const { indicator } = props.row.original

  const colors = {
    NOT_START: '#a6aaa0',
    RUNNING: '#42a5f5',
    DONE: '#78cb0b',
    ERROR: '#ff0000',
  }
  const tooltips = {
    NOT_START: '尚未開始',
    RUNNING: '報表產生中',
    DONE: '報表已完成',
    ERROR: '報表產生異常，請聯絡 RnD',
  }

  const tooltip = tooltips[indicator] ?? tooltips.NOT_START
  const backgroundColor = colors[indicator] ?? colors.NOT_START

  return (
    <div className={classes.dotContainerStyle}>
      <div style={{ backgroundColor }} className={classes.dotStyle} title={tooltip} />
      {props.children}
    </div>
  )
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function JobTable (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const jobs = useSelector(state => state.report.job.data)
  const meta = useSelector(state => state.report.job.meta)
  const params = useSelector(state => state.report.job.params)
  const [remarksEditDialogOpen, setRemarksEditDialogOpen] = React.useState(false)
  const [selectedJob, setSelectedJob] = React.useState({})
  const JobCell = ({ value, row }) => {
    const history = useHistory()

    if (row.original.status === 'pending') {
      return (
        <ColoredDot row={row}>
          {value.substring(0, 8)}
        </ColoredDot>
      )
    }

    return (
      <ColoredDot row={row}>
        <div onClick={() => { history.push('/report/' + value) }} className={classes.hover}>{value.substring(0, 8)}</div>
      </ColoredDot>
    )
  }
  const columns = [
    {
      accessor: 'id',
      Header: 'Job',
      Cell: JobCell,
    },
    {
      accessor: row => ReportTimeRange(row),
      Header: 'Report Date',
    },
    {
      accessor: 'deliveryType',
      Header: 'Type',
      Cell: ({ value }) => reportType(value),
    },
    {
      accessor: row => row.merchantIds.length,
      Header: 'Merchant',
    },
    {
      accessor: 'createdAt',
      Header: 'Created Date',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      accessor: 'identifier',
      Header: 'Admin',
      Cell: ({ value }) => getAdminName(value),
    },
    {
      accessor: 'downloadLink',
      Header: 'Report',
      Cell: ({ value }) => DownloadButton(value),
    },
    {
      accessor: row => Summary(row),
      Header: 'Summary',
    },
    {
      Header: 'Remark',
      accessor: function RemarksAccessor (row, i) {
        return (
          <div className={classes.accessorColumn} style={{ maxWidth: 300 }}>
            <Typography variant='body2'>
              {row.remark}
            </Typography>
            <IconButton
              size='small'
              style={{ alignSelf: 'flex-start' }}
              onClick={() => {
                setRemarksEditDialogOpen(true)
                setSelectedJob(row)
              }}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </div>
        )
      },
    },
  ]
  React.useEffect(() => {
    dispatch(actions.report.getReportJobs())
  }, [])

  const onPageChange = async (event, page) => {
    await dispatch(actions.report.updateJobQuery({ ...params, skip: page * meta.perPage }))
  }

  const handleRowsPerPageChange = (event) => {
    dispatch(actions.report.updateMeta({
      perPage: event.target.value,
      currentPage: 1,
    }))
    dispatch(actions.report.getReportJobs())
  }

  return (
    <div>
      <ControlledTable
        columns={columns}
        data={jobs}
        pageSize={meta.perPage}
        currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
        rowCount={meta.total}
        onPageChange={onPageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50]}
      />
      <RemarksEditDialog
        open={remarksEditDialogOpen}
        handleClose={() => {
          setRemarksEditDialogOpen(false)
          setSelectedJob({})
        }}
        selectedJob={selectedJob}
      />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  hover: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1675e0',
    },
  },
  accessorColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  dotStyle: {
    borderRadius: '50%',
    width: '0.7rem',
    height: '0.7rem',
    marginRight: '0.7rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotContainerStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))
