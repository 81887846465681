import ActionTypes from './ActionTypes'
import AppActionTypes from '../app/ActionTypes'
import nodeApi from '@/libs/api/node'
import * as appActions from '@/redux/app/actions'
/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getUsers () {
  const apiName = 'getUsers'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      const { data } = await nodeApi.getAllUsers()
      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
    } catch (error) {
      console.log('getUsers error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 * @param {{
 * username: string
 * password: string
 * role: string
 * }}
 * @returns {ThunkFunction}
 */
export function createUser ({ username, password, role }) {
  const apiName = 'createUser'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      await nodeApi.createUser(username, password, role)
    } catch (error) {
      console.log('createUser error', error)
      throw error
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 * @param {{ id: string, password?: string, role?: string }} params
 * @returns {ThunkFunction}
 */
export function updateUser ({ id, ...params }) {
  const apiName = 'updateUser'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      await nodeApi.updateUser(id, params)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Success', content: 'Update successfully.' }))
    } catch (error) {
      console.log('updateUser error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 *
 * @param {IUser} user
 * @returns {ThunkFunction}
 */
export function deleteUser (user) {
  const apiName = 'deleteUser'
  const { uid } = user
  return async (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.CREATE_API_REQUEST,
      payload: { apiName },
    })
    try {
      await nodeApi.deleteUser(uid)
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Success', content: 'User successfully deleted.' }))
    } catch (error) {
      dispatch(appActions.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
      console.log('deleteUser error', error)
    } finally {
      dispatch({
        type: AppActionTypes.FINISH_API_REQUEST,
        payload: { apiName },
      })
    }
  }
}

/**
 * @function
 * @param {IUserQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}
