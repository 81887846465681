import React from 'react'

import CollapsibleList from '@/components/CollapsibleList'
import SettingControl from '../SettingControl'

const SETTINGS = [
  { label: '啟用後台報表即時概況 (DimPOS only)', paramaterName: 'enablePortalDashboard', type: 'switch' },
  { label: '啟用後台報表數據和報表 (DimPOS only)', paramaterName: 'enablePortalDatanReport', type: 'switch' },
  { label: '啟用後台報表報表下載 (merchant 1.0 only)', paramaterName: 'enablePortalReportDownload', type: 'switch' },
  { label: '銷售報表顯示選項 (merchant 1.0 only)', paramaterName: 'salesReportShowOptions', type: 'switch' },
  { label: '啟用點單報表下載', paramaterName: 'enablePortalStatementPage', type: 'switch' },
]

/**
 *
 * @param {*} props
 * @returns
 */
export default function ReportSettings (props) {
  const [expand, setExpand] = React.useState(false)

  return (
    <CollapsibleList title='報表設定' expand={expand} setExpand={setExpand}>
      {
        SETTINGS.map(setting => (
          <SettingControl key={setting.label} setting={setting} />
        ))
      }
    </CollapsibleList>
  )
}
