import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

TableSearchForm.prototypes = {
  handleSearch: PropTypes.func,
  handleClear: PropTypes.func,
  children: PropTypes.node,
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function TableSearchForm (props) {
  const classes = useStyles(props)
  const { handleSearch, handleClear, children } = props

  return (
    <form className={classes.root} autoComplete='off'>
      <div className={classes.fileds}>
        {children}
      </div>
      <div className={classes.actions}>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={handleSearch}
        >
          Search
        </Button>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={handleClear}
        >
          Clear
        </Button>
      </div>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  textField: {
    background: theme.palette.common.white,
  },
  fileds: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(1),
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
}))
