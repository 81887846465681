import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

/**
 *
 * @param {*} props
 * @returns
 */
export default function ReportDetail (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { jobId = 0 } = useParams()
  const jobSerial = jobId.substring(0, 8)
  const [selectAll, setSelectAll] = React.useState(false)
  const [validMerchants, setValidMerchants] = React.useState([])
  const handleSelectAll = () => {
    validMerchants.forEach(merchant => {
      merchant.visible = !selectAll
    })
    setSelectAll(!selectAll)
    setValidMerchants([...validMerchants])
  }
  const MerchantCheckbox = (props) => {
    const merchant = props.merchant
    return (
      <div className={classes.checkboxLine}>
        <FormControlLabel
          control={
            <Checkbox
              name='checkedB'
              color='primary'
              value={merchant.id}
              checked={merchant.visible}
              onClick={(event) => handleClick(event, merchant)}
            />
        }
          label={merchant.name}
        />
      </div>
    )
  }
  const handleClick = (event, merchant) => {
    merchant.visible = event.target.checked
    setValidMerchants([...validMerchants])
    if (validMerchants.some(merchant => !merchant.visible)) {
      setSelectAll(false)
    }
    if (validMerchants.every(merchant => merchant.visible)) {
      setSelectAll(true)
    }
  }
  const handleCreateReport = async () => {
    const params = {
      jobId: jobId,
      merchants: validMerchants.map(merchant => {
        return {
          id: merchant.id,
          visiblePortal: merchant.visible,
        }
      }),
    }
    await dispatch(actions.report.setReportVisiblePortal(params))
    dispatch(actions.app.toggleDialog('alertDialog', true, { title: 'Message', content: 'Updated!' }))
  }
  React.useEffect(() => {
    const getReportVisiblePortal = async () => {
      const data = await dispatch(actions.report.getReportVisiblePortal(jobId))
      setValidMerchants(data)
    }
    getReportVisiblePortal()
  }, [jobId])
  return (
    <Page>
      {/* content */}
      <PageHeader text={'Job #' + jobSerial} />
      <div className={classes.title}>Show in Merchant Portal</div>
      <div className={classes.selectAll}>
        <FormControlLabel
          control={
            <Checkbox
              name='ALL'
              color='primary'
              checked={selectAll}
              onClick={() => handleSelectAll()}
            />
          }
          label={<Typography className={classes.formControlLabel}>ALL</Typography>}
        />
      </div>
      <div className={classes.data}>
        <FormGroup className={classes.grid}>
          {_.map(validMerchants, merchant =>
            <MerchantCheckbox key={merchant.id} merchant={merchant} />,
          )}
        </FormGroup>
        <div className={classes.createReportBtn}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleCreateReport}
          >
            Show in Merchant Portal
          </Button>
        </div>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    background: '#fafafa',
    padding: '12px',
  },
  data: {
    background: '#FFFFFF',
    padding: '12px',
  },
  checkboxLine: {
    display: 'flex',
    alignItems: 'center',
  },
  createReportBtn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  selectAll: {
    background: '#FFFFFF',
    paddingLeft: '12px',
    paddingTop: '12px',
  },
  formControlLabel: {
    fontWeight: 'bold',
  },
}))
