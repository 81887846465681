import { useImmer } from 'use-immer'
import React from 'react'
import _ from 'lodash'
const Context = React.createContext()

export function Provider (props) {
  const { children } = props
  const [state, setState] = useImmer({
    openEditDialog: false,
    openLinkDialog: false,
    openCreateDialog: false,
    openCopyMenuDialog: false,
    openCopyToBetaDialog: false,
    merchant: {},
  })
  const updateInputState = (path, value) => {
    setState((draft) => {
      _.set(draft, path, value)
    })
  }
  return <Context.Provider value={[state, updateInputState]}>{children}</Context.Provider>
}

export function useMerchant () {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useMerchant must be used within a Provider')
  }
  return context
}
