import { exportXlsx } from '@/libs/xlsx'
import _ from 'lodash'
import goAdminApi from '@/libs/api/goAdmin'
import moment from 'moment'

import ActionTypes from './ActionTypes'

const EXPORT_HEADERS = [
  'Merchant',
  'Serial #',
  'Created Date',
  'Paid At',
  'Driver Pickup At',
  'Completed At',
  'Pickup At',
  'Status',
  'Cancel Reason',
  'Takeaway Status',
  'Food Total',
  'Shipping Fee',
  'Order Total',
  'Payment Method',
  'Payment Gateway',
  'Table',
  'Address',
  'Customer Phone',
  'Customer Name',
  'Customer Address',
  'ID',
  'Payment Ref',
  'Promotion Code',
]

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * 沒傳 queryParams 的話預設使用 redux 的 order.params
 * @function
 * @param {IOrderQueryParams?} queryParams
 * @returns {ThunkFunction}
 */
export function getOrders (queryParams) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getOrders' },
    })
    try {
      const params = _.isEmpty(queryParams)
        ? { ...getState().order.params, limit: getState().order.meta.perPage }
        : { ...queryParams }
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })
      const response = await goAdminApi.getOrders(params)
      const { data, total } = response

      // 如果出現 paid, pending order 超過5分鐘. 播放音效
      const pendingAlertSoundToggle = getState().order.pendingAlertSoundToggle
      if (pendingAlertSoundToggle) {
        const hasPendingOrder = data.filter(o => {
          const isStoreDelivery = o.deliveryType === 'storeDelivery'
          const isTakeaway = o.deliveryType === 'takeaway' && ['CUSTOMER_WEB', 'CUSTOMER_APP', 'SERVER'].includes(o.from)
          return (isStoreDelivery || isTakeaway) && o.status === 'paid' && o.takeawayStatus === 'pending'
        })
        const has10minPendingOrder = hasPendingOrder.filter(o => {
          const isOver10Minute = moment().diff(moment(o.paidAt), 'minute') >= 10
          return isOver10Minute
        })
        if (hasPendingOrder.length) {
          try {
            const audioEl = document.getElementById('pendingOrderAudio')
            audioEl.play()
          } catch (error) { }
        }
        if (has10minPendingOrder.length) {
          try {
            const audioEl = document.getElementById('pendingOrder10minAudio')
            audioEl.play()
          } catch (error) { }
        }
      }
      //

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { total },
      })
    } catch (error) {
      console.log('getOrders error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getOrders' },
      })
    }
  }
}

/**
 * @function
 * @param {IOrderQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function resetOrders () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DATA,
      payload: {},
    })
    dispatch(init())
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function exportOrders () {
  return async (dispatch, getState) => {
    try {
      // const orders = getState().order.data ?? []
      const params = {
        ...getState().order.params,
        limit: undefined,
        skip: undefined,
      }
      // FIXME: lodash.pickby?
      Object.keys(params).forEach(key => {
        if (params[key] === '') {
          delete params[key]
        }
      })
      const response = await goAdminApi.getOrders(params)
      const { data: orders } = response

      const rows = _.map(orders, order => {
        /** @type {IOrder} */
        const payment = _.sortBy(order?.payments, [
          p => p.status === 'paid' ? -1 : 1, // paid first
          p => -1 * p.paidAmount, // paidAmount larger first
          p => -1 * +moment(p.createdAt), // created at latest first
        ])[0]
        const paidAt = order.paidAt ? moment(order.paidAt).format('YYYY-MM-DD HH:mm:ss') : ''
        const pickupAt = order.pickupAt ? moment(order.pickupAt).format('YYYY-MM-DD HH:mm:ss') : ''
        const completedAt = order.completedAt ? moment(order.completedAt).format('YYYY-MM-DD HH:mm:ss') : ''
        const scheduleAt = order?.shipping?.lalamove?.scheduleAt ? moment(order.shipping.lalamove.scheduleAt).format('YYYY-MM-DD HH:mm:ss') : ''
        const promocodeModifier = order?.modifiers?.find(o => o.type === 'PROMOCODE')
        const orderColumnObj = {
          Merchant: order.merchantName,
          'Serial #': order.serial,
          'Created Date': moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          'Paid At': paidAt,
          'Driver Pickup At': scheduleAt,
          'Completed At': completedAt,
          'Pickup At': pickupAt,
          Status: order.status,
          'Cancel Reason': order.cancelReason,
          'Takeaway Status': order.takeawayStatus,
          'Food Total': order.roundedRevenue,
          'Shipping Fee': order.shippingFee,
          'Order Total': order.roundedTotal,
          'Payment Method': payment?.paymentMethod,
          'Payment Gateway': payment?.gateway,
          Table: order.table,
          Address: order.merchantAddress,
          'Customer Phone': order.phone,
          'Customer Name': order.name,
          'Customer Address': order.address,
          ID: order.id,
          'Payment Ref': payment?.ref,
          'Promotion Code': promocodeModifier?.code,
        }

        const columns = EXPORT_HEADERS.map(o => orderColumnObj[o])
        const row = _
          .chain(columns)
          .map(col => {
            if (col == null) return ''
            const value = String(col)
            return value.replaceAll('\n', '').replaceAll(';', ',') // avoid csv display error
          })
          .value()
        return row
      })
      exportXlsx(`order_export_${moment().format('YYYYMMDDHHmmss')}.xlsx`, EXPORT_HEADERS, rows)
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.error') || error
      if (errorMessage) {
        window.alert(errorMessage)
      }
      console.error('exportOrderOrders error', error)
    }
  }
}

/**
 * @function
 * @param {*} params
 * @returns {ThunkFunction}
 */
export function updateMeta (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_META,
        payload: params,
      })
    } catch (error) {
      console.log('updateMeta error', error)
    }
  }
}

/**
 * @function
 * @param {*} params
 * @returns {ThunkFunction}
 */
export function updatePendingAlertSoundToggle (v) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_PENDING_ALERT_SOUND_TOGGLE,
        payload: { data: v },
      })
    } catch (error) {
      console.log('updateMeta error', error)
    }
  }
}
