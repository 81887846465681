import { Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import ListHeader from './ListHeader'

/**
 *
 * @param {{
 * expand: boolean
 * setExpand: () => void
 * children: any
 * className: any
 * }} props
 * @returns
 */
export default function CollapsibleList (props) {
  const { title, expand, setExpand, children } = props
  const classes = useStyles(props)

  const handleExpand = () => {
    setExpand(!expand)
  }

  return (
    <div className={props.className}>
      <ListHeader title={title} expand={expand} onClick={handleExpand} />
      <Collapse in={expand}>
        <div className={classes.settings}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  settings: {
    display: 'flex',
    flexDirection: 'column',
    // gap: 8,
    paddingLeft: 24,
    paddingRight: 24,
    '&>:nth-child(even)': {
      background: '#fbe5d2',
    },
    '&>div': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}))
