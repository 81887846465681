import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { usePromoCode } from '@/hooks/PromotionContext'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CreatePromoCodeButton (props) {
  const classes = useStyles(props)
  const [, setPromoCode] = usePromoCode()
  const handleClick = () => setPromoCode({})

  return (
    <Button
      disableElevation
      variant='contained'
      color='secondary'
      className={classes.button}
      onClick={handleClick}
    >Create
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    // backgroundColor: '#777777',
    // color: '#fafafa',
    '&:hover': {
      // backgroundColor: '#666666',
    },
  },
}))
