import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'

import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'
import CollapsibleList from '@/components/CollapsibleList'
import MerchantSearch from '@/components/MerchantSearch'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CouponSettings (props) {
  const [expand, setExpand] = React.useState(false)
  const [merchant, setMerchant] = React.useState()
  const [inputState, updateInputState] = useInputState()
  const [errors, setErrors] = useErrors()

  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
  }

  const handleNameChange = (e) => {
    const { name, value } = e.target
    updateInputState(['name', name], value)
    setErrors({ ...errors, 'name.zh': undefined, 'name.en': undefined })
  }

  const handleTncChange = (e) => {
    const { name, value } = e.target
    updateInputState(['tnc', name], value)
  }

  const handleActionTypeChange = (e) => {
    const { value } = e.target
    updateInputState(['action', 'type'], value)
    updateInputState(['action', 'payload'], '')
    setErrors({ ...errors, 'action.payload': undefined })
  }

  const handleActionPayloadChange = (e) => {
    const { value } = e.target
    updateInputState(['action', 'payload'], value)
    setErrors({ ...errors, 'action.payload': undefined })
  }

  const ActionPayload = React.useMemo(() => {
    if (inputState.action?.type === 'NONE') {
      return null
    }

    if (inputState.action?.type === 'MERCHANT') {
      return (
        <MerchantSearch
          onChange={(o) => {
            setMerchant(o)
            updateInputState(['action', 'payload'], o?.id)
            updateInputState(['action', 'merchantName'], o?.name)
            updateInputState(['action', 'inputMerchantName'], o?.name)
          }}
          value={merchant}
        />
      )
    }

    return (
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Action Payload'
          name='payload'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.action?.payload ?? ''}
          onChange={handleActionPayloadChange}
          error={Boolean(errors['action.payload'])}
        />
        <FormHelperText>Coupon 立即使用的動作</FormHelperText>
      </FormControl>
    )
  }, [inputState.action, errors])

  if (inputState.type !== 'COUPON') {
    // 非 Coupon 不顯示
    return null
  }

  return (
    <CollapsibleList title='Coupon 資訊' expand={expand} setExpand={setExpand}>
      {/* Name (ZH) */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Name (ZH)'
          name='zh'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.name?.zh ?? ''}
          onChange={handleNameChange}
          error={Boolean(errors['name.zh'])}
        />
        <FormHelperText>Coupon 活動名稱:中文</FormHelperText>
      </FormControl>

      {/* Name (EN) */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Name (EN)'
          name='en'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.name?.en ?? ''}
          onChange={handleNameChange}
          error={Boolean(errors['name.en'])}
        />
        <FormHelperText>Coupon 活動名稱:英文</FormHelperText>
      </FormControl>

      {/* TNC (ZH) */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='TNC (ZH)'
          name='zh'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          multiline
          value={inputState.tnc?.zh ?? ''}
          onChange={handleTncChange}
        />
        <FormHelperText>Coupon 活動說明、使用說明或規則說明:中文</FormHelperText>
      </FormControl>

      {/* TNC (EN) */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='TNC (EN)'
          name='en'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          multiline
          value={inputState.tnc?.en ?? ''}
          onChange={handleTncChange}
        />
        <FormHelperText>Coupon 活動說明、使用說明或規則說明:英文</FormHelperText>
      </FormControl>

      {/* TNC URL */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='TNC (url)'
          name='tncUrl'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.tncUrl ?? ''}
          onChange={handleChange}
        />
        <FormHelperText>Coupon 顯示在說明中，可以連到相關網頁</FormHelperText>
      </FormControl>

      {/* Action Type */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <InputLabel margin='dense'>Action Type</InputLabel>
        <Select
          label='Action Type'
          name='type'
          variant='outlined'
          fullWidth
          value={inputState.action?.type ?? ''}
          onChange={handleActionTypeChange}
        >
          <MenuItem value='NONE'>NONE</MenuItem>
          <MenuItem value='MERCHANT'>MERCHANT</MenuItem>
          <MenuItem value='CATEGORY'>CATEGORY</MenuItem>
          <MenuItem value='CUISINE'>CUISINE</MenuItem>
          <MenuItem value='SEARCH'>SEARCH</MenuItem>
          <MenuItem value='LINK'>LINK</MenuItem>
        </Select>
        <FormHelperText>Coupon 立即使用的動作</FormHelperText>
      </FormControl>

      {/* Action Payload */}
      {ActionPayload}
    </CollapsibleList>
  )
}
