import { Button, Slide, Snackbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { usePromoCode } from '@/hooks/PromotionContext'
import ControlledTable from '@/components/Table/ControlledTable'

function SlideTransition (props) {
  return <Slide {...props} direction='up' />
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function PromotionTable (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const promoCodes = useSelector(state => state.promoCode.data)
  const meta = useSelector(state => state.promoCode.meta)
  const params = useSelector(state => state.promoCode.params)
  const [, setPromoCode] = usePromoCode()
  const [snackBarText, setSnackBarText] = React.useState(null)

  /**
   *
   * @param {{
   * name?: string
   * value?: string
   * onClick?: () => void
   * }} props
   * @returns
   */
  function CopyButton (props) {
    const { name, value, onClick } = props
    const title = name ? `Copy ${name}` : 'Copy'
    const handleCopy = async () => {
      await navigator.clipboard.writeText(value)
      setSnackBarText(`${name} copied`)
    }
    return (
      <Button
        disableElevation
        variant='contained'
        size='small'
        color='primary'
        onClick={onClick || handleCopy}
      >
        {title}
      </Button>
    )
  }

  /**
   *
   * @param {{
   * promoCode: IPromoCode
   * }} props
   * @returns
   */
  function DeletePromoCodeButton (props) {
    const { promoCode } = props
    return (
      <Button
        disableElevation
        variant='contained'
        size='small'
        color='secondary'
        onClick={async () => {
          dispatch(actions.app.toggleDialog('confirmDialog', null, {
            content: `Are you sure you want to delete this ${promoCode.type === 'COUPON' ? 'Coupon' : 'PromoCode'}?`,
            confirmCallback: async () => {
              await dispatch(actions.promoCode.deletePromoCode(promoCode))
              dispatch(actions.promoCode.getPromoCodes())
            },
          }))
        }}
      >
        Delete
      </Button>
    )
  }

  const columns = [
    {
      Header: 'Type',
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { type } = promoCode
        return type || 'PROMOCODE'
      },
    },
    {
      Header: 'Name / Code',
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { name, code } = promoCode
        const displayName = name?.zh || name?.en
        const handleClick = () => setPromoCode(promoCode)
        return (
          <div className={classes.nameCol}>
            {displayName && <Typography onClick={handleClick} color='textPrimary'>{displayName}</Typography>}
            <Typography onClick={handleClick} color={code ? 'primary' : 'secondary'}>{code || 'EMPTY'}</Typography>
          </div>
        )
      },
    },
    {
      Header: 'Merchant',
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { merchantId, merchantName } = promoCode
        return merchantName || merchantId
      },
    },
    {
      Header: 'Valid Date',
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { validUntil, validFrom } = promoCode
        const now = moment()
        const isValid = now.isBetween(moment(validFrom), moment(validUntil))
        const isInvalid = validFrom !== null && now.isAfter(moment(validFrom))
        const dateStr = `${moment(validFrom).format('YYYY/MM/DD')}-${moment(validUntil).format('YYYY/MM/DD')}`
        const color = isInvalid ? 'primary' : 'secondary'
        const prefix = isInvalid ? 'Invalid' : 'Expired'

        return isValid
          ? dateStr
          : <Typography color={color} variant='body2'>({prefix}) {dateStr}</Typography>
      },
    },
    {
      Header: 'Offered By',
      accessor: 'offeredBy',
    },
    {
      Header: 'Amount / Percent',
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { amount, percent } = promoCode
        if (amount) return `$${amount}`
        if (percent) return `${percent}%`
      },
    },
    {
      Header: 'Used / Send / Quota', // 已使用數 / 已發送數 / 總數量
      // promocode 的情況，領取與使用同時發生，因此 usedTime = redeemedTime
      // coupon 的情況，可先領取再使用，因此 redeemedTime <= usedTime
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { redeemedTime, usedTime, quota } = promoCode
        return `${redeemedTime} / ${usedTime} / ${quota || '∞'}`
      },
    },
    {
      Header: 'Actions',
      accessor: row => {
        /** @type {IPromoCode} */
        const promoCode = row
        const { id, code } = promoCode
        return (
          <div className={classes.actionsCol}>
            <CopyButton name='id' value={id} />
            <CopyButton name='code' value={code} />
            <CopyButton
              onClick={() => {
                setPromoCode({
                  ...promoCode,
                  // 複製 promotion 但重置一些值
                  // ? 具體有哪些？
                  id: undefined,
                  code: undefined,
                  createdAt: undefined,
                  creator: undefined,
                })
              }}
            />
            <DeletePromoCodeButton promoCode={promoCode} />
          </div>
        )
      },
    },
  ]

  const onPageChange = async (e, page) => {
    dispatch(actions.promoCode.updateQuery({ ...params, skip: page * meta.perPage }))
    dispatch(actions.promoCode.getPromoCodes())
  }

  return (
    <>
      <ControlledTable
        columns={columns}
        data={promoCodes}
        pageSize={meta.perPage}
        currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
        rowCount={meta.total}
        onPageChange={onPageChange}
      />
      <Snackbar
        open={Boolean(snackBarText)}
        onClose={() => setSnackBarText(null)}
        autoHideDuration={2000}
        TransitionComponent={SlideTransition}
        message={snackBarText}
        key={snackBarText}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  nameCol: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  actionsCol: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))
