import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Legend,
  PieSeries,
  Title,
  Tooltip,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import { ArgumentScale, EventTracker, Palette, Stack } from '@devexpress/dx-react-chart'
import { Box, Button, ButtonGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { scaleBand } from '@devexpress/dx-chart-core'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

const DimOrderPalette = ['#DEDE25', '#BD4BCC', '#3C8D83', '#86BE44', '#52AAD9', '#DE8925', '#B7B7A7', '#765517', '#4A59AA', '#D14475']

/**
 *
 * @param {*} props
 * @returns
 */
export default function Dashboard (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const statistics = useSelector(state => state.dashboard.data)
  const registeredUsers = useSelector(state => state.dashboard.registeredUsers)
  const activatedUsers = useSelector(state => state.dashboard.activatedUsers)
  const [range, setRange] = React.useState(7)
  const [days, setDays] = React.useState({})

  const users = React.useMemo(() => {
    const users = []

    for (let i = 0; i < range + 1; i++) {
      users.push({
        date: moment().subtract(range - i, 'days').format('YYYY-MM-DD'),
        shortDate: moment().subtract(range - i, 'days').format('MM-DD'),
        regCount: 0,
        actCount: 0,
      })
    }

    registeredUsers.forEach(registeredUser => {
      const user = users.find(user => user.date === registeredUser.date)
      if (user) {
        user.regCount = registeredUser.count
      }
    })

    activatedUsers.forEach(activatedUser => {
      const user = users.find(user => user.date === activatedUser.date)
      if (user) {
        user.actCount = activatedUser.count
      }
    })

    users.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())

    return users
  }, [range, registeredUsers, activatedUsers])

  function intToString (value) {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T'
    }
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
    }
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return (value).toFixed(1).replace(/\.0$/, '')
  }

  function DimBox (props) {
    const classes = useStyles(props)
    return (
      <Box className={classes.dimbox}>
        <Box pt={2} textAlign='center' fontSize={16}>{props.name}</Box>
        <Box py={1} textAlign='center' fontSize={30}>{intToString(parseFloat(props.value))}</Box>
      </Box>
    )
  }

  React.useEffect(() => {
    const param = {
      startedAt: moment().subtract(range, 'days').format('YYYY-MM-DD'),
      endedAt: moment().format('YYYY-MM-DD'),
    }
    dispatch(actions.dashboard.getStatistics(param))
    dispatch(actions.dashboard.getRegisteredUsers(param))
    dispatch(actions.dashboard.getActivatedUsers(param))
    setDays({
      startedAt: moment().subtract(range, 'days').format('YYYY-MM-DD'),
      endedAt: moment().format('YYYY-MM-DD'),
    })
  }, [range])

  return (
    <Page>
      {/* content */}
      <PageHeader text='Dashboard' />
      <Box display='flex' justifyContent='space-around'>
        <DimBox name='APP使用人數' value={statistics.users} />
        <DimBox name='本月活躍用戶數' value={statistics.mau} />
        <DimBox name='RC累積發送數' value={statistics.ricecoin} />
        <DimBox name='用戶RC總消耗數' value={statistics.withholding} />
        <DimBox name='活躍用戶平均交易數' value={statistics.transactions} />
      </Box>
      <Paper>
        <div className={classes.btns}>
          <ButtonGroup color='primary' aria-label='outlined primary button group'>
            <Button variant={range === 7 && 'contained'} onClick={() => setRange(7)}>7 days</Button>
            <Button variant={range === 30 && 'contained'} onClick={() => setRange(30)}>a month</Button>
          </ButtonGroup>
        </div>
        <Chart data={users}>
          <Title text={`每日用戶統計(${days.startedAt}~${days.endedAt})`} />
          <ArgumentAxis />
          <ArgumentScale factory={scaleBand} />
          <ValueAxis />
          <BarSeries
            valueField='regCount'
            argumentField='shortDate'
            name='每日註冊用戶'
          />
          <BarSeries
            valueField='actCount'
            argumentField='shortDate'
            name='每日活躍用戶'
          />
          <Stack />
          <EventTracker />
          <Tooltip />
          <Legend />
        </Chart>
      </Paper>

      <Paper className={classes.dimpaper}>
        <Chart
          data={statistics.rate}
          width={600}
        >
          <Title text={`交易次數比例(${days.startedAt}~${days.endedAt})`} />
          <Palette scheme={DimOrderPalette} />
          <PieSeries
            valueField='value'
            argumentField='key'
            innerRadius={0.3}
            outerRadius={1}
          />
          <Legend />
        </Chart>
      </Paper>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  dimbox: {
    flexGrow: 1,
    margin: '6px',
    background: '#FFFFFF',
    color: '#533633',
    fontWeight: 'bold',
  },
  dimpaper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
  },
  btns: {
    padding: theme.spacing(1),
  },
}))
