import { Button, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'
import config from '@/config'

/**
 *
 * @param {{
 * open: boolean
 * handleClose: () => void
 * selectedOrder?: IDeliveryOrder
 * }} props
 * @returns
 */
export default function CopyDeliveryOrderDialog (props) {
  const { open, handleClose, selectedOrder } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  return (
    <Dialog
      title='Are You Sure?'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                dispatch(actions.deliveryOrder.copyDeliveryOrder(selectedOrder?.id))
                handleClose()
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleClose()
              }}
            >
              No
            </Button>
          </>
        )
      }}
    >
      <div className={classes.dialogContent}>
        <Typography>
          This action will create a copy of&nbsp;
          <Link
            color='primary'
            onClick={() => window.open(config.receipt + selectedOrder?.orderId + '?doadmin=1')}
          >
            #{selectedOrder?.orderSerial}
          </Link>
          .
        </Typography>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
  },
}))
