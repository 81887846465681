import React from 'react'

const Context = React.createContext()

export function Provider (props) {
  const { value, children } = props
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useErrors () {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useErrors must be used within a Provider')
  }
  return context
}
