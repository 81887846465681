/* eslint-disable no-unused-vars */
import _ from 'lodash'
import axios, { AxiosInstance } from 'axios'

import { getToken } from '@/libs/firebase'
import config from '@/config'
import qs from 'qs'

class GoAdminApi {
  /** @type {AxiosInstance} */
  axios

  constructor (url) {
    this.axios = axios.create({
      baseURL: url || config.goAdminApi,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    })

    this.axios.interceptors.request.use(
      async config => {
        config.headers.Authorization = `TOKEN ${await getToken()}`
        return config
      },
      error => error,
    )
  }

  /**
   * @param {IOrderQueryParams} params
   * @returns {Promise<GoPaginationResponseData<IOrder>>}
   */
  getOrders = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/order', {
      params,
      headers: { 'x-api-version': '2.0.0' },
    })
    return response.data
  }

  /**
   * @param {ICreateJobParams} params
   * @returns {}
   */
  createJob = async (params) => {
    /** @type {} */
    const response = await this.axios.post('a/report/job',
      params,
    )
    return response.data
  }

  /**
   * @param {IReportJobQueryParams} params
   * @returns {Promise<GoPaginationResponseData<IReportJob>>}
   */
  getReportJobs = async (params) => {
    /** @type {} */
    const response = await this.axios.get('a/report/job', {
      params,
    })
    return response.data
  }

  /**
   * @param {IReportMerchantQueryParams} params
   * @returns {Promise<GoPaginationResponseData<Merchant>>}
   */
  getReportMerchants = async (params, signal) => {
    /** @type {} */
    const response = await this.axios.get('a/report/merchant', {
      params,
      signal,
    })
    return response.data
  }

  /**
   * @param {string} jobId
   * @returns {{id: string, name: string visible: boolean}[]}
   */
  getReportVisiblePortal = async (jobId) => {
    /** @type {} */
    const response = await this.axios.get('a/report/visible/' + jobId)
    return response.data
  }

  /**
   * @param {jobId: string, merchants: {id: string, visible: boolean}[]} params
   * @returns {}
   */
  setReportVisiblePortal = async (params) => {
    /** @type {} */
    const response = await this.axios.patch('a/report/visible/' + params.jobId,
      params.merchants,
    )
    return response.data
  }

  /**
   * @function
   * @param {string} id ReportJob.id
   * @param {object} data
   * @returns
   */
  async updateReportJob (id, data) {
    const response = await this.axios.patch(`a/report/remark/${id}`, data)
    return response.data
  }

  /**
   *
   * @param {IPromoCodeQueryParams} params
   * @returns {{ data: IPromoCode[], total: number }}
   */
  getPromoCodes = async (params) => {
    const response = await this.axios.get('a/promocode', { params })
    return response.data
  }

  getMerchant = async (id) => {
    const response = await this.axios.get('a/merchant/' + id)
    return response.data
  }

  queryMerchant = async (params) => {
    const response = await this.axios.get('a/merchant', { params })
    return response.data
  }

  /**
   * @param {{username: string, password: string}} params
   * @return {string}
   */
  regMerchant = async (params) => {
    const result = await this.axios.post('a/user/reg/merchant', params)
    return result.data
  }

  updateMerchantShortCode = async (merchantId, shortCode) => {
    return await this.axios.post(`a/merchant/${merchantId}/shortcode`, { shortCode })
  }

  updateMerchantPassword = async (merchantId, username, password) => {
    return await this.axios.post(`a/user/cred/${merchantId}`, { username, password })
  }

  /**
   * @param {{id: string, shortCode: string, name: string, address: string, contact: string }} params
   * @return {string}
   */
  createMerchantInfo = async (params) => {
    const result = await this.axios.post('a/merchant', params)
    return result
  }

  /**
   *
   * @param {string} id merchant.id
   * @param {{disable: boolean}} params
   * @returns
   */
  disableMerchant = async (id, params) => {
    const response = await this.axios.post(`a/merchant/${id}/disable`, params)
    return response.data
  }

  /**
   *
   * @param {string} id merchant.id
   * @returns
   */
  deleteMerchant = async (id) => {
    const response = await this.axios.delete(`a/merchant/${id}`)
    return response.data
  }

  /**
   *
   * @param {string} id merchant.id
   * @param {object} params
   * @returns
   */
  updateMerchantSetting = async (id, params) => {
    const response = await this.axios.post(`a/merchant/${id}/setting`, params)
    return response.data
  }

  /**
   *
   * @param {string} id merchant.id
   * @returns
   */
  copyToBeta = async (id) => {
    const response = await this.axios.post(`a/merchant/${id}/exportmerchant`)
    return response.data
  }

  /**
   * @returns {Promise<ILocals[]>}
   */
  getBatchLocales = async () => {
    const response = await this.axios.get('a/batchlocaleoption')
    return response.data
  }

  /**
   *
   * @param {IPromoCode} promoCode
   * @returns
   */
  createPromoCode = async (promoCode) => {
    const response = await this.axios.post('a/promocode', promoCode)
    return response.data
  }

  /**
   *
   * @param {IPromoCode} promoCode
   * @returns
   */
  updatePromoCode = async (promoCode) => {
    const response = await this.axios.post(`a/promocode/${promoCode.id}`, promoCode)
    return response.data
  }

  /**
   *
   * @param {string} id
   * @returns
   */
  deletePromoCode = async (id) => {
    const response = await this.axios.delete(`a/promocode/${id}`)
    return response.data
  }

  /**
   *
   * @param {string} id
   * @param {File} fileToUpload
   * @returns
   */
  uploadPromoCodeImage = async (id, fileToUpload) => {
    const formData = new FormData()
    formData.append('file', fileToUpload, fileToUpload.name)
    const response = await this.axios.post(`a/promocode/${id}/image`, formData)
    return response.data
  }

  /**
   *
   * @param {string} id
   * @returns
   */
  deletePromoCodeImage = async (id) => {
    const response = await this.axios.delete(`a/promocode/${id}/image`)
    return response.data
  }

  /**
   * 把拆單的新單號送給 orderapp 紀錄起來
   * 之後送 webhook 狀態更新時 orderapp 才會知道是哪張 order 需要更新狀態
   * @param {string} id deliveryOrder.id
   * @param {string} orderId order.id
   */
  async copyDeliveryOrder (id, orderId) {
    const response = await this.axios.post(`a/order/${orderId}/deliveryorder`, { id })
    return response.data
  }

  /**
  * @param {string} id deliveryOrder.id
   * @param {string} orderId order.id
   */
  async getPosPayLinks () {
    const response = await this.axios.get('a/pos-pay-link')
    return response.data
  }

  /**
  * @param {string} id deliveryOrder.id
   * @param {string} orderId order.id
   */
  async createPosPayLink (params) {
    const response = await this.axios.post('a/pos-pay-link', params)
    return response.data
  }

  /**
   * @param {string} orderId order.id
   * @param {*} params {reason,approver}
   */
  async cancelOrder (id, params) {
    const response = await this.axios.put(`a/order/${id}/cancel`, params)
    return response.data
  }

  /**
   * @param {string} orderId order.id
   */
  async sendDelaySMS (id) {
    const response = await this.axios.post(`a/order/${id}/notifydelay`)
    return response.data
  }
}
export default new GoAdminApi()
