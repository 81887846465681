import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IAuthState} */
export const initialState = {
  isInit: false,
  isLoading: false,
  error: '',
  user: {},
}

export default produce(
  /**
   *  @param {IAuthState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.SIGNIN:
      case ActionTypes.SIGNOUT:
      {
        draft.isLoading = true
        draft.error = ''
        break
      }
      case ActionTypes.SIGNIN_SUCCESS: {
        const { user } = action.payload
        draft.isLoading = false
        draft.user = user
        break
      }
      case ActionTypes.SIGNOUT_SUCCESS: {
        draft.isLoading = false
        draft.user = initialState.user
        break
      }
      case ActionTypes.SIGNIN_ERROR:
      case ActionTypes.SIGNOUT_ERROR: {
        const { message } = action.payload
        draft.isLoading = false
        draft.error = message
        break
      }

      default:
        break
    }
  },
  initialState,
)
