import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useImmer } from 'use-immer'
import React from 'react'
import _ from 'lodash'

import { Provider as ErrorsStateContextProvider } from '@/hooks/ErrorsStateContext'
import { Provider as InputStateContextProvider } from '@/hooks/inputStateContext'
import { useMerchant } from '@/hooks/merchantContext'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'

import AccountSettings from './settings/AccountSettings'
import BasicSettings from './settings/MerchantSettings'
import DirectorySettings from './settings/DirectorySettings'
import OrderSettings from './settings/OrderSettings'
import PaymentSettings from './settings/PaymentSettings'
import PrinterSettings from './settings/PrinterSettings'
import ReportSettings from './settings/ReportSettings'

const initialState = {}

/**
 *
 * @param {*} props
 * @returns
 */
export default function MerchantDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [errors, setErrors] = React.useState({})
  const [merchantState, setMerchant] = useMerchant()
  const [inputState, setInputState] = useImmer({})
  const updateInputState = (path, value) => {
    setInputState((draft) => {
      _.set(draft, path, value)
    })
  }

  React.useEffect(() => {
    setInputState((draft) => {
      // 初始 inputState
      const newState = {
        ...initialState,
        ...merchantState.merchant?.setting,
        // 經緯度特殊處理
        geometry: merchantState?.merchant?.setting?.location ? `${merchantState?.merchant?.setting?.location[1]},${merchantState?.merchant?.setting?.location[0]}` : '',
        // 帳號密碼特殊處理
        account: {
          username: merchantState?.merchant?.shortCode,
          password: undefined,
        },
      }
      return newState
    })
  }, [merchantState.merchant])

  const open = merchantState.openEditDialog

  const handleClose = () => {
    setMerchant('merchant', {})
    setMerchant('openEditDialog', false)
    setErrors({})
  }

  const handleSubmit = async () => {
    const params = { ...inputState }
    if (params.geometry) {
      const [lat, lng] = params.geometry.split(',')
      params.lat = +_.trim(lat)
      params.lng = +_.trim(lng)
      delete params.geometry
    }
    try {
      if (params.account.username !== merchantState?.merchant?.shortCode) {
        await dispatch(actions.merchant.updateMerchantShortCode(merchantState.merchant.id, params.account.username))
      }
      if (params.account.password) {
        await dispatch(actions.merchant.updateMerchantPassword(merchantState.merchant.id, params.account.username, params.account.password))
      }
      delete params.account

      await dispatch(actions.merchant.updateMerchant(merchantState.merchant.id, params))
      dispatch(actions.merchant.getMerchants())
    } catch (error) {
      dispatch(actions.app.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
    } finally {
      handleClose()
    }
  }

  const handleDelete = async () => {
    dispatch(actions.app.toggleDialog('confirmDialog', null, {
      content: `Are you sure to delete merchant ${merchantState.merchant?.name}? This action is not reversible`,
      confirmCallback: async () => {
        try {
          await dispatch(actions.merchant.deleteMerchant(merchantState.merchant.id))
          dispatch(actions.merchant.getMerchants())
        } catch (error) {
          dispatch(actions.app.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
        } finally {
          handleClose()
        }
      },
    }))
  }
  return (
    <InputStateContextProvider value={[inputState, updateInputState]}>
      <ErrorsStateContextProvider value={[errors, setErrors]}>
        <Dialog
          fullHeight
          title={`Edit ${merchantState.merchant?.name}`}
          open={open}
          handleClose={handleClose}
          renderActions={() => {
            return (
              <>
                <Button
                  variant='text'
                  color='secondary'
                  disableElevation
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            )
          }}
        >
          <div className={classes.form}>
            <BasicSettings />
            <PaymentSettings />
            <OrderSettings />
            <PrinterSettings />
            <DirectorySettings />
            <AccountSettings />
            <ReportSettings />
          </div>
        </Dialog>
      </ErrorsStateContextProvider>
    </InputStateContextProvider>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}))
