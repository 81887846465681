/** @type {IApiConfig} */
const config = {
  production: false,
  environment: 'beta',
  api: 'https://beta-node.dimorder.com/',
  customerApi: 'https://beta-customer-api.dimorder.com/',
  deliveryApi: 'https://beta-delivery-api.dimorder.com/',
  dimboxApi: 'https://box-api.dimorder.com',
  goAdminApi: 'https://beta-admin-api.dimorder.com/',
  quickPayApi: 'https://beta-quickpaylink-api.dimorder.com/',
  receipt: 'https://beta-receipt.dimorder.com/',
  notificationSocketUrl: 'wss://beta-api.dimorder.com/a/notification/subscribe',
  clientUrl: 'https://beta.dimorder.com',
  clientNewUrl: 'https://beta-c.dimorder.com',
  clientWaiterUrl: 'https://beta-waiter.dimorder.com',
  dashboardUrl: 'https://beta-dimorder-dashboard.web.app',
  reportUrl: 'https://beta-dimorder-report.web.app',
  mpUrl: 'https://beta-mp.dimorder.com',
  firebase: {
    apiKey: 'AIzaSyCtRflLUe8x6HLoowNgVumvf42oBDidslM',
    authDomain: 'dimorder-beta-10f3e.firebaseapp.com',
    databaseURL: 'https://dimorder-beta-10f3e.firebaseio.com',
    projectId: 'dimorder-beta-10f3e',
    storageBucket: 'dimorder-beta-10f3e.appspot.com',
    messagingSenderId: '1049923969734',
    appId: '1:1049923969734:web:7b4620abc5a2e4968cedc7',
  },
}

export default config
