import React from 'react'

const Context = React.createContext()

export function Provider (props) {
  const { children } = props
  const [state, setState] = React.useState()
  return <Context.Provider value={[state, setState]}>{children}</Context.Provider>
}

export function usePromoCode () {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('usePromoCode must be used within a Provider')
  }
  return context
}
