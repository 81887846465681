import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'
import CollapsibleList from '@/components/CollapsibleList'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function QuotaSettings (props) {
  const [expand, setExpand] = React.useState(false)
  const [inputState, updateInputState] = useInputState()
  // 有 orderAmount 或 quotaPerTime 時預設會打開
  const [showAutoSend, setShowAutoSend] = React.useState(!!(inputState.orderAmount === 0 || inputState.orderAmount > 0 || inputState.quotaPerTime))
  const [errors, setErrors] = useErrors()

  const handleChangeAutoSend = () => {
    // 如果原本沒打開，按了會初始成 0
    if (!showAutoSend) {
      updateInputState('orderAmount', 0)
    }
    // 如果有打開，按了會還原成 null 並關閉
    if (showAutoSend) {
      updateInputState('orderAmount', null)
      updateInputState('quotaPerTime', null)
    }
    setShowAutoSend(!showAutoSend)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
    setErrors({ ...errors, [name]: undefined })
  }

  return (
    <CollapsibleList title='使用次數' expand={expand} setExpand={setExpand}>
      {/* UsedTime */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Used Time'
          name='usedTime'
          type='number'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.usedTime ?? 0}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0 }}
          disabled
        />
        <FormHelperText>{inputState.type === 'COUPON' ? '已領次數' : '已用次數'}</FormHelperText>
      </FormControl>

      {/* Quota */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Quota'
          name='quota'
          type='number'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.quota ?? 1}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 1, max: 99999 }}
          error={Boolean(errors.quota)}
        />
        <FormHelperText>{inputState.type === 'COUPON' ? '可領數量' : '可用次數'}</FormHelperText>
      </FormControl>

      {/* Quota Per User */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Quota Per User'
          name='quotaPerUser'
          type='number'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.quotaPerUser ?? 0}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          inputProps={{
            min: inputState.type === 'COUPON' ? 1 : 0,
            max: inputState.type === 'COUPON' ? 10 : undefined,
          }}
          error={Boolean(errors.quotaPerUser)}
        />
        <FormHelperText>{inputState.type === 'COUPON' ? '單一使用者可領數量，最多10張（預設0=不限制）' : '單一使用者可用次數（預設0=不限制）'}</FormHelperText>
      </FormControl>

      {inputState.type === 'COUPON' &&
        <>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onClick={handleChangeAutoSend}
                        // 有 orderAmount 或 quotaPerTime 時預設會打開
                defaultChecked={showAutoSend}
              />
                  }
            label='自動派發優惠卷'
          />
          {showAutoSend &&
            <>
              {/* Order Amount */}
              <FormControl variant='outlined' margin='dense' fullWidth>
                <TextField
                  label='Order Amount'
                  name='orderAmount'
                  type='number'
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={inputState.orderAmount ?? ''}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  inputProps={{ min: 0, max: 99999 }}
                  error={Boolean(errors.orderAmount)}
                  disabled={!showAutoSend}
                />
                <FormHelperText>訂單小計滿多少可自動領取</FormHelperText>
              </FormControl>

              {/* claim delivery type */}
              <FormControl variant='outlined' margin='dense' fullWidth>
                <InputLabel margin='dense'>限制派發的Delivery Type</InputLabel>
                <Select
                  label='限制派發的Delivery Type'
                  name='claimDeliveryType'
                  variant='outlined'
                  fullWidth
                  value={inputState.claimDeliveryType ?? 'ALL'}
                  onChange={handleChange}
                >
                  <MenuItem value='ALL'>ALL</MenuItem>
                  <MenuItem value='takeaway'>TAKEAWAY</MenuItem>
                  <MenuItem value='storeDelivery'>STORE DELIVERY</MenuItem>
                  <MenuItem value='table'>TABLE</MenuItem>
                </Select>
                <FormHelperText>限制派發的Delivery Type</FormHelperText>
              </FormControl>

              {/* Quota Per Time */}
              <FormControl variant='outlined' margin='dense' fullWidth>
                <TextField
                  label='Quota Per Time'
                  name='quotaPerTime'
                  type='number'
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={inputState.quotaPerTime ?? ''}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  inputProps={{ min: 1, max: 99999 }}
                  error={Boolean(errors.quotaPerTime)}
                  disabled={!showAutoSend}
                />
                <FormHelperText>單次消費滿額可獲得幾張（不輸入則一次領滿）</FormHelperText>
              </FormControl>
            </>}
        </>}
    </CollapsibleList>
  )
}
