import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Home (props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      My Dashboard
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    placeItems: 'center',
  },
}))
