import { Redirect, Route } from 'react-router-dom'
import React from 'react'
import _ from 'lodash'

import hooks from '@/hooks'

/**
 *
 * @param {IRoute} props
 * @returns
 */
export default function RouteWithSubRoutes (route) {
  const user = hooks.auth.useUser()
  const isAuthenticated = _.isNil(route.roles) || _.get(user, 'role', '').split(',').some(r => _.includes(route.roles, r))
  const renderRoute = (props) => {
    if (route.redirect) {
      return <Redirect to={route.redirect} />
    }

    if (route.private) {
      if (!isAuthenticated) {
        return <div>Permission denied</div>
      }
    }

    return route.component && <route.component {...props} routes={route.routes} />
  }

  return <Route path={route.path} render={renderRoute} />
}
