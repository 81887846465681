import { makeStyles } from '@material-ui/core/styles'
import PROVIDER_COLORS from './providerColors'
import React from 'react'
import cx from 'classnames'
import moment from 'moment'

/**
 *
 * @param {{
 * step: number
 * label: string
 * latestStep: number
 * deliveryOrder: IDeliveryOrder
 * }} props
 * @returns
 */
export default function ProgressStep (props) {
  const { step, label, latestStep, deliveryOrder } = props
  const providerColor = PROVIDER_COLORS[deliveryOrder.provider]
  const classes = useStyles({ ...props, providerColor })
  const providerData = deliveryOrder.providers[deliveryOrder.provider]

  const getTimestamp = () => {
    switch (step) {
      case 3:
        return providerData.onGoingAt
      case 4:
        return providerData.pickedUpAt ?? deliveryOrder.start.timeActual
      case 5:
        return providerData.completedAt ?? deliveryOrder.end.timeActual
      default:
        return undefined
    }
  }
  const timestamp = getTimestamp()
  const timeLabel = timestamp ? moment(timestamp).format('HH:mm') : undefined

  return (
    <div className={cx(classes.step, { [classes.activeStep]: latestStep >= step })}>
      <span>{step}</span>
      <span className={cx(classes.label, { [classes.activeLabel]: latestStep >= step })}>{label}</span>
      <span className={classes.timeLabel}>{timeLabel}</span>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  step: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 30,
    minHeight: 30,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#bdbdbd',
    borderStyle: 'solid',
    color: '#bdbdbd',
  },
  activeStep: {
    backgroundColor: props => props.providerColor,
    borderStyle: 'none',
    color: '#ffffff',
    fontWeight: 700,
  },
  label: {
    position: 'absolute',
    top: 32,
    color: '#bdbdbd',
  },
  activeLabel: {
    color: '#424242',
    fontWeight: 700,
  },
  timeLabel: {
    position: 'absolute',
    top: 32 + 20,
    color: '#424242',
    fontWeight: 300,
    fontStyle: 'italic',
  },
}))
