import ActionTypes from './ActionTypes'
import deliveryApi from '@/libs/api/delivery'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @param {keyof IDialogs} dialog
 * @param {boolean?} open
 * @param {any?} data
 * @return {ThunkFunction}
 */
export function toggleDialog (dialog, open, data) {
  return (dispatch, getState) => {
    const toggledOpen = open ?? !getState().app.dialogs[dialog]?.open
    const toggledData = data ?? {}

    dispatch({
      type: ActionTypes.TOGGLE_DIALOG,
      payload: {
        dialog,
        open: toggledOpen,
        data: toggledData,
      },
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function resetDialogs () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DIALOGS,
    })
  }
}

/**
 * @param {string} id Driver.id
 * @param {object} content
 * @returns {ThunkFunction}
 */
export function sendNotification (id, content) {
  return async (dispatch, getState) => {
    try {
      const pushTicket = await deliveryApi.sendNotification(id, content)
      return pushTicket
    } catch (error) {
      console.error('[App/sendNotification] error', error)
    }
  }
}

export function setDrawerOpen (open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_DRAWER_OPEN,
      payload: {
        open,
      },
    })
  }
}
export function setMobileDrawerOpen (open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_MOBILE_DRAWER_OPEN,
      payload: {
        open,
      },
    })
  }
}
