import React from 'react'

import { actions, useDispatch } from '@/redux'
import AddIconButton from '@/components/IconButton/AddIconButton'
import ExportButton from '@/components/ExportButton'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import CustomerDialog from '../Customer/CustomerDialog'
import RefillDialog from './RefillDialog'
import RiceCoinSearchForm from './RiceCoinSearchForm'
import RiceCoinTable from './RiceCoinTable'

/**
 *
 * @param {*} props
 * @returns
 */
export default function RiceCoin (props) {
  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    dispatch(actions.app.toggleDialog('refillRiceCoin'))
  }
  const handleExport = () => {
    dispatch(actions.riceCoin.exportLedgers())
  }

  return (
    <Page>
      {/* content */}
      <PageHeader text='RiceCoin'>
        <AddIconButton color='primary' onClick={handleOpenDialog} />
        <ExportButton onClick={handleExport} />
      </PageHeader>
      <RiceCoinSearchForm />
      <RiceCoinTable />

      {/* dialog */}
      <RefillDialog />
      <CustomerDialog />
    </Page>
  )
}
