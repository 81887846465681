import { Button, ButtonBase, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import Page from '@/components/Page'
import constants from '@/constants'

const defaultState = {
  username: '',
  password: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function SignIn (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const [inputState, setInputState] = React.useState(defaultState)
  const error = useSelector(state => state.auth.error)

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState(prev => { return { ...prev, [name]: value } })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(actions.auth.signin(inputState.username, inputState.password, () => history.push('/ricecoin'),
    ))
  }

  return (
    <Page>
      <Paper className={classes.conainer}>
        <ButtonBase className={classes.title}>
          <img className={classes.logo} src={require('@/assets/images/logo192.png').default} />
          <Typography variant='h5' component='h1'>
            DimOrder
          </Typography>
        </ButtonBase>
        <Typography variant='h4' component='h3'>
          Sign In
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            label='Name'
            name='username'
            type='text'
            variant='outlined'
            className={classes.textField}
            value={inputState.username}
            onChange={handleChange}
          />
          <TextField
            label='Password'
            name='password'
            type='password'
            variant='outlined'
            className={classes.textField}
            value={inputState.password}
            onChange={handleChange}
          />
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disableElevation
            className={classes.button}
          >
            sign in
          </Button>
        </form>
        <Typography>{error}</Typography>
      </Paper>
    </Page>
  )
};

const useStyles = makeStyles(theme => ({
  conainer: {
    width: 400,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    textAlign: 'center',
    alignSelf: 'center',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
  },
  title: {
    height: constants.app.height.NAV_HEADER,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  logo: {
    width: 30,
    height: 30,
    objectFit: 'contain',
  },

}))
