import { routes } from '@/libs/routes'
import { useSelector } from '@/redux'
import _ from 'lodash'

/**
 * @function
 * @returns {boolean}
 */
export function useLoggedIn () {
  const user = useSelector(state => state.auth.user)
  const isLoggedIn = !_.isEmpty(user)

  return isLoggedIn
}

/**
 * @function
 * @returns {IUser}
 */
export function useUser () {
  return useSelector(state => state.auth.user)
}

/**
 * @returns {IRoute[]}
 */
export function useAuthenticatedRoutes () {
  const { role } = useSelector(state => state.auth.user)
  const roles = role.split(',')
  const viewRoute = _.filter(routes, route => route.showInNav)
  const result = _.filter(viewRoute, route => roles.some(r => _.includes(route.roles, r)))
  return result
}
