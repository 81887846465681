import { Button, FormControl, TextField } from '@material-ui/core'
import { Provider as ErrorsStateContextProvider } from '@/hooks/ErrorsStateContext'
import { Provider as InputStateContextProvider } from '@/hooks/inputStateContext'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useImmer } from 'use-immer'
import React from 'react'
import _ from 'lodash'

import { useMerchant } from '@/hooks/merchantContext'
import Dialog from '@/components/Dialog'

const initialState = {
  username: '',
  password: '',
  name: '',
  address: '',
  contact: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function CreateDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [errors, setErrors] = React.useState({})
  const [merchantState, setMerchant] = useMerchant()
  const [inputState, setInputState] = useImmer({})
  const updateInputState = (path, value) => {
    setInputState((draft) => {
      _.set(draft, path, value)
    })
  }

  // const validateInput = React.useCallback(() => {
  //   const temp = {}
  //   if (!_.isEmpty(temp)) {
  //     setErrors(temp)

  //     let message = ''
  //     if (_.size(temp) === 1) {
  //       message = _.map(temp, message => `* ${message}`)
  //     } else {
  //       message = _
  //         .chain(temp)
  //         .pickBy() // // pickBy() 預設使用 _.identity 來 pick，要注意的是這也會把 object 中任何 falsy value 給去掉，在這個情況是允許使用的，因為 temp 裡面只會有 string 或 undefined
  //         .map(message => `* ${message}`)
  //         .uniq() // 去除重複的錯誤訊息
  //         .join('\n')
  //         .value()
  //     }

  //     dispatch(actions.app.toggleDialog('alertDialog', true, {
  //       title: 'Error',
  //       content: message,
  //     }))

  //     return false
  //   } else {
  //     return true
  //   }
  // }, [inputState, merchantState])

  React.useEffect(() => {
    setInputState((draft) => {
      // 初始 inputState
      const newState = {
        ...initialState,

      }
      return newState
    })
  }, [merchantState.merchant])

  const open = merchantState.openCreateDialog

  const handleClose = () => {
    console.log('close')
    setMerchant('merchant', {})
    setMerchant('openCreateDialog', false)
    setErrors({})
  }

  const handleSubmit = async () => {
    // const valid = validateInput()
    // if (!valid) return
    try {
      await dispatch(actions.merchant.createMerchant(inputState))
      dispatch(actions.merchant.getMerchants())
    } catch (error) {
      dispatch(actions.app.toggleDialog('alertDialog', true, { title: 'Error', content: error.message }))
    } finally {
      handleClose()
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
    setErrors({ ...errors, [name]: undefined })
  }

  return (
    <InputStateContextProvider value={[inputState, updateInputState]}>
      <ErrorsStateContextProvider value={[errors, setErrors]}>
        <Dialog
          title='Create New Merchant'
          open={open}
          handleClose={handleClose}
          renderActions={() => {
            return (
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disableElevation
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )
          }}
        >
          <div className={classes.content}>
            <FormControl variant='outlined' margin='dense' fullWidth>
              <TextField
                label='Username'
                name='username'
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={inputState.username}
                onChange={handleChange}
                error={Boolean(errors.code)}
              />
            </FormControl>
            <FormControl variant='outlined' margin='dense' fullWidth>
              <TextField
                label='Password'
                name='password'
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={inputState.password}
                onChange={handleChange}
                error={Boolean(errors.code)}
              />
            </FormControl>
            <FormControl variant='outlined' margin='dense' fullWidth>
              <TextField
                label='Name'
                name='name'
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={inputState.name}
                onChange={handleChange}
                error={Boolean(errors.code)}
              />
            </FormControl>
            <FormControl variant='outlined' margin='dense' fullWidth>
              <TextField
                label='Address'
                name='address'
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={inputState.address}
                onChange={handleChange}
                error={Boolean(errors.code)}
              />
            </FormControl>
            <FormControl variant='outlined' margin='dense' fullWidth>
              <TextField
                label='Contact'
                name='contact'
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                value={inputState.contact}
                onChange={handleChange}
                error={Boolean(errors.code)}
              />
            </FormControl>
          </div>
        </Dialog>
      </ErrorsStateContextProvider>
    </InputStateContextProvider>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    padding: 8,
    gap: 8,
  },
}))
