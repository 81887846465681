// import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import {
  Button,
  TextField,
} from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React from 'react'

const defaultState = {
  id: '',
}

export default function ChangePasswordDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open, data } = useDialog('changePassword')
  const [inputState, setInputState] = React.useState(defaultState)
  const handleClose = () => {
    dispatch(actions.app.toggleDialog('changePassword'))
  }

  React.useEffect(() => {
    if (data.uid) {
      setInputState({
        ...inputState,
        id: data.uid,
      })
    }
  }, [open])

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState({ ...inputState, [name]: value })
  }

  const handleSubmit = async () => {
    const param = {
      ...inputState,
    }
    await dispatch(actions.app.toggleDialog('changePassword'))
    await dispatch(actions.user.updateUser(param))
  }
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title='Change password'
      renderActions={() => {
        return (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSubmit}
          >
            Update
          </Button>
        )
      }}
    >
      <div className={classes.box}>
        <div>Password:</div>
        <TextField
          fullWidth
          variant='outlined'
          size='small'
          name='password'
          type='password'
          classes={{ root: classes.textField }}
          value={inputState.password}
          onChange={handleChange}
        />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(1),
    padding: theme.spacing(3),
    alignItems: 'center',
  },
}))
