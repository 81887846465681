import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import SearchForm from './SearchForm'

/**
 *
 * @param {*} props
 * @returns
 */
export default function ReportNew (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const history = useHistory()
  const [validMerchants, setValidMerchants] = React.useState([])
  const firstHalfOfMonth = moment().format('D') < 16
  const defaultSearchParams = {
    startedAt: firstHalfOfMonth ? moment().subtract(1, 'months').date(16).startOf('day').toISOString() : moment().startOf('month').startOf('day').toISOString(),
    endedAt: firstHalfOfMonth ? moment().subtract(1, 'months').endOf('months').endOf('day').toISOString() : moment().date(15).endOf('day').toISOString(),
    deliveryType: ['takeaway', 'storeDelivery'],
  }
  const [merchantSearchParams, setMerchantSearchParams] = React.useState(defaultSearchParams)
  const [selectAll, setSelectAll] = React.useState(true)
  const loadingReportMerchants = useSelector(state => state.app.loadingApis.map(o => o.apiName).includes('getReportMerchants'))

  React.useEffect(() => {
    const getReportMerchants = async () => {
      const response = await dispatch(actions.report.getReportMerchants(merchantSearchParams))
      const merchants = response.map(m => ({
        ...m,
        checked: m.isSheetExist && m.isOrderExist,
      }))
      // 沒有在google sheet的店顯示在最前面
      const sortedMerchants = _.orderBy(merchants, ['isSheetExist', 'id'], ['asc', 'asc'])
      setValidMerchants(sortedMerchants)
    }
    getReportMerchants()
  }, [merchantSearchParams])

  const handleClick = (event, merchant) => {
    merchant.checked = event.target.checked
    setValidMerchants([...validMerchants])
    if (validMerchants.some(merchant => merchant.isSheetExist && !merchant.checked)) {
      setSelectAll(false)
    }
    if (validMerchants.every(merchant => (merchant.isSheetExist && merchant.checked) || !merchant.isSheetExist)) {
      setSelectAll(true)
    }
  }

  const handleSelectAll = () => {
    validMerchants.forEach(merchant => {
      if (merchant.isSheetExist) {
        merchant.checked = !selectAll
      }
    })
    setSelectAll(!selectAll)
    setValidMerchants([...validMerchants])
  }

  const MerchantCheckbox = (props) => {
    const merchant = props.merchant
    return (
      <div className={classes.checkboxLine}>
        <FormControlLabel
          control={
            <Checkbox
              name='checkedB'
              color='primary'
              disabled={!merchant.isSheetExist}
              value={merchant.id}
              checked={merchant.checked}
              onClick={() => handleClick(event, merchant)}
            />
        }
          label={merchant.name}
        />{!merchant.isSheetExist && <span className={classes.tip}>(No data in google sheet)</span>}
      </div>
    )
  }

  const handleSearch = (searchParams) => {
    setMerchantSearchParams(searchParams)
  }

  const handleCreateReport = async () => {
    const ids = validMerchants.filter(m => m.checked).map(m => m.id)
    const params = {
      merchantIDs: ids,
      ...merchantSearchParams,
    }
    await dispatch(actions.report.createJob(params))
    history.push('/report')
  }

  return (
    <Page>
      {/* content */}
      <PageHeader text='New Report'>
        <Button
          variant='contained'
          color='primary'
          className={classes.exportBtn}
          onClick={() => window.open('https://docs.google.com/spreadsheets/d/1sF03aabUdvbblCjUeS4qZe4iQFf4uzvkpwFF1BwQ-F0/edit#gid=0')}
        >
          Report Setting
        </Button>
      </PageHeader>
      <SearchForm handleSearch={handleSearch} />
      {
        loadingReportMerchants && (
          <div>
            載入中...
          </div>
        )
      }
      {
        !loadingReportMerchants && (
          <div>
            <div className={classes.title}>Merchant</div>
            <div className={classes.selectAll}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='ALL'
                    color='primary'
                    checked={selectAll}
                    onClick={() => handleSelectAll()}
                  />
                }
                label={<Typography className={classes.formControlLabel}>ALL</Typography>}
              />
            </div>
            <div className={classes.data}>
              <FormGroup className={classes.grid}>
                {_.map(validMerchants, merchant =>
                  <MerchantCheckbox key={merchant.id} merchant={merchant} />,
                )}
              </FormGroup>
              <div className={classes.createReportBtn}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleCreateReport}
                >
                  Create Report
                </Button>
              </div>
            </div>
          </div>
        )
      }
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  exportBtn: {
    marginLeft: 'auto',
  },
  title: {
    background: '#fafafa',
    padding: '12px',
  },
  data: {
    background: '#FFFFFF',
    padding: '12px',
  },
  formLabel: {
    display: 'block',
  },
  checkboxLine: {
    display: 'flex',
    alignItems: 'center',
  },
  tip: {
    color: 'red',
  },
  createReportBtn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  selectAll: {
    background: '#FFFFFF',
    paddingLeft: '12px',
    paddingTop: '12px',
  },
  formControlLabel: {
    fontWeight: 'bold',
  },
}))
