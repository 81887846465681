import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import TableSearchForm from '@/components/Table/TableSearchForm'

const initialDatetime = {
  from: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
  to: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
}

const initialInputState = {
  from: moment(initialDatetime.from).toISOString(),
  to: moment(initialDatetime.to).toISOString(),
  serial: '',
  code: '',
}

const DELIVERY_ORDER_STATUS = ['PENDING', 'ASSIGNING_DRIVER', 'ON_GOING', 'PICKED_UP', 'COMPLETED', 'CANCELED', 'REJECTED', 'EXPIRED']

/**
 *
 * @param {{
 * drivers: IDriver[]
 * driverOrderStatusMap: object
 * selectedDriver: string
 * setSelectedDriver: function
 * selectedStatus: 'ALL' | TDeliveryOrderStatus
 * setSelectedDriver: function
 * }} props
 * @returns
 */
export default function DeliveryOrderSearchForm (props) {
  const {
    drivers,
    driverOrderStatusMap,
    selectedDriver,
    setSelectedDriver,
    selectedStatus,
    setSelectedStatus,
  } = props

  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [inputState, setInputState] = React.useState(initialInputState)
  const [datetime, setDatetime] = React.useState(initialDatetime)

  const handleSearch = (event) => {
    event.preventDefault()
    dispatch(actions.deliveryOrder.updateMeta({ currentPage: 1 }))
    dispatch(actions.deliveryOrder.getDeliveryOrders(inputState))
  }

  const handleReset = (event) => {
    event.preventDefault()
    setInputState(initialInputState)
    setDatetime(initialDatetime)
    dispatch(actions.deliveryOrder.getDeliveryOrders(initialInputState))
  }

  const handleChange = (event) => {
    let { name, value } = event.currentTarget
    if (name === 'from' || name === 'to') {
      setDatetime({ ...datetime, [name]: value })
      value = moment(value).toISOString()
    }
    setInputState({ ...inputState, [name]: value })
  }

  return (
    <TableSearchForm
      handleSearch={handleSearch}
      handleReset={handleReset}
    >
      {/* Order Serial Input */}
      <TextField
        label='Serial'
        name='serial'
        type='tel'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.serial}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputProps={{ startAdornment: <InputAdornment position='start'>#</InputAdornment> }}
      />

      {/* Code Input */}
      <TextField
        label='Code'
        name='code'
        type='text'
        variant='outlined'
        size='small'
        placeholder='Dxx'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.code}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
      />

      {/* Status Selector */}
      <FormControl variant='outlined' fullWidth className={classes.formControl}>
        <InputLabel margin='dense'>Status</InputLabel>
        <Select
          className={classes.selectControl}
          classes={{ select: classes.select }}
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value)
          }}
          label='Status'
          name='sriver'
          margin='dense'
        >
          <MenuItem value='ALL'>
            <Typography>ALL</Typography>
          </MenuItem>
          {
            _.map(DELIVERY_ORDER_STATUS, (STATUS, index) => (
              <MenuItem key={index} value={STATUS}>
                <Typography>{STATUS}</Typography>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>

      {/* Driver Selector */}
      <FormControl variant='outlined' fullWidth className={classes.formControl}>
        <InputLabel margin='dense'>Driver</InputLabel>
        <Select
          className={classes.selectControl}
          classes={{ select: classes.select }}
          value={selectedDriver}
          onChange={(e) => {
            setSelectedDriver(e.target.value)
          }}
          label='Driver'
          name='driver'
          margin='dense'
        >
          <MenuItem value='ALL'>
            <Typography>ALL</Typography>
          </MenuItem>
          {
            _.map(drivers, (driver, index) => {
              const statusMap = driverOrderStatusMap[driver?.id]
              if (!_.size(statusMap?.ASSIGNING_DRIVER) && !_.size(statusMap?.ON_GOING) && !_.size(statusMap?.PICKED_UP)) {
                // 若 driver 當前沒有被分派的外送單 ASSIGNING_DRIVER/ON_GOING/PICKED_UP 則不顯示
                return null
              }

              return (
                <MenuItem key={index} value={driver.id}>
                  <Typography style={{ flex: 1 }}>{driver.name}</Typography>
                  <div className={classes.countsWrap}>
                    <Typography color='secondary'>{_.size(statusMap?.ON_GOING)}</Typography>
                    <Typography className={classes.divider}>/</Typography>
                    <Typography color='secondary'>{_.size(statusMap?.PICKED_UP)}</Typography>
                    <Typography className={classes.divider}>/</Typography>
                    <Typography color='secondary'>{_.size(statusMap?.COMPLETED)}</Typography>
                  </div>
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>

      {/* Start Time Input */}
      <TextField
        label='From'
        name='from'
        type='datetime-local'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={datetime.from}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {/* End Time Input */}
      <TextField
        label='To'
        name='to'
        type='datetime-local'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={datetime.to}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </TableSearchForm>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  textField: {
    '& .MuiInputBase-formControl': {
      background: theme.palette.common.white,
    },
  },
  formControl: {
    // background: theme.palette.common.white,
  },
  selectControl: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  fileds: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: theme.spacing(1),
  },
  countsWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
  },
  divider: {
    color: '#d1d1d1',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
  },
}))
