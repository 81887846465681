export default {
  width: {
    NAV_DRAWER: 240,
  },
  height: {
    NAV_HEADER: 60,
  },
  deliveryType: {
    takeaway: '外賣',
    storeDelivery: '外送',
  },
}
