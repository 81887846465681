import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'

import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import SMSRecordTable from './SMSRecordTable'
import SMSStatistics from './SMSStatistics'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SMSHistory (props) {
  const classes = useStyles(props)
  const history = useHistory()
  return (
    <Page>
      {/* content */}
      <PageHeader text='SMS/Notification History'>
        <Button
          variant='contained'
          disableElevation
          color='primary'
          className={classes.newBtn}
          onClick={() => { history.push('/SMS/new') }}
        >
          Create
        </Button>
      </PageHeader>
      <SMSStatistics />
      <SMSRecordTable />
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  newBtn: {
    marginLeft: 'auto',
  },
}))
