import { useSelector } from '@/redux'

/**
 * @function
 * @param {string} dialog
 * @returns {IModal}
 */
export function useDialog (dialog) {
  return useSelector(state => state.app.dialogs[dialog])
}
