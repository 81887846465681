import betaConfig from './beta'
import prodConfig from './prod'
import testConfig from './test'

/** @type {'test' | 'beta' | 'prod'} */
const REACT_APP_ENV = process.env.REACT_APP_ENV
/** @type {'test' | 'beta' | 'prod'} */
const OVERWRITE_ENV = localStorage.getItem('OVERWRITE_ENV')

const env = OVERWRITE_ENV || REACT_APP_ENV || 'test'
console.log(`REACT_APP_VERSION=${process.env.REACT_APP_VERSION}`)
console.log(`REACT_APP_ENV=${REACT_APP_ENV}`)
console.log(`OVERWRITE_ENV=${OVERWRITE_ENV}`)
console.log('use env', env)

/** @type {IApiConfigs} */
const configs = {
  test: testConfig,
  beta: betaConfig,
  prod: prodConfig,
  production: prodConfig,
}

/** @type {IApiConfig} */
const envConfig = configs[env] || {}

/** @type {IApiConfig} */
const exportConfig = {
  env,
  ...testConfig,
  ...envConfig,
}

export default exportConfig
