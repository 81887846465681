import { TableBody as MuiTableBody, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

TableBody.prototypes = {
  getTableBodyProps: PropTypes.func,
  rows: PropTypes.array,
  prepareRow: PropTypes.func,
}

/**
 *
 * @param {{
 *  getTableBodyProps: Function
 *  rows: any[]
 *  prepareRow: Function
 * }} props
 * @returns
 */
export default function TableBody (props) {
  const { getTableBodyProps, rows, prepareRow, tableBodyProps } = props
  const classes = useStyles(props)
  const renderDeliveryOrderProgressBar = tableBodyProps?.tableRowProps?.renderDeliveryOrderProgressBar
  const tableCellProps = tableBodyProps?.tableRowProps?.tableCellProps

  return (
    <MuiTableBody {...getTableBodyProps()}>
      {_.map(rows, (row, index) => {
        prepareRow(row)
        return (
          <React.Fragment key={index}>
            {Boolean(renderDeliveryOrderProgressBar) && renderDeliveryOrderProgressBar(index)}
            <TableRow {...row.getRowProps()} className={classes.row} hover>
              {_.map(row.cells, cell =>
                <TableCell
                  className={classes.cell}
                  {...cell.getCellProps()}
                  style={{ ...(tableCellProps?.style ?? {}) }}
                >
                  {cell.render('Cell')}
                </TableCell>)}
            </TableRow>
          </React.Fragment>
        )
      })}
    </MuiTableBody>
  )
}

const useStyles = makeStyles(theme => ({
  row: {
    position: 'relative',
  },
  cell: {
    whiteSpace: 'pre-line',
    minWidth: 100,
  },
}))
