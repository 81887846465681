import { FormControl, Switch, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'
import React from 'react'
import _ from 'lodash'

/**
 *
 * @param {*} props
 * @returns
 */
export default function SwitchControl (props) {
  const { setting } = props
  const [inputState, updateInputState] = useInputState()
  const [errors, setErrors] = useErrors()
  const classes = useStyles()
  const checked = _.get(inputState, setting.paramaterName, false)

  const handleSwitchChange = (event) => {
    const { name, checked } = event.currentTarget
    updateInputState(name, checked)
    setErrors({ ...errors, [name]: undefined })
  }

  return (
    <FormControl variant='outlined' margin='dense' fullWidth key={setting.label}>
      <div className={classes.switchLine}>
        <Typography>{setting.label}</Typography>
        <Switch
          color='primary'
          checked={checked}
          name={setting.paramaterName}
          onChange={handleSwitchChange}
          {...setting.inputProps}
        />
      </div>
    </FormControl>
  )
}

const useStyles = makeStyles(theme => ({
  switchLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))
