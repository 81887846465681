import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IRiceCoinQueryParams} */
export const initialParams = {
  page: 1,
  memo: '',
  mobile: '',
  startedAt: '',
  endedAt: '',
}

/** @type {IRiceCoinState} */
export const initialState = {
  isInit: false,
  data: [],
  meta: {},
  params: initialParams,
}

export default produce(
  /**
   *  @param {IRiceCoinState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_DATA: {
        const { data } = action.payload
        draft.data = data
        break
      }
      case ActionTypes.UPDATE_META: {
        const { meta } = action.payload
        draft.meta = meta
        break
      }
      case ActionTypes.UPDATE_PARAMS: {
        const { params } = action.payload
        draft.params = params
        break
      }
      default:
        break
    }
  },
  initialState,
)
