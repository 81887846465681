import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IModal} */
const initialModal = {
  open: false,
  data: {},
}

/** @type {IDialogs} */
const initalDialogs = {
  refillRiceCoin: initialModal,
  customerDetail: initialModal,
  confirmDialog: initialModal,
  alertDialog: initialModal,
  addRefund: initialModal,
  showRefund: initialModal,
  addUser: initialModal,
  editUser: initialModal,
  addPayLink: initialModal,
  changePassword: initialModal,
  orderCancelDialog: initialModal,
}

/** @type {IAppState} */
export const initialState = {
  isInit: false,
  dialogs: initalDialogs,
  loadingApis: [],
  drawerOpen: true,
  mobileDrawerOpen: false,
}

export default produce(
  /**
   *  @param {IAppState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.TOGGLE_DIALOG: {
        const { dialog, open, data } = action.payload
        draft.dialogs[dialog] = { open, data }
        break
      }
      case ActionTypes.RESET_DIALOGS: {
        draft.dialogs = initalDialogs
        break
      }
      case ActionTypes.CREATE_API_REQUEST: {
        const { apiName, request, controller } = action.payload
        draft.loadingApis.push({ apiName, request, controller })
        // console.log('API REQUEST: ', apiName)
        break
      }
      case ActionTypes.FINISH_API_REQUEST: {
        const { apiName } = action.payload
        draft.loadingApis = _.without(draft.loadingApis, _.find(draft.loadingApis, { apiName }))
        // console.log('API FINISH: ', _.clone(draft.loadingApis))
        break
      }
      case ActionTypes.UPDATE_DRAWER_OPEN: {
        const { open } = action.payload
        draft.drawerOpen = open
        break
      }
      case ActionTypes.UPDATE_MOBILE_DRAWER_OPEN: {
        const { open } = action.payload
        draft.mobileDrawerOpen = open
        break
      }
      default:
        break
    }
  },
  initialState,
)
