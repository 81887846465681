import ActionTypes from './ActionTypes'
import produce from 'immer'

/** @type {ISMSParams} */
export const initialParams = {
  limit: 10,
  page: 1,
}
/** @type {ISMSState} */
export const initialState = {
  isInit: false,
  records: {
    data: [],
    meta: {
      perPage: 10,
      currentPage: 1,
      total: 0,
    },
    params: initialParams,
  },
}

export default produce(
  /**
   *  @param {ISMSState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_RECORD_DATA: {
        const { data } = action.payload
        draft.records.data = data
        break
      }
      case ActionTypes.UPDATE_RECORD_META: {
        const { meta } = action.payload
        draft.records.meta = meta
        break
      }
      case ActionTypes.UPDATE_RECORD_PARAMS: {
        const { params } = action.payload
        draft.records.params = params
        break
      }
      default:
        break
    }
  },
  initialState,
)
