import { Button, InputAdornment, TextField } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React from 'react'
import _ from 'lodash'

const defaultState = {
  mobile: '',
  amount: '',
  memo: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function RefillDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open } = useDialog('refillRiceCoin')
  const [inputState, setInputState] = React.useState(defaultState)
  const isSubmitDisabled = _.values(inputState).some(v => v === '' || v === null)

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState(prev => { return { ...prev, [name]: value } })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(actions.riceCoin.refillRiceCoin(inputState))
    handleClose()
  }

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('refillRiceCoin'))
    setInputState(defaultState)
  }

  return (
    <Dialog
      title='Refill RiceCoin'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disableElevation
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )
      }}
    >
      <form className={classes.form} autoComplete='off'>
        <TextField
          label='Mobile'
          name='mobile'
          type='tel'
          required
          variant='outlined'
          size='medium'
          fullWidth
          value={inputState.mobile}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position='start'>+852</InputAdornment>,
          }}
        />
        <TextField
          label='Amount'
          name='amount'
          type='number'
          required
          variant='outlined'
          size='medium'
          fullWidth
          value={inputState.amount}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
        />
        <TextField
          label='Memo'
          name='memo'
          type='text'
          required
          variant='outlined'
          size='medium'
          fullWidth
          value={inputState.memo}
          onChange={handleChange}
        />
      </form>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))
