import axios from 'axios'

import { getToken } from '@/libs/firebase'
import config from '@/config'

class DeliveryApi {
  constructor (url) {
    this.token = undefined
    this.axios = axios.create({ baseURL: url || config.deliveryApi })
    this.axios.interceptors.request.use(
      async config => {
        this.token = await getToken()
        config.headers.Authorization = `TOKEN ${this.token}`
        return config
      },
      error => error,
    )
  }

  /**
   *
   * @param {string} deliveryOrderId DeliveryOrder.id
   * @param {object} data
   * @returns
   */
  async updateDeliveryOrder (deliveryOrderId, data) {
    const response = await this.axios.patch(`/delivery/orders/${deliveryOrderId}`, { data })
    return response.data
  }

  /**
   *
   * @param {string} deliveryOrderId
   * @param {string} driverId
   */
  async assignDriver (deliveryOrderId, driverId) {
    const response = await this.axios.post(`/delivery/orders/${deliveryOrderId}/assign/${driverId}`)
    return response.data
  }

  /**
   *
   * @param {string} deliveryOrderId
   * @returns
   */
  async cancelDeliveryOrder (deliveryOrderId) {
    const response = await this.axios.put(`/delivery/orders/${deliveryOrderId}/cancel`)
    return response.data
  }

  /**
   *
   * @param {string} deliveryOrderId
   * @param {string} serviceType
   * @returns
   */
  async placeLalamoveOrder (deliveryOrderId, serviceType) {
    const response = await this.axios.post(`/delivery/orders/${deliveryOrderId}/lalamove/orders`, { serviceType })
    return response.data
  }

  /**
   *
   * @param {string} lalamoveOrderId
   * @returns
   */
  async getLalamoveOrderDetails (lalamoveOrderId) {
    const response = await this.axios.get(`/lalamove/orders/${lalamoveOrderId}`)
    return response.data
  }

  /**
   *
   * @param {string} lalamoveOrderId
   * @returns
   */
  async cancelLalamoveOrder (lalamoveOrderId) {
    const response = await this.axios.delete(`/lalamove/orders/${lalamoveOrderId}`)
    return response.data
  }

  /**
   *
   * @param {string} driverId
   * @param {object} content
   * @returns
   */
  async sendNotification (driverId, content) {
    const response = await this.axios.post(`/notification/send/${driverId}`, { content })
    return response.data
  }

  /**
   *
   * @param {string} driverId
   * @param {boolean} enable
   * @returns
   */
  async enableDriver (driverId, enable) {
    const response = await this.axios.post(`/driver/${driverId}/enable`, { enable })
    return response.data
  }

  /**
   *
   * @param {string} deliveryOrderId
   * @param {string} lalamoveOrderId
   * @returns
   */
  async updateLalamoveOrderId (deliveryOrderId, lalamoveOrderId) {
    const response = await this.axios.patch(`/admin/orders/${deliveryOrderId}/lalamove/${lalamoveOrderId}`)
    return response.data
  }
}

export default new DeliveryApi()
