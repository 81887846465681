/** @type {IApiConfig} */
const config = {
  production: false,
  environment: 'test',
  api: 'https://test-node.dimorder.com/',
  customerApi: 'https://test-customer-api.dimorder.com/',
  // deliveryApi: 'http://localhost:8080/',
  deliveryApi: 'https://test-delivery-api.dimorder.com/',
  dimboxApi: 'https://box-api.dimorder.com',
  goAdminApi: 'https://test-api.dimorder.com/',
  quickPayApi: 'https://test-quickpaylink-api.dimorder.com/',
  receipt: 'https://test-receipt.dimorder.com/',
  notificationSocketUrl: 'wss://test-api.dimorder.com/a/notification/subscribe',
  clientUrl: 'https://test.dimorder.com',
  clientNewUrl: 'https://test-c.dimorder.com',
  clientWaiterUrl: 'https://test.dimorder.com',
  dashboardUrl: 'https://test-dimorder-dashboard.web.app',
  reportUrl: 'https://test-dimorder-report.web.app',
  mpUrl: 'https://test-mp.dimorder.com',
  firebase: {
    apiKey: 'AIzaSyCC-reTueeTZke4P2RgTQ2L0eIUsHzjK1Q',
    authDomain: 'dimorder-dev.firebaseapp.com',
    databaseURL: 'https://dimorder-dev.firebaseio.com',
    projectId: 'dimorder-dev',
    storageBucket: 'dimorder-dev.appspot.com',
    messagingSenderId: '244888588047',
    appId: '1:244888588047:web:bcab955db302269407787a',
  },
}

export default config
