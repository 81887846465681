import { ButtonBase, Drawer, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { NavLink, useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHotkeys } from 'react-hotkeys-hook'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Hidden from '@material-ui/core/Hidden'
import React from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { actions, useDispatch, useSelector } from '@/redux'
import { setMobileDrawerOpen } from '@/redux/app/actions'
import constants from '@/constants'
import hooks from '@/hooks'

const DRAWER_WIDTH = 200
const desktopMediaQuery = window.matchMedia('(min-width: 1200px)')

/**
 *
 * @param {*} props
 * @returns
 */
export default function NavDrawer (props) {
  const { window } = props
  const classes = useStyles(props)
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const routes = hooks.auth.useAuthenticatedRoutes()
  const drawerOpen = useSelector(state => state.app.drawerOpen)
  const mobileDrawerOpen = useSelector(s => s.app.mobileDrawerOpen)

  useHotkeys('[', (event, handler) => {
    dispatch(actions.app.setDrawerOpen(!drawerOpen))
  }, [drawerOpen])

  // 選單自動開合
  function handleMediaChange () {
    if (desktopMediaQuery.matches) {
      dispatch(actions.app.setDrawerOpen(true))
    } else {
      dispatch(actions.app.setDrawerOpen(false))
    }
  }
  desktopMediaQuery.addEventListener('change', handleMediaChange)

  const renderItem = (route, i) => {
    // TODO: can add divider
    return (
      <NavLink className={classes.navLink} key={route.path} to={route.path} activeClassName={classes.activeNavLink} exact>
        <ListItem className={classes.navListItem} key={route.path} button>
          {route.icon && (
            <div className={classes.routeIcon}>
              {route.icon()}
            </div>
          )}
          {(mobileDrawerOpen || drawerOpen) &&
            <ListItemText primary={route.menuName} />}
        </ListItem>
      </NavLink>
    )
  }

  const drawer = (
    <>
      <div>
        <ButtonBase className={classes.title} onClick={() => history.push('/')}>
          <img className={classes.logo} src={require('@/assets/images/logo192.png').default} />
          {
          (mobileDrawerOpen || drawerOpen) &&
            <Typography variant='h5' component='h1'>
              DimOrder
            </Typography>
        }
        </ButtonBase>
      </div>
      {
          drawerOpen && process.env.REACT_APP_VERSION && (
            <div>
              <div className={classes.textCenter}>v{process.env.REACT_APP_VERSION}</div>
            </div>
          )
      }
      <div className={classes.drawerContainer}>
        <List>
          {_.map(routes, (route, i) => renderItem(route, i))}
        </List>
      </div>
      {
        !mobileDrawerOpen &&
          <div>
            <ButtonBase className={cx(classes.chevronButton, classes.bottomMenuItem)} onClick={() => dispatch(actions.app.setDrawerOpen(!drawerOpen))}>
              {
            drawerOpen
              ? <ChevronLeftIcon />
              : <ChevronRightIcon />
          }
            </ButtonBase>
          </div>
      }
    </>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <>
      {/* desktop drawer */}
      <Hidden smUp implementation='css'>
        <Drawer
          container={container}
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileDrawerOpen}
          onClose={() => { dispatch(setMobileDrawerOpen(!mobileDrawerOpen)) }}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {/* mobile drawer */}
      <Hidden xsDown implementation='css'>
        <Drawer
          variant='permanent'
          open={drawerOpen}
          className={cx(classes.drawer, {
            [classes.drawerOpen]: mobileDrawerOpen || drawerOpen,
            [classes.drawerClose]: !mobileDrawerOpen && !drawerOpen,
          })}
          classes={{
            paper: cx({
              // [classes.drawerPaper]: true,
              [classes.drawerOpen]: mobileDrawerOpen || drawerOpen,
              [classes.drawerClose]: !mobileDrawerOpen && !drawerOpen,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    // width: '100%',
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    // minWidth: constants.app.width.NAV_DRAWER,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  title: {
    height: constants.app.height.NAV_HEADER,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
    margin: '0 auto',
  },
  logo: {
    width: 30,
    height: 30,
    objectFit: 'contain',
  },
  navLink: {
    textDecoration: 'none',
    color: '#533633',
  },
  activeNavLink: {
    '& span': {
      fontWeight: 'bold',
    },
    '& > div': {
      background: '#f8f1e9',
    },
  },
  routeIcon: {
    width: 30,
    height: 30,
    fontSize: 20,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    '& $title': {
      gap: 0,
    },
    '& $routeIcon': {
      marginRight: 0,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(4) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  chevronButton: {
    width: '100%',
    height: '45px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  bottomMenuItem: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
  navListItem: {
    padding: 8,
  },
  textCenter: {
    textAlign: 'center',
  },
}))
