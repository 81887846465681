import React from 'react'

import CustomerDialog from './CustomerDialog'
import CustomerSearchForm from './CustomerSearchForm'
import CustomerTable from './CustomerTable'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Customer (props) {
  return (
    <Page>
      {/* content */}
      <PageHeader text='Customer' />
      <CustomerSearchForm />
      <CustomerTable />

      {/* dialog */}
      <CustomerDialog />
    </Page>
  )
}
