import ActionTypes from './ActionTypes'
import customerApi from '@/libs/api/customer'
import moment from 'moment'
/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getLedgers () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getLedgers' },
    })
    try {
      const params = { ...getState().riceCoin.params }
      // clear params
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })
      const response = await customerApi.getLedgers({
        ...params,
        ...params.mobile && { mobile: '+852' + params.mobile },
      })
      const { data, meta } = response

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { meta },
      })
    } catch (error) {
      console.log('getLedgers error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getLedgers' },
      })
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function exportLedgers () {
  return async (dispatch, getState) => {
    try {
      const params = { ...getState().riceCoin.params }
      // clear params
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })
      const response = await customerApi.getLedgers({
        ...params,
        ...params.mobile && { mobile: '+852' + params.mobile },
        // format: 'csv',
        limit: Number.MAX_SAFE_INTEGER,
      })
      // create csv
      let csv = 'id,amount,memo,merchant_name,Serial #,delivery_type,created_at,mobile,admin,ratio\n'
      response.data.forEach(e => {
        csv += `${e.id || ''},${e.amount || ''},"${e.memo || ''}","${e.merchantName || ''}",${e.orderSerial || 'Not Found'},${e.deliveryType || ''},${moment(e.createdAt).format('YYYY-MM-DD HH:mm') || ''},"${e.mobile || ''}",${e.admin || ''},${e.ratio || ''}\n`
      })
      // download
      const file = new Blob(['\uFEFF' + csv], { type: 'application/csv;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = 'rice_coin.csv'
      link.click()
      window.URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
    } catch (error) {
      console.log('getLedgers error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function refillRiceCoin (input) {
  const delay = (n) => {
    return new Promise(resolve => {
      setTimeout(resolve, n * 1000)
    })
  }
  return async (dispatch) => {
    try {
      await customerApi.refillRiceCoin({
        mobile: '+852' + input.mobile,
        amount: input.amount,
        memo: input.memo,
      })
      // delay 若無delay會有機率沒抓到剛剛新增的資料
      await delay(0.5)
      // refill後，重新抓取ledgers
      dispatch(getLedgers())
    } catch (error) {
      console.log('refillRiceCoin error', error)
    }
  }
}

/**
 * @function
 * @param {IRiceCoinQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}
