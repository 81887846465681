import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {{
 * onClick: () => void
 * }} props
 * @returns
 */
export default function ExportButton (props) {
  const { onClick: handleClick } = props
  const classes = useStyles(props)

  return (
    <Button
      disableElevation
      variant='contained'
      className={classes.exportButton}
      onClick={handleClick}
    >Export
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  exportButton: {
    marginLeft: 'auto',
    backgroundColor: '#777777',
    color: '#fafafa',
    '&:hover': {
      backgroundColor: '#666666',
    },
  },
}))
