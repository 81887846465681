/** @type {IApiConfig} */
const config = {
  production: true,
  environment: 'production',
  api: 'https://node.dimorder.com/',
  customerApi: 'https://customer-api.dimorder.com/',
  deliveryApi: 'https://delivery-api.dimorder.com/',
  dimboxApi: 'https://box-api.dimorder.com',
  goAdminApi: 'https://admin-api.dimorder.com/',
  quickPayApi: 'https://quickpaylink-api.dimorder.com/',
  receipt: 'https://receipt.dimorder.com/',
  notificationSocketUrl: 'wss://admin-api.dimorder.com/a/notification/subscribe',
  clientUrl: 'https://dimorder.com',
  clientNewUrl: 'https://c.dimorder.com',
  clientWaiterUrl: 'https://waiter2.dimorder.com',
  dashboardUrl: 'https://dimorder-dashboard.web.app',
  reportUrl: 'https://dimorder-report.web.app',
  mpUrl: 'https://mp.dimorder.com',
  firebase: {
    apiKey: 'AIzaSyAYq9sViFBGnHD4BT1pRWftsTDacoAQ1j0',
    authDomain: 'dimorder1.firebaseapp.com',
    databaseURL: 'https://dimorder1.firebaseio.com',
    projectId: 'dimorder1',
    storageBucket: 'dimorder1.appspot.com',
    messagingSenderId: '756396734164',
    appId: '1:756396734164:web:9300f6bcad2df73a936d41',
    measurementId: 'G-S96QJD7K3D',
  },
}

export default config
