import _ from 'lodash'
import moment from 'moment'

import { boxesIdReducer, itemsReducer } from '@/libs/dimbox'
import dimboxAPi from '@/libs/api/dimbox'

import ActionTypes from './ActionTypes'

/**
 *
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    try {
      await dispatch(updateDimboxes())
      dispatch({ type: ActionTypes.INIT })
    } catch (error) {
      console.error('[Dimbox/init] error', error)
    }
  }
}

/**
 *
 * @param {Date?} pickupAt
 * @returns {ThunkFunction}
 */
export function updateDimboxes (pickupAt) {
  return async (dispatch, getState) => {
    try {
      const date = pickupAt ? moment(pickupAt).format('YYYY-MM-DD') : undefined
      // const cutOffTime = moment().hours(10).minutes(30)
      const defaultDate = moment().format('YYYY-MM-DD')
      const dimboxes = await dimboxAPi.getDimboxes(date ?? defaultDate)
      dispatch({
        type: ActionTypes.UPDATE_DIMBOXES,
        payload: { boxes: dimboxes?.boxes ?? [], available: dimboxes?.available ?? 0 },
      })
    } catch (error) {
      console.error('[Dimbox/updateDimboxes] error', error)
    }
  }
}

/**
 *
 * @param {number} cabinetId
 * @returns {ThunkFunction}
 */
export function selectCabinet (cabinetId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_CABINET,
      payload: { cabinetId },
    })
  }
}

/**
 *
 * @param {*} orders
 * @returns {ThunkFunction}
 */
export function exportDimboxOrders (orders) {
  return async (dispatch, getState) => {
    try {
      const headers = [
        'serial',
        'merchant_name',
        'boxes',
        'items',
        'takeaway_status',
        'created_at',
      ]

      const th = _.join(headers, ';')
      const rows = _.map(orders, order => {
        const o = order
        const columns = [
          o.serial,
          o.merchantName,
          `"${_.join(boxesIdReducer(o.boxes), '\r')}"`,
          `"${_.join(itemsReducer(o.batches), '\r')}"`,
          o.takeawayStatus,
          moment(o.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        ]
        const row = _.join(columns, ';')
        return row
      })

      const csv = _.join([th, ...rows], '\n')
      const file = new Blob(['\uFEFF' + csv], { type: 'application/csv;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = `dimbox_orders_${moment().format('YYYY-MM-DD')}.csv`
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.error') || error
      if (errorMessage) {
        window.alert(errorMessage)
      }
      console.error('exportDimboxOrders error', error)
    }
  }
}

/**
 *
 * @param {*} summary
 * @returns {ThunkFunction}
 */
export function exportDimboxOrderSummary (summary) {
  return async (dispatch, getState) => {
    try {
      const headers = [
        'merchant_name',
        'items',
      ]

      const th = _.join(headers, ';')
      const rows = _.map(summary, merchantSummary => {
        const columns = [
          merchantSummary.merchantName,
          `"${_.join(merchantSummary.itemSummary, '\r')}"`,
        ]
        const row = _.join(columns, ';')
        return row
      })
      const csv = _.join([th, ...rows], '\n')
      const file = new Blob(['\uFEFF' + csv], { type: 'application/csv;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = `dimbox_orders_summary_${moment().format('YYYY-MM-DD')}.csv`
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.error') || error
      if (errorMessage) {
        window.alert(errorMessage)
      }
      console.error('exportDimboxOrderSummary error', error)
    }
  }
}
