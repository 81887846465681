import React, { useEffect } from 'react'

import { actions, useDispatch } from '@/redux'
import ExportButton from '@/components/ExportButton'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import OrderQuickSearch from './OrderQuickSearch'
import OrderSearchForm from './OrderSearchForm'
import OrderTable from './OrderTable'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Order (props) {
  const dispatch = useDispatch()
  const handleExport = () => {
    dispatch(actions.order.exportOrders())
  }

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(actions.order.getOrders())
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Page>
      {/* content */}
      <PageHeader text='Order Record'>
        <ExportButton onClick={handleExport} />
      </PageHeader>
      <OrderSearchForm />
      <OrderQuickSearch />
      <OrderTable />
    </Page>
  )
}
