import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ProgressLines from './ProgressLines'
import ProgressStep from './ProgressStep'
import React from 'react'
import STEPS_MAP from './stepsMap'
import TitleColumn from './TitleColumn'
import _ from 'lodash'

const labels = Object.keys(STEPS_MAP)
const lastIndex = _.findLastIndex(labels)

/**
 *
 * @param {{
 * deliveryOrder: IDeliveryOrder
 * setLalamoveOprationDialogOpen: () => void
 * setSelectedOrderId: () => void
 * }} props
 * @returns
 */
export default function DeliveryOrderProgressBar (props) {
  const { deliveryOrder } = props
  const classes = useStyles(props)

  const lalamoveStatus = deliveryOrder.providers.LALAMOVE.status
  const dimorderStatus = deliveryOrder.providers.DIMORDER.status
  const lalamoveStep = STEPS_MAP[lalamoveStatus] ?? -1
  const dimorderStep = STEPS_MAP[dimorderStatus] ?? -1
  const latestStep = Math.max(lalamoveStep, dimorderStep)

  return (
    <TableRow roll='row'>
      <TableCell roll='cell' className={classes.cell}>
        <TitleColumn deliveryOrder={deliveryOrder} />
      </TableCell>
      <TableCell roll='cell' className={classes.cell} colSpan='7'>
        <div className={classes.container}>
          {
          _.map(labels, (label, index) => {
            const isFinalStep = index === lastIndex
            return (
              <React.Fragment key={index}>
                <ProgressStep
                  step={STEPS_MAP[label]}
                  label={label}
                  latestStep={latestStep}
                  deliveryOrder={deliveryOrder}
                />
                {!isFinalStep && (
                  <ProgressLines step={STEPS_MAP[label]} {...props} />
                )}
              </React.Fragment>
            )
          })
        }
        </div>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  row: {
    paddingLeft: 64,
    paddingRight: 64,
  },
  cell: {
    borderBottom: '2px dashed rgba(224, 224, 224, 0.4)',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 64,
    paddingRight: 64,
    paddingBottom: 16,
  },
}))
