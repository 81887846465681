import { useSelector } from '@/redux'

/**
 * @function
 * @returns {IUser[]}
 */
export function useUsers () {
  return useSelector(state => state.user.data)
}

/**
 * @function
 * @returns {IUserQueryParams}
 */
export function useQueryParams () {
  return useSelector(state => state.user.params)
}
