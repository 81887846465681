import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import React from 'react'

import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'

import CollapsibleList from '@/components/CollapsibleList'

/**
 *
 * @param {*} props
 * @returns
 */
export default function AmountSettings (props) {
  const [inputState, updateInputState] = useInputState()
  const [expand, setExpand] = React.useState(false)
  const [errors, setErrors] = useErrors()

  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
  }

  const handleAmountChange = (e) => {
    const { value } = e.target
    updateInputState('amount', value)
    updateInputState('percent', 0)
    updateInputState('maxAmount', 0)
    setErrors({ ...errors, amount: undefined, percent: undefined })
  }

  const handlePercentChange = (e) => {
    const { value } = e.target
    updateInputState('percent', value)
    updateInputState('amount', 0)
  }

  return (
    <CollapsibleList title='優惠金額' expand={expand} setExpand={setExpand}>
      {/* Amount */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Amount'
          name='amount'
          type='number'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.amount ?? 0}
          onChange={handleAmountChange}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0, max: 999 }}
          error={Boolean(errors.amount)}
        />
        <FormHelperText>折扣固定金額</FormHelperText>
      </FormControl>

      {/* Percent */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Percent'
          name='percent'
          type='number'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.percent ?? 0}
          onChange={handlePercentChange}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0, max: 100 }}
          error={Boolean(errors.percent)}
        />
        <FormHelperText>折扣百分比(20 = 20%OFF = 8折)</FormHelperText>
      </FormControl>

      {/* MaxAmount */}
      {Boolean(Number(inputState.percent)) && (
        <FormControl variant='outlined' margin='dense' fullWidth>
          <TextField
            label='Max Amount'
            name='maxAmount'
            type='number'
            variant='outlined'
            size='small'
            fullWidth
            value={inputState.maxAmount ?? 0}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            inputProps={{ min: 0 }}
            error={Boolean(errors.maxAmount)}
          />
          <FormHelperText>使用 percent 時可設定折扣上限</FormHelperText>
        </FormControl>
      )}

      {/* Min Order Amount */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <TextField
          label='Min Order Amount'
          name='minOrderAmount'
          type='number'
          variant='outlined'
          size='small'
          fullWidth
          value={inputState.minOrderAmount ?? 0}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          inputProps={{ min: 0 }}
          error={Boolean(errors.minOrderAmount)}
        />
        <FormHelperText>最低消費金額 (order.total)</FormHelperText>
      </FormControl>
    </CollapsibleList>
  )
}
