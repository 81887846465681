import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'

import { setMobileDrawerOpen } from '../redux/app/actions'
import ChangePasswordDialog from '@/pages/User/ChangePasswordDialog'
import hooks from '@/hooks'
import useMediaQuery from '@material-ui/core/useMediaQuery'
export default function AppHeader (props) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(props)
  const history = useHistory()
  const isLoggedIn = hooks.auth.useLoggedIn()
  const user = hooks.auth.useUser()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const logout = () => {
    if (isLoggedIn) {
      dispatch(actions.auth.signout())
      return
    }
    history.push('/signin')
  }
  const handleChangePassword = () => {
    dispatch(actions.app.toggleDialog('changePassword', null, user))
    handleClose()
  }

  return (
    <div className={classes.appHeader}>
      {isLoggedIn && (
        <>
          {/* <div className={classes.appHeaderContent}> */}
          <div className={classes.menuButtonDiv}>
            {
              isMobile &&
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
              // edge='start'
                  onClick={() => { dispatch(setMobileDrawerOpen(true)) }}
                  className={classes.menuButton}
                >
                  <MenuIcon fontSize='large' />
                </IconButton>
            }
          </div>
          <div className={classes.barRightDiv}>
            <Typography>
              Hello, {user.name}
            </Typography>
            <IconButton onClick={handleMenu}>
              <SettingsOutlinedIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
              <MenuItem onClick={logout}>Log out</MenuItem>
            </Menu>
          </div>
          {/* </div> */}
          <ChangePasswordDialog />
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  appHeader: {
    // height: constants.app.height.NAV_HEADER,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    padding: theme.spacing(0.5),
  },
  menuButtonDiv: {

  },
  menuButton: {
    padding: theme.spacing(1),
  },
  // appHeaderContent:{

  // }
  barRightDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flex: 1,
  },
}))
