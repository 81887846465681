import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import ControlledTable from '@/components/Table/ControlledTable'

const channelConvert = {
  push: 'Notification',
  sms: 'SMS',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function SMSRecordTable (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const jobs = useSelector(state => state.sms.records.data)
  const meta = useSelector(state => state.sms.records.meta)
  const params = useSelector(state => state.sms.records.params)

  /**
   *
   * @param {ISMSRecord} smsRecord
   * @returns
   */
  const targetConvert = ({ recipients, conditions }) => {
    if (recipients > 0) return 'Users list'

    const { amount } = conditions
    if (amount === undefined) return 'All user'

    const { gt, lt, eq } = amount
    if (eq !== undefined) return 'Non-active users'
    if (gt !== undefined) {
      if (gt === 0) return 'All active users'
      return 'Active users of purchased over then HK$ ' + gt
    }
    if (lt !== undefined) return 'Active users of purchased less then HK$ ' + lt
    return 'Unknow'
  }

  /**
   *
   * @param {ISMSRecord} smsRecord
   * @returns
   */
  const targetQuantity = ({ body, delivered, dropped }) => {
    return Math.ceil(body.length / 170) * (delivered + dropped)
  }

  /**
   *
   * @param {ISMSRecord} smsRecord
   * @returns
   */
  const SMSStatus = ({ id, delivered, dropped }) => {
    return (
      <ul>
        <li>Deliverd: <span className={classes.hover} onClick={() => getSMSResult(id, 'fulfilled')}>{delivered}</span></li>
        <li>Not delivered: <span className={classes.hover} onClick={() => getSMSResult(id, 'rejected')}>{dropped}</span></li>
      </ul>
    )
  }

  /**
   *
   * @param {string} id
   * @param {'fulfilled' | 'rejected'} type
   */
  const getSMSResult = (id, type) => {
    dispatch(actions.sms.getSMSResult({ id, type }))
  }

  const columns = [
    {
      // 發送的建立時間
      accessor: 'createdAt',
      Header: 'Datetime',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      // 發送對象的條件
      Header: 'Target',
      accessor: row => targetConvert(row),
    },
    {
      // 發送的方式
      accessor: 'channel',
      Header: 'Channel',
      Cell: ({ value }) => channelConvert[value],
    },
    {
      // 發送的內容
      accessor: 'body',
      Header: 'Content',
      Cell: ({ value }) => <Typography className={classes.content}>{value}</Typography>,
    },
    {
      accessor: 'data',
      Header: 'Action',
      Cell: ({ value }) => {
        const fallback = 'NONE'
        if (typeof value?.payload === 'string') {
          if (value.payload === '') return fallback // 避免空字串造成 JSON.parse crash
          /** @type {IMarketingAction} */
          const payload = JSON.parse(value.payload)
          return payload?.type ?? fallback
        }
        return fallback
      },
    },
    {
      accessor: 'data.payload',
      Header: 'Payload',
      Cell: ({ value }) => {
        const fallback = ''
        if (typeof value === 'string') {
          if (value === '') return fallback // 避免空字串造成 JSON.parse crash
          /** @type {IMarketingAction} */
          const payload = JSON.parse(value)
          return payload?.payload ?? fallback
        }
        return fallback
      },
    },
    {
      // 總發送數
      accessor: row => targetQuantity(row),
      Header: 'Quantity',
    },
    {
      // 發送人的名字
      accessor: 'admin',
      Header: 'Admin',
    },
    {
      // 發送的總金額
      accessor: 'price',
      Header: 'Price',
    },
    {
      // 發送成功的人數 & 發送失敗的人數
      accessor: row => SMSStatus(row),
      Header: 'Status',
    },
  ]

  React.useEffect(() => {
    dispatch(actions.sms.getSMSRecord())
  }, [])

  const onPageChange = async (event, page) => {
    await dispatch(actions.sms.updateSMSQuery({ ...params, page: page + 1 }))
    await dispatch(actions.sms.getSMSRecord())
  }

  return (
    <ControlledTable
      columns={columns}
      data={jobs}
      pageSize={meta.perPage}
      currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
      rowCount={meta.total}
      onPageChange={onPageChange}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  hover: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1675e0',
    },
  },
  content: {
    width: 300,
    wordBreak: 'break-all',
  },
}))
