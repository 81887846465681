import { Button } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React from 'react'

export default function AlertDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open, data } = useDialog('alertDialog')
  const title = data.title || 'Alert'
  const content = data.content || 'Alert'
  const handleSubmit = () => {
    if (data.confirmCallback) {
      data.confirmCallback()
    }
    handleClose()
  }

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('alertDialog', false, data))
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={title}
    >
      <div className={classes.form}>
        <div className={classes.content}>{content}</div>
        <div className='d-flex justify-center'>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSubmit}
          >
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: '75px auto',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    alignItems: 'center',
    minWidth: '250px',
  },
  content: {
    whiteSpace: 'pre',
  },
}))
