import { AiFillSetting, AiOutlineLink } from 'react-icons/ai'
import { FaMotorcycle, FaSms, FaUserFriends, FaUsers } from 'react-icons/fa'
import { GiCardboardBox, GiFullMotorcycleHelmet } from 'react-icons/gi'
import { HiDocumentReport } from 'react-icons/hi'
import { IoPricetagOutline, IoReceipt, IoStorefrontSharp } from 'react-icons/io5'
import { MdAttachMoney, MdDashboard } from 'react-icons/md'
import { ReactComponent as RcIcon } from '@/assets/images/rcIconAdmin.svg'
// import { RiCopperCoinLine, RiRefund2Fill } from 'react-icons/ri'
import Customer from '@/pages/Customer'
import Dashboard from '@/pages/Dashboard'
import DeliveryOrder from '@/pages/DeliveryOrder'
import Dimbox from '@/pages/Dimbox'
import Driver from '@/pages/Driver'
import Home from '@/pages/Home'
import Links from '@/pages/Links'
import Merchant from '@/pages/Merchant'
import NewReport from '@/pages/Report/New'
import NewSMS from '@/pages/SMS/New'
import Order from '@/pages/Order'
import PosPayLink from '@/pages/PosPayLink'
import Promotoin from '@/pages/Promotion'
import React from 'react'
import Report from '@/pages/Report'
import ReportDetail from '@/pages/Report/detail'
import RiceCoin from '@/pages/RiceCoin'
import SMS from '@/pages/SMS'
import Setting from '@/pages/Setting'
import SignIn from '@/pages/SignIn'
import User from '@/pages/User'

export const routes = [
  {
    path: '/',
    exact: true,
    showInNav: true,
    redirect: '/home',
  },
  {
    path: '/signin',
    component: SignIn,
    exact: true,
    showInNav: true,
    private: false,
  },
  {
    path: '/home',
    component: Home,
    exact: true,
    showInNav: true,
    private: true,
  },
  {
    path: '/dashboard',
    menuName: 'Dashboard',
    component: Dashboard,
    icon: MdDashboard,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING', 'RICECOIN_ADMIN'],
  },
  {
    path: '/ricecoin',
    menuName: 'RiceCoin',
    component: RiceCoin,
    icon: () => <RcIcon />,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'MARKETING', 'RICECOIN_ADMIN'],
  },
  {
    path: '/merchant',
    menuName: 'Merchant',
    component: Merchant,
    icon: IoStorefrontSharp,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING'],
  },
  {
    path: '/customer',
    menuName: 'Customer',
    component: Customer,
    icon: FaUsers,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING'],
  },
  {
    path: '/order',
    menuName: 'Order Record',
    component: Order,
    icon: IoReceipt,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING', 'RICECOIN_ADMIN'],
  },
  // {
  //   path: '/refund',
  //   menuName: 'Refund',
  //   component: Refund,
  //   icon: RiRefund2Fill,
  //   exact: true,
  // showInNav:true,
  //   private: true,
  //   roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING', 'RICECOIN_ADMIN'],
  // },
  {
    path: '/dimbox',
    menuName: 'DimBox Order',
    component: Dimbox,
    icon: GiCardboardBox,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING', 'DIMBOX_MANAGER'],
  },
  {
    path: '/delivery_order',
    menuName: 'Delivery Order',
    component: DeliveryOrder,
    icon: FaMotorcycle,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING'],
  },
  {
    path: '/driver',
    menuName: 'Driver',
    component: Driver,
    icon: GiFullMotorcycleHelmet,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING'],
  },
  {
    path: '/promotion',
    menuName: 'Promotion',
    component: Promotoin,
    icon: IoPricetagOutline,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'ADMINISTRATOR', 'MARKETING', 'RICECOIN_ADMIN'],
  },
  {
    path: '/SMS',
    menuName: 'Notification',
    component: SMS,
    icon: FaSms,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'MARKETING'],
  },
  {
    path: '/SMS/new',
    menuName: 'NewSMS',
    component: NewSMS,
    exact: false,
    private: true,
    roles: ['SUPER_ADMIN', 'MARKETING'],
  },
  {
    path: '/report',
    menuName: 'Report',
    component: Report,
    icon: HiDocumentReport,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'FINANCING'],
  },
  {
    path: '/pos-pay-link',
    menuName: 'Pay Link',
    component: PosPayLink,
    icon: MdAttachMoney,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'FINANCING', 'SALES'],
  },
  {
    path: '/report/new',
    menuName: 'NewReport',
    component: NewReport,
    exact: false,
    private: true,
    roles: ['SUPER_ADMIN', 'FINANCING'],
  },
  {
    path: '/report/:jobId',
    menuName: 'ReportDetail',
    component: ReportDetail,
    exact: false,
    private: true,
    roles: ['SUPER_ADMIN', 'FINANCING'],
  },
  {
    path: '/user',
    menuName: 'User',
    component: User,
    icon: FaUserFriends,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN'],
  },
  {
    path: '/setting',
    menuName: 'Setting',
    component: Setting,
    icon: AiFillSetting,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN', 'SYSTEM_CONFIG_MANAGER'],
  },
  {
    path: '/links',
    menuName: 'Links',
    component: Links,
    icon: AiOutlineLink,
    exact: true,
    showInNav: true,
    private: true,
    roles: ['SUPER_ADMIN'],
  },
]
