import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'

import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'

import CollapsibleList from '@/components/CollapsibleList'
import MerchantSearch from '@/components/MerchantSearch'

/**
 *
 * @param {*} props
 * @returns
 */
export default function BasicSettings (props) {
  const [expand, setExpand] = React.useState(true)
  const [inputState, updateInputState] = useInputState()
  const [merchant, setMerchant] = React.useState()
  const creating = !inputState.id
  const [errors, setErrors] = useErrors()

  const handleChange = (e) => {
    const { name, value } = e.target
    updateInputState(name, value)
    setErrors({ ...errors, [name]: undefined })
  }

  const handleTypeChange = (e) => {
    handleChange(e)
    setErrors({})
  }

  return (
    <>
      {/* Type */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <InputLabel margin='dense'>Type</InputLabel>
        <Select
          label='Type'
          name='type'
          variant='outlined'
          fullWidth
          value={inputState.type ?? ''}
          onChange={handleTypeChange}
        >
          <MenuItem value='PROMOCODE'>PROMOCODE</MenuItem>
          <MenuItem value='COUPON'>COUPON</MenuItem>
        </Select>
      </FormControl>

      <CollapsibleList title='基本設定' expand={expand} setExpand={setExpand}>
        {/* Code */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <TextField
            label='Code'
            name='code'
            type='text'
            variant='outlined'
            size='small'
            fullWidth
            value={inputState.code ?? ''}
            onChange={handleChange}
            disabled={!creating} // 不可編輯
            error={Boolean(errors.code)}
          />
        </FormControl>

        {/* Offered By */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <InputLabel margin='dense'>Offered By</InputLabel>
          <Select
            label='Offered By'
            name='offeredBy'
            variant='outlined'
            fullWidth
            value={inputState.offeredBy ?? 'Merchant'}
            onChange={handleChange}
            disabled={!creating}
          >
            <MenuItem value='DimOrder'>DimOrder</MenuItem>
            <MenuItem value='Merchant'>Merchant</MenuItem>
          </Select>
          <FormHelperText>最後誰要付這筆錢</FormHelperText>
        </FormControl>

        {/* Apply To */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <InputLabel margin='dense'>Apply To</InputLabel>
          <Select
            label='Apply To'
            name='applyTo'
            variant='outlined'
            fullWidth
            value={inputState.applyTo ?? 'ALL'}
            onChange={handleChange}
            disabled={!creating}
          >
            <MenuItem value='ALL'>ALL</MenuItem>
            <MenuItem value='PRODUCT'>PRODUCT</MenuItem>
            <MenuItem value='SHIPPING'>SHIPPING</MenuItem>
          </Select>
          <FormHelperText>折扣到哪裡</FormHelperText>
        </FormControl>

        {/* Delivery Type */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <InputLabel margin='dense'>Delivery Type</InputLabel>
          <Select
            label='DeliveryType'
            name='deliveryType'
            variant='outlined'
            fullWidth
            value={inputState.deliveryType ?? 'ALL'}
            onChange={handleChange}
          >
            <MenuItem value='ALL'>ALL</MenuItem>
            <MenuItem value='takeaway'>TAKEAWAY</MenuItem>
            <MenuItem value='storeDelivery'>STORE DELIVERY</MenuItem>
            <MenuItem value='table'>TABLE</MenuItem>
          </Select>
          <FormHelperText>限制 deliveryType</FormHelperText>
        </FormControl>

        {/* Merchant Name */}
        {creating
          ? (
            <MerchantSearch
              helperText='限制特定餐廳使用'
              onChange={(o) => {
                setMerchant(o)
                updateInputState('merchantId', o?.id)
                updateInputState('merchantName', o?.name)
              }}
              value={merchant}
            />
            )
          : (
            <FormControl variant='outlined' margin='dense' fullWidth>
              <InputLabel margin='dense'>Merchant Name</InputLabel>
              <Select
                disabled
                label='MerchantName'
                name='merchantName'
                variant='outlined'
                fullWidth
                value={inputState.merchantName}
              >
                <MenuItem value={inputState.merchantName}>{inputState.merchantName}</MenuItem>
              </Select>
              <FormHelperText>限制特定餐廳使用</FormHelperText>
            </FormControl>
            )}

        {/* Only new user */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <InputLabel margin='dense'>Only New User</InputLabel>
          <Select
            label='claimNewUser'
            name='claimNewUser'
            variant='outlined'
            fullWidth
            value={inputState.claimNewUser ?? false}
            onChange={handleChange}
          >
            <MenuItem value={false}>FALSE</MenuItem>
            <MenuItem value>TRUE</MenuItem>
          </Select>
          <FormHelperText>限制新用戶才能領取</FormHelperText>
        </FormControl>

        {/* Only registered user */}
        <FormControl variant='outlined' margin='dense' fullWidth>
          <InputLabel margin='dense'>Only Registered User</InputLabel>
          <Select
            label='onlyRegisteredUser'
            name='onlyRegisteredUser'
            variant='outlined'
            fullWidth
            value={inputState.onlyRegisteredUser ?? false}
            onChange={handleChange}
          >
            <MenuItem value={false}>FALSE</MenuItem>
            <MenuItem value>TRUE</MenuItem>
          </Select>
          <FormHelperText>限制用戶才能使用</FormHelperText>
        </FormControl>
      </CollapsibleList>
    </>
  )
}
