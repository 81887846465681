import { IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import React from 'react'

export default function AddIconButton (props) {
  return (
    <IconButton {...props}>
      <AddCircleIcon />
    </IconButton>
  )
}
