import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import MerchantSearch from '@/components/MerchantSearch'
import React from 'react'
import TableSearchForm from '@/components/Table/TableSearchForm'

/** @type {IPromoCodeQueryParams} */
const defaultState = {
  type: '',
  merchantId: '',
  status: '',
  name: '',
  promocode: '',
  limit: 10,
  skip: 0,
}

/**
 * 1. 輸入優惠碼查詢
 * 2. 選擇店家查詢
 * @param {*} props
 * @returns
 */
export default function PromotionSearchForm (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [inputState, setInputState] = React.useState(defaultState)
  const [merchant, setMerchant] = React.useState()

  const handleSearch = async () => {
    dispatch(actions.promoCode.updateQuery({
      ...inputState,
      skip: 0,
      type: inputState.type === '*' ? undefined : inputState.type,
      merchantId: inputState.merchantId === '*' ? undefined : inputState.merchantId,
      status: inputState.status === '*' ? undefined : inputState.status,
    }))
    dispatch(actions.promoCode.getPromoCodes())
  }

  const handleClear = () => {
    setInputState(defaultState)
    dispatch(actions.promoCode.updateQuery({ ...defaultState, merchantId: undefined }))
    dispatch(actions.promoCode.getPromoCodes())
  }

  const handleChange = (e) => {
    setInputState({ ...inputState, promocode: e.target.value })
  }

  return (
    <TableSearchForm
      handleSearch={handleSearch}
      handleClear={handleClear}
    >
      {/* 選擇 Type 查詢 */}
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel margin='dense'>Type</InputLabel>
        <Select
          className={classes.selectControl}
          value={inputState.type}
          onChange={(event) => {
            setInputState({ ...inputState, type: event.target.value })
          }}
          label='Type'
          name='type'
        >
          <MenuItem value='*'>All Type</MenuItem>
          <MenuItem key='PROMOCODE' value='PROMOCODE'>PROMO CODE</MenuItem>
          <MenuItem key='COUPON' value='COUPON'>COUPON</MenuItem>
        </Select>
      </FormControl>

      {/* 輸入名稱查詢 */}
      <TextField
        label='Name'
        name='name'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.name}
        onChange={(event) => {
          setInputState({ ...inputState, name: event.target.value })
        }}
      />

      {/* 輸入優惠碼查詢 */}
      <TextField
        label='Code'
        name='promocode'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.promocode}
        onChange={handleChange}
      />

      {/* 選擇店家查詢 */}
      <MerchantSearch
        onChange={(o) => {
          setMerchant(o)
          setInputState({ ...inputState, merchantId: o?.id })
        }}
        value={merchant}
        formControlProps={{ style: { margin: 0 } }}
      />

      {/* 選擇 Status 查詢 */}
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel margin='dense'>Status</InputLabel>
        <Select
          className={classes.selectControl}
          value={inputState.status}
          onChange={(event) => {
            setInputState({ ...inputState, status: event.target.value })
          }}
          label='Status'
          name='status'
        >
          <MenuItem value='*'>All Promotion</MenuItem>
          <MenuItem key='valid' value='valid'>Valid</MenuItem>
          <MenuItem key='invalid' value='invalid'>Invalid</MenuItem>
          <MenuItem key='expired' value='expired'>Expired</MenuItem>
        </Select>
      </FormControl>
    </TableSearchForm>
  )
}

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiInputBase-formControl': {
      background: theme.palette.common.white,
    },
  },
  formControl: {
    // background: theme.palette.common.white,
  },
  selectControl: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  fileds: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: theme.spacing(1),
  },
}))
