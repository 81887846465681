import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from '@/redux'
import PROVIDER_COLORS from './providerColors'
import React from 'react'
import STEPS_MAP from './stepsMap'
import _ from 'lodash'
import cx from 'classnames'

/**
 *
 * @param {{
 * step: number
 * provider: TDeliveryProvider
 * deliveryOrder: IDeliveryOrder
 * setLalamoveOprationDialogOpen: () => void
 * setSelectedOrderId: () => void
 * }} props
 * @returns
 */
export default function ProgressLine (props) {
  const { step, provider, deliveryOrder, setLalamoveOprationDialogOpen, setSelectedOrderId } = props
  const drivers = useSelector(state => state.driver.data)
  const [hover, setHover] = React.useState(false)

  const providerData = deliveryOrder.providers[provider]
  const providerStatus = providerData?.status
  const providerStep = STEPS_MAP[providerStatus] ?? -1
  const providerColor = PROVIDER_COLORS[provider]
  const classes = useStyles({ ...props, providerColor, hover })
  const providerOrderId = providerData?.orderId
  const providerDriverId = providerData?.driverId
  const providerDriver = React.useMemo(() => {
    if (provider === 'DIMORDER') {
      const driverName = _.find(drivers, driver => driver.id === providerDriverId)?.name ?? ''
      return driverName
    }
    return providerDriverId
  }, [provider, providerDriverId, drivers])

  const ProgressDetail = React.useMemo(() => (
    <div className={classes.progressDetail}>
      <Typography variant='body2'>{provider}</Typography>
      <Typography variant='body2'>Status: {providerStatus}</Typography>
      {Boolean(providerOrderId) && <Typography variant='body2'>OrderId: {providerOrderId}</Typography>}
      {Boolean(providerDriver) && <Typography variant='body2'>Driver: {providerDriver}</Typography>}
    </div>
  ), [provider, providerStatus, providerOrderId, providerDriver])

  const handleClick = () => {
    if (provider === 'LALAMOVE') {
      setLalamoveOprationDialogOpen(true)
      setSelectedOrderId(deliveryOrder.id)
    }
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.lineWrap}
        onClick={handleClick}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <div className={cx(
          classes.line,
          {
            [classes.progressing]: providerStep === step,
            [classes.active]: providerStep >= step,
          })}
        />
      </div>
      {ProgressDetail}
    </div>
  )
}

const useStyles = makeStyles((theme, props) => ({
  container: {
    position: 'relative',
  },
  lineWrap: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    cursor: 'pointer',
  },
  line: {
    borderTopWidth: 3,
    borderTopStyle: 'solid',
    borderTopColor: '#e0e0e0',
  },
  progressing: {
    borderTopStyle: 'dotted !important',
  },
  active: {
    borderTopColor: props => `${props.providerColor} !important`,
  },
  progressDetail: {
    transition: 'opacity 0.15s',
    zIndex: 1,
    position: 'absolute',
    top: 32,
    left: 16,
    borderRadius: 4,
    padding: 8,
    backgroundColor: props => props.providerColor,
    color: '#f1f1f1',
    opacity: props => props.hover ? 1 : 0,
  },
}))
