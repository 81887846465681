import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 *
 * @param {{
 * title: string
 * onClick: () => void
 * pageIndex: number
 * }} props
 * @returns
 */
export default function ChangePageButton (props) {
  const { title, onClick: handleClick, pageIndex } = props
  const classes = useStyles(props)

  return (
    <Button variant='contained' color={pageIndex ? 'secondary' : 'primary'} className={classes.button} onClick={handleClick}>{title}</Button>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    // backgroundColor: '#777777',
    // color: '#fafafa',
    '&:hover': {
      // backgroundColor: '#666666',
    },
  },
}))
