// import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { Button, Checkbox, FormControlLabel, FormGroup, Table as MuiTable, TableBody as MuiTableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core'
import { useSelector } from 'react-redux'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'

/**
 *
 * @param {{
 * open: boolean
 * handleClose: () => void
 * }} props
 * @returns
 */
export default function TagEditor (props) {
  const { open, handleClose } = props
  const dispatch = useDispatch()
  const tagList = useSelector(state => state.customer.tags.data)
  const [tags, setTags] = React.useState([])
  const [showTags, setShowTags] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(0)
  const pageSize = 10

  React.useEffect(() => {
    if (tagList.length === 0) {
      dispatch(actions.customer.getTags())
    }
  }, [])

  React.useEffect(() => {
    setTags(tagList.map(tag => {
      return {
        ...tag,
        checked: false,
      }
    }))
  }, [tagList])

  React.useEffect(() => {
    setShowTags(tags.slice(currentPage * pageSize, (currentPage + 1) * pageSize))
  }, [tags])

  React.useEffect(() => {
    setShowTags(tags.slice(currentPage * pageSize, (currentPage + 1) * pageSize))
  }, [currentPage])

  const onPageChange = (event, page) => {
    setCurrentPage(page)
  }

  const handleDelete = () => {
    const deleteTags = tags.filter(tag => tag.checked)
    dispatch(actions.app.toggleDialog('confirmDialog', null, {
      content: 'Are you sure to delete those tags?' + deleteTags.map(tag => tag.name).join(', '),
      confirmCallback: async () => {
        const results = deleteTags.map(tag => {
          return dispatch(actions.customer.deleteTag(tag.id))
        })
        await Promise.all(results)
        await dispatch(actions.customer.getTags())
      },
    }))
  }

  const handleClick = (event, tag) => {
    tag.checked = event.target.checked
    setTags([...tags])
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title='Tags editor'
      renderActions={() => {
        return (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleClose}
          >
            OK
          </Button>
        )
      }}
    >
      <div className='ml-4 mt-4'>
        <Button
          size='small'
          color='primary'
          variant='outlined'
          onClick={handleDelete}
        >
          <DeleteIcon fontSize='small' />
        </Button>
        <MuiTable>
          <MuiTableBody>
            <TableRow>
              <TableCell>
                <FormGroup>
                  {_.map(showTags, tag =>
                    <FormControlLabel
                      key={tag.id}
                      control={
                        <Checkbox
                          color='primary'
                          value={tag.id}
                          checked={tag.checked}
                          onClick={(event) => handleClick(event, tag)}
                        />
                      }
                      label={tag.name}
                    />,
                  )}
                </FormGroup>
              </TableCell>
            </TableRow>
          </MuiTableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={tags.length}
                rowsPerPage={pageSize}
                page={currentPage}
                onPageChange={onPageChange}
              />
            </TableRow>
          </TableFooter>
        </MuiTable>
      </div>
    </Dialog>
  )
}
