import ActionTypes from './ActionTypes'
import customerApi from '@/libs/api/customer'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getStatistics (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getStatistics' },
    })
    try {
      const response = await customerApi.getStatistics(params)
      const { data } = response

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
    } catch (error) {
      console.log('getStatistics error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getStatistics' },
      })
    }
  }
}
/**
 * @function
 * @returns {ThunkFunction}
 */
export function getRegisteredUsers (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getRegisteredUsers' },
    })
    try {
      const response = await customerApi.getRegisteredUsers(params)
      const { data } = response

      dispatch({
        type: ActionTypes.UPDATE_REGISTERED_USERS,
        payload: { data },
      })
    } catch (error) {
      console.log('getRegisteredUsers error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getRegisteredUsers' },
      })
    }
  }
}
/**
 * @function
 * @returns {ThunkFunction}
 */
export function getActivatedUsers (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getActivatedUsers' },
    })
    try {
      const response = await customerApi.getActivatedUsers(params)
      const { data } = response

      dispatch({
        type: ActionTypes.UPDATE_ACTIVATED_USERS,
        payload: { data },
      })
    } catch (error) {
      console.log('getActivatedUsers error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getActivatedUsers' },
      })
    }
  }
}
/**
 * @param {{userId: string}} params
 * @function
 * @returns {ThunkFunction}
 */
export function getDeliveryTypes (params) {
  return async (dispatch, getState) => {
    try {
      const response = await customerApi.getDeliveryTypes(params)
      const { data } = response
      return data
    } catch (error) {
      console.log('getDeliveryTypes error', error)
    }
  }
}
