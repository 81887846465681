import { useSelector } from '@/redux'

/**
 * @function
 * @returns {IPosPayLink[]}
 */
export function usePosPayLinks () {
  return useSelector(state => state.posPayLink.data)
}

/**
 * @function
 * @returns {IPosPayLinkQueryParams}
 */
export function useQueryParams () {
  return useSelector(state => state.posPayLink.params)
}
