import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

PageHeader.prototypes = {
  text: PropTypes.string,
  children: PropTypes.node,
}

export default function PageHeader (props) {
  const classes = useStyles(props)
  const { text, children } = props

  return (
    <div className={classes.pageHeader}>
      <Typography className={classes.title} variant='h5' component='h2'>
        {text}
      </Typography>
      {children && children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  pageHeader: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flex: 0,
  },
  title: {
    flex: 1,
  },
}))
