import _ from 'lodash'

export function boxesIdReducer (boxes) {
  const boxIds = _.reduce(boxes, (acc, box) => {
    acc.push(box.boxId)
    return acc
  }, [])
  return boxIds
}

export function itemsReducer (batches) {
  // 把 order.batches 內的 item 壓縮成只有名字的 array
  // 如有同一個 item 有兩個就重複名字兩次，以此類推
  const orderItemNames = _.reduce(batches, (acc, batch) => {
    const names = _.reduce(batch.items, (acc, item) => {
      if (!item?.name) return acc
      for (let i = 0; i < item.quantity; i++) {
        acc.push(item.name)
      }
      return acc
    }, [])
    acc.push(...names)
    return acc
  }, [])

  const counts = _.countBy(orderItemNames)
  const uniques = _.uniq(orderItemNames)
  const itemNameWithCounts = _.map(uniques, itemName => itemName + ' x ' + counts[itemName])
  return itemNameWithCounts
}
