import { CircularProgress } from '@material-ui/core'
import { Toaster } from 'react-hot-toast'
import { actions, useDispatch, useSelector } from '@/redux'
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles'
import { auth } from '@/libs/firebase'
import { useHistory } from 'react-router-dom'
import AddRefundDialog from './Refund/AddRefundDialog'
import AlertDialog from '@/components/Dialog/AlertDialog'
import AppHeader from '@/components/AppHeader'
import AppRouter from '@/pages/AppRouter'
import ConfirmDialog from '@/components/Dialog/ConfirmDialog'
import NavDrawer from '@/components/NavDrawer'
import OrderCancelDialog from '../components/Dialog/OrderCancelDialog'
import React from 'react'
import constants from '@/constants'
import hooks from '@/hooks'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function Main (props) {
  const dispatch = useDispatch()
  const isLoggedIn = hooks.auth.useLoggedIn()
  const drawerOpen = useSelector(state => state.app.drawerOpen)
  const classes = useStyles({ ...props, isLoggedIn, drawerOpen })
  const history = useHistory()
  const [loadingUser, setLoadingUser] = React.useState(true)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  React.useEffect(() => {
    if (isDesktop) {
      dispatch(actions.app.setMobileDrawerOpen(false))
    }
    dispatch(actions.app.setDrawerOpen(isDesktop))
  }, [isDesktop])

  React.useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setLoadingUser(false)
      if (user) {
        await dispatch(actions.auth.signinWithUser(user))
        if (window.location.pathname === '/signin') {
          history.push('/ricecoin')
        }
      } else {
        history.push('/signin')
        dispatch(actions.auth.signout())
      }
    })

    dispatch(actions.auth.init())
    dispatch(actions.driver.getDrivers())
    dispatch(actions.deliveryOrder.getDeliveryOrders({
      from: moment().startOf('day').toISOString(),
      to: moment().endOf('day').toISOString(),
      serial: '',
    }))
  }, [])

  if (loadingUser) {
    return (
      <div className={classes.fullScreenLoading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {isLoggedIn &&
        <NavDrawer />}
      <main className={classes.main}>
        <AppHeader />
        <div className={classes.content}>
          <AppRouter />
        </div>
        {/* dialog */}
        <AlertDialog />
        <ConfirmDialog />
        <AddRefundDialog />
        <OrderCancelDialog />
      </main>
      {/* toast */}
      <Toaster />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  fullScreenLoading: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    // flexDirection: 'column',
  },
  nav: {
    width: constants.app.width.NAV_DRAWER,
  },
  main: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: ({ isLoggedIn, drawerOpen }) => {
      return 0
    },
    flex: 1,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  content: {
    padding: theme.spacing(0, 2, 1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}))
