import _ from 'lodash'
import firebase from 'firebase/app'
import moment from 'moment'
import 'firebase/firestore'

import { exportXlsx } from '@/libs/xlsx'
import { toggleDialog } from '@/redux/app/actions'
import deliveryApi from '@/libs/api/delivery'
import goAdminApi from '@/libs/api/goAdmin'

import ActionTypes from './ActionTypes'

const firestore = firebase.firestore()
let unsub = () => { }
const initialProvider = 'DIMORDER'
const initialProviders = {
  DIMORDER: {
    status: 'ASSIGNING_DRIVER',
    driverId: '',
  },
  LALAMOVE: {
    status: 'PENDING',
    orderId: '',
  },
}

/**
 *
 * @returns {ThunkFunction}
 */
export function updateMeta (data) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_META,
      payload: { meta: { ...data } },
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function unsubscribe () {
  return (dispatch, getState) => {
    unsub()
  }
}

/**
 * @function
 * @param {IGetDeliveryOrdersParams} params
 * @returns {ThunkFunction}
 */
export function getDeliveryOrders (params) {
  return async (dispatch, getState) => {
    const { from, to, serial, code } = params

    let query = firestore.collection('deliveryOrders')
    if (from) { query = query.where('start.timeEstimated', '>=', from) }
    if (to) { query = query.where('start.timeEstimated', '<=', to) }
    if (serial) { query = query.where('orderSerial', '==', serial) }

    try {
      unsub()
      unsub = query.onSnapshot((collection) => {
        const deliveryOrders = _
          .chain(collection.docs)
          .map(
            doc => {
              // const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
              // console.log(source, ' data: ', data)
              const data = doc.data()
              return data
            })
          .orderBy(o => o.createdAt, 'desc')
          .filter(o => code ? o.code.includes(code.toUpperCase()) : true)
          .value()

        dispatch({
          type: ActionTypes.UPDATE_DATA,
          payload: { data: deliveryOrders },
        })

        dispatch({
          type: ActionTypes.UPDATE_META,
          payload: { meta: { total: _.size(deliveryOrders) } },
        })
      })
    } catch (error) {
      console.error('getDeliveryOrders error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function copyDeliveryOrder (id) {
  return async (dispatch, getState) => {
    try {
      const ref = firestore.collection('deliveryOrders').doc()
      const orders = getState().deliveryOrder.data
      const order = _.find(orders, order => order.id === id)
      const createdAt = moment().toISOString()
      const duplicateOrders = _.reduce(orders, (acc, o) => o.orderSerial === order.orderSerial ? acc + 1 : acc, 0)
      const breakBulkSerial = duplicateOrders + 1

      if (!order.breakBulkSerial) {
        // 第一次 copy 訂單 0 => 1
        dispatch(updateDeliveryOrder(order.id, { breakBulkSerial: 1 }))
      }

      const clone = _
        .chain(order)
        .clone()
        .set('id', ref.id)
        .set('provider', initialProvider)
        .set('providers', initialProviders)
        .set(['providers', 'DIMORDER', 'updatedAt'], createdAt)
        .set(['providers', 'LALAMOVE', 'updatedAt'], createdAt)
        .set('createdAt', createdAt)
        .set('breakBulkSerial', breakBulkSerial)
        .value()
      ref.set(clone)

      await goAdminApi.copyDeliveryOrder(clone.id, clone.orderId)
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: copyDeliveryOrder', content: error.message }))
      console.error('copyDeliveryOrder error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @param {object} data
 * @returns {ThunkFunction}
 */
export function updateDeliveryOrder (id, data) {
  return async (dispatch, getState) => {
    try {
      const result = await deliveryApi.updateDeliveryOrder(id, data)
      return result
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: updateDeliveryOrder', content: error.message }))
      console.error('updateDeliveryOrder error', error)
    }
  }
}

/**
 * @param {string} orderId
 * @param {string} driverId
 * @returns {ThunkFunction}
 */
export function assignDriver (orderId, driverId) {
  return async (dispatch, getState) => {
    try {
      const result = await deliveryApi.assignDriver(orderId, driverId)
      return result
    } catch (error) {
      console.error('assignDriver error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function cancelDeliveryOrder (id) {
  return async (dispatch, getState) => {
    try {
      const result = await deliveryApi.cancelDeliveryOrder(id)
      return result
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: cancelDeliveryOrder', content: error.message }))
      console.error('deleteDeliveryOrder error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @param {string} serviceType
 * @returns {IDeliveryOrder}
 */
export function placeLalamoveOrder (id, serviceType) {
  return async (dispatch, getState) => {
    try {
      const orderRef = await deliveryApi.placeLalamoveOrder(id, serviceType)
      return orderRef
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: placeLalamoveOrder', content: error.message }))
      console.error('placeLalamoveOrder error', error)
    }
  }
}

/**
 * @function
 * @param {string} id LalamoveOrder.id
 * @returns
 */
export function cancelLalamoveDelivery (id) {
  return async (dispatch, getState) => {
    try {
      await deliveryApi.cancelLalamoveOrder(id)
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: cancelLalamoveOrder', content: error.message }))
      console.error('cancelLalamoveOrder error', error)
    }
  }
}

/**
 * @function
 * @param {string} id DeliveryOrder.id
 * @returns
 */
export function cancelDimorderDelivery (id) {
  return async (dispatch, getState) => {
    try {
      dispatch(updateDeliveryOrder(id, {
        'providers.DIMORDER.status': 'CANCELED',
        'providers.DIMORDER.updatedAt': moment().toISOString(),
        provider: 'LALAMOVE',
        driverId: '',
      }))
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: cancelDimorderDelivery', content: error.message }))
      console.error('cancelDimorderDelivery error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function removeDeliveryOrder (id) {
  return (dispatch, getState) => {
    try {
      firestore.collection('deliveryOrders').doc(id).delete()
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: removeDeliveryOrder', content: error.message }))
      console.error('removeDeliveryOrder error', error)
    }
  }
}

/**
 * @function
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function redirectToLalamoveOrder (id) {
  return async (dispatch, getState) => {
    try {
      const lalamoveOrder = await deliveryApi.getLalamoveOrderDetails(id)
      window.open(lalamoveOrder.data.shareLink)
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: redirectToLalamoveOrder', content: error.message }))
      console.error('redirectToLalamoveOrder error', error)
    }
  }
}

/**
 * @param {*} deliveryOrderId deliveryOrder.id
 * @param {*} lalamoveOrderId
 * @returns {ThunkFunction}
 */
export function updateLalamoveOrderId (deliveryOrderId, lalamoveOrderId) {
  return async (dispatch, getState) => {
    try {
      await deliveryApi.updateLalamoveOrderId(deliveryOrderId, lalamoveOrderId)
    } catch (error) {
      dispatch(toggleDialog('alertDialog', true, { title: 'Error: updateLalamoveOrderId', content: error.message + error?.response?.data?.message }))
      console.error('updateLalamoveOrderId error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function exportDeliveryOrders () {
  return async (dispatch, getState) => {
    try {
      const orders = getState().deliveryOrder.data ?? []
      const drivers = getState().driver.data ?? []

      const headers = [
        'code',
        'serial',
        'createdAt',
        'provider',
        'lalamove_status',
        'lalamove_orderId',
        'dimorder_status',
        'start_area',
        'end_area',
        'base_shipping_fee',
        'small_order_fee',
        'tunnel_fee',
        'shipping_fee',
        'dimorder_driverId',
        'dimorder_driverContact',
        'dimorder_driverName',
        'lalamove_priceAmount',
        'lalamove_priceTips',
        'food_profit',
        'p&l',
        'start_timeEstimated',
        'start_contactName',
        'start_contactPhone',
        'start_address',
        'end_timeEstimated',
        'end_contactName',
        'end_contactPhone',
        'end_address',
        'remarks',
        'ON_GOING',
        'PICKED_UP',
        'COMPLETED',
      ]
      const rows = _(orders)
        .orderBy(o => o.start.timeEstimated, 'asc')
        .map(order => {
        /** @type {IDeliveryOrder} */
          const o = order
          const driver = drivers.find(d => d?.id === o.providers.DIMORDER.driverId)
          const foodProfit = o.breakBulkSerial <= 1 ? 30 : 0 // 分單不顯示  // TODO: 更改為訂單中的食物錢
          const customerAmount = o.breakBulkSerial <= 1 ? o.shippingFee?.customerAmount : 0 // 分單不顯示
          const baseShippingFee = o.breakBulkSerial <= 1 ? o.shippingFee?.baseShippingFee : 0
          const smallOrderFee = o.breakBulkSerial <= 1 ? o.shippingFee?.smallOrderFee : 0
          const tunnelFee = o.breakBulkSerial <= 1 ? o.shippingFee?.tunnelFee : 0
          const providerData = o.providers[o.provider]
          const onGoingAt = providerData.onGoingAt
          const pickedUpAt = providerData.pickedUpAt ?? o.start.timeActual
          const completedAt = providerData.completedAt ?? o.end.timeActual

          const columns = [
            o.breakBulkSerial <= 1 ? o.code : o.code + '-' + o.breakBulkSerial,
            o.orderSerial,
            moment(o.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            o.provider,
            o.providers.LALAMOVE.status,
            o.providers.LALAMOVE.orderId,
            o.providers.DIMORDER.status,
            o.start.area,
            o.end.area,
            baseShippingFee,
            smallOrderFee,
            tunnelFee,
            customerAmount,
            o.providers.DIMORDER.driverId,
            driver?.contact,
            driver?.name,
            o.providers.LALAMOVE.price?.amount,
            o.providers.LALAMOVE.price?.tips,
            foodProfit,
            (foodProfit + customerAmount - o.providers.LALAMOVE.price?.amount - o.providers.LALAMOVE.price?.tips) || 0,
            moment(o.start.timeEstimated).format('YYYY-MM-DD HH:mm:ss'),
            o.start.contactName,
            o.start.contactPhone,
            _.chain([o.start.address, o.start.building, o.start.floorRoom]).without('').join(' | ').value(),
            moment(o.end.timeEstimated).format('YYYY-MM-DD HH:mm:ss'),
            o.end.contactName,
            o.end.contactPhone,
            _.chain([o.end.address, o.end.building, o.end.floorRoom]).without('').join(' | ').value(),
            o.remarks,
            onGoingAt ? moment(onGoingAt).format('YYYY-MM-DD HH:mm:ss') : '',
            pickedUpAt ? moment(pickedUpAt).format('YYYY-MM-DD HH:mm:ss') : '',
            completedAt ? moment(completedAt).format('YYYY-MM-DD HH:mm:ss') : '',
          ]
          return _
            .chain(columns)
            .map(col => {
              if (col == null) return ''
              const value = String(col)
              return _.chain(value).replace('\n', '').replace(';', ',').value() // avoid csv display error
            })
            .value()
        })
        .value()
      exportXlsx(`delivery_export_${moment().format('YYYYMMDDHHmmss')}.xlsx`, headers, rows)
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.error') || error
      if (errorMessage) {
        window.alert(errorMessage)
      }
      console.error('exportDeliveryOrders error', error)
    }
  }
}
