
import { TextField } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import TableSearchForm from '@/components/Table/TableSearchForm'
import hooks from '@/hooks'

const defaultState = {
  description: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function PosPayLinkSearchForm (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [inputState, setInputState] = React.useState(defaultState)
  const params = hooks.posPayLink.useQueryParams()

  React.useEffect(() => {
    if (params) {
      setInputState(params)
    }
  }, [params])

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState(prev => { return { ...prev, [name]: value } })
  }

  const handleSearch = async (event) => {
    event.preventDefault()
    await dispatch(actions.posPayLink.updateQuery({ ...inputState, skip: 0 }))
    dispatch(actions.posPayLink.getPosPayLinks())
  }

  return (
    <>
      <TableSearchForm
        handleSearch={handleSearch}
      >
        <TextField
          label='Search..'
          name='description'
          type='text'
          variant='outlined'
          size='small'
          fullWidth
          classes={{ root: classes.textField }}
          value={inputState.description}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch(e)
            }
          }}
          // onKeyUp={handleSearch}
          onChange={handleChange}
        />
      </TableSearchForm>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  textField: {
    background: theme.palette.common.white,
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  fileds: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: theme.spacing(1),
  },
}))
