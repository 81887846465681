import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import ExportButton from '@/components/ExportButton'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import _ from 'lodash'
import moment from 'moment'

import DeliveryOrderSearchForm from './DeliveryOrderSearchForm'
import DeliveryOrderTable from './DeliveryOrderTable'

/**
 *
 * @param {*} props
 * @returns
 */
export default function DeliveryOrder (props) {
  const dispatch = useDispatch()

  const [selectedDriver, setSelectedDriver] = React.useState('ALL')
  const [selectedStatus, setSelectedStatus] = React.useState('ALL')

  const _drivers = useSelector(state => state.driver.data)
  const drivers = React.useMemo(() => _.filter(_drivers, driver => !driver.disabled), [_drivers])
  const deliveryOrders = useSelector(state => state.deliveryOrder.data)
  const { perPage, currentPage } = useSelector(state => state.deliveryOrder.meta)

  const filtered = React.useMemo(() => {
    return _.filter(deliveryOrders, order => {
      if (selectedDriver !== 'ALL') {
        if (order.providers.DIMORDER.driverId !== selectedDriver) {
        // 若不符合所選的司機則不留下
          return false
        }
      }
      if (selectedStatus !== 'ALL') {
        if ((order.providers.DIMORDER.status !== selectedStatus && order.providers.LALAMOVE.status !== selectedStatus)) {
        // 外送單狀態不分 provider 若都不符合所選的訂單狀態則不留下
          return false
        }
      }
      return true
    })
  }, [deliveryOrders, selectedDriver, selectedStatus])
  const total = React.useMemo(() => _.size(filtered), [filtered])
  const paginated = React.useMemo(() => {
    const startIndex = (currentPage - 1) * perPage
    const endIndex = currentPage * perPage
    return _.slice(filtered, startIndex, endIndex)
  }, [filtered, currentPage, perPage])

  const driverOrderStatusMap = React.useMemo(() => {
    // 例：driverOrderStatusMap[driverId][ON_GOING] 可以拿到該 driver 當天 ON_GOING 的單數
    // 用在司機下拉選單的右側，方便使用者看出當前 driver 共有多少 ON_GOING/PICKED_UP/COMPLETED 的單
    const grouped = _.groupBy(deliveryOrders, 'providers.DIMORDER.driverId')
    return _.reduce(grouped, (driverStatus, orders, driverId) => {
      driverStatus[driverId] = _.reduce(orders, (statusMap, order) => {
        if (!moment().isSame(moment(order.start.timeEstimated), 'day')) {
          // 非當天送達的單則不計入單數
          return statusMap
        }
        if (!statusMap[order.providers.DIMORDER.status]) {
          statusMap[order.providers.DIMORDER.status] = [order]
          return statusMap
        }
        statusMap[order.providers.DIMORDER.status].push(order)
        return statusMap
      }, {})
      return driverStatus
    }, {})
  }, [deliveryOrders])

  const handleExport = () => {
    dispatch(actions.deliveryOrder.exportDeliveryOrders())
  }

  return (
    <Page>
      {/* content */}
      <PageHeader text='Delivery Order'>
        <ExportButton onClick={handleExport} />
      </PageHeader>
      <DeliveryOrderSearchForm
        drivers={drivers}
        driverOrderStatusMap={driverOrderStatusMap}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <DeliveryOrderTable
        total={total}
        drivers={drivers}
        driverOrderStatusMap={driverOrderStatusMap}
        deliveryOrders={paginated}
      />
    </Page>
  )
}
