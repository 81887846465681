import bee from '../assets/avatars/bee.png'
import butterfly from '../assets/avatars/butterfly.png'
import cat from '../assets/avatars/cat.png'
import chicken from '../assets/avatars/chicken.png'
import crab from '../assets/avatars/crab.png'
import elephant from '../assets/avatars/elephant.png'
import fish from '../assets/avatars/fish.png'
import flamingo from '../assets/avatars/flamingo.png'
import fox from '../assets/avatars/fox.png'
import giraffe from '../assets/avatars/giraffe.png'
import hen from '../assets/avatars/hen.png'
import koala from '../assets/avatars/koala.png'
import lion from '../assets/avatars/lion.png'
import monkey from '../assets/avatars/monkey.png'
import owl from '../assets/avatars/owl.png'
import pandaBear from '../assets/avatars/panda-bear.png'
import shark from '../assets/avatars/shark.png'
import sheep from '../assets/avatars/sheep.png'
import squirrel from '../assets/avatars/squirrel.png'
import whale from '../assets/avatars/whale.png'

/** @type {IAvatar[]} */
const avatars = [
  { name: 'bee', source: bee },
  { name: 'butterfly', source: butterfly },
  { name: 'cat', source: cat },
  { name: 'chicken', source: chicken },
  { name: 'elephant', source: elephant },
  { name: 'fox', source: fox },
  { name: 'hen', source: hen },
  { name: 'lion', source: lion },
  { name: 'owl', source: owl },
  { name: 'whale', source: whale },
  { name: 'crab', source: crab },
  { name: 'fish', source: fish },
  { name: 'flamingo', source: flamingo },
  { name: 'giraffe', source: giraffe },
  { name: 'koala', source: koala },
  { name: 'monkey', source: monkey },
  { name: 'pandaBear', source: pandaBear },
  { name: 'shark', source: shark },
  { name: 'sheep', source: sheep },
  { name: 'squirrel', source: squirrel },
]
export default avatars
