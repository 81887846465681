import { IconButton } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import React from 'react'

export default function DeleteIconButton (props) {
  return (
    <IconButton
      color='secondary'
      {...props}
    >
      <DeleteOutlineIcon />
    </IconButton>
  )
}
