import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'

/**
 *
 * @param {{
 * open: boolean
 * handleClose: () => void
 * selectedDriver: IDriver
 * }} props
 * @returns
 */
export default function UpdateDriverRoleDialog (props) {
  const { open, handleClose, selectedDriver } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [role, setRole] = React.useState('')
  const handleChange = (e) => setRole(e.target.value)

  React.useEffect(() => {
    setRole(selectedDriver?.role ?? '')
  }, [selectedDriver?.id])

  return (
    <Dialog
      title='Update Driver Role'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                dispatch(actions.driver.updateDriver(selectedDriver?.id, { role }))
                handleClose()
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleClose()
              }}
            >
              No
            </Button>
          </>
        )
      }}
    >
      <div className={classes.dialogContent}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Role</FormLabel>
          <RadioGroup
            row
            aria-label='role'
            name='radio-buttons-group'
            value={role}
            onChange={handleChange}
          >
            <FormControlLabel value='ADMIN' control={<Radio />} label='ADMIN' />
            <FormControlLabel value='DRIVER' control={<Radio />} label='DRIVER' />
          </RadioGroup>
        </FormControl>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
  },
}))
