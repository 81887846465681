import ActionTypes from './ActionTypes'
import customerApi from '@/libs/api/customer'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getSMSRecord () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getSMSRecord' },
    })
    try {
      const params = { ...getState().sms.records.params }
      Object.keys(params).forEach(key => {
        if (params[key] === '') {
          delete params[key]
        }
      })
      const response = await customerApi.getSMS(params)
      const { data, meta } = response
      dispatch({
        type: ActionTypes.UPDATE_RECORD_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_RECORD_META,
        payload: { meta },
      })
    } catch (error) {
      console.log('getSMS error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getSMSRecord' },
      })
    }
  }
}

/**
 * @function
 * @param {{startedAt: string, endedAt: string}} params
 * @returns {}
 */
export function getSMSOutbound (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getSMSOutbound' },
    })
    try {
      const response = await customerApi.getSMSOutbound(params)
      return response.data
    } catch (error) {
      console.log('getSMSOutbound error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getSMSOutbound' },
      })
    }
  }
}

/**
 * @function
 * @param {IGetSMSResultParams} params
 * @returns {ThunkFunction}
 *
 * @typedef IGetSMSResultParams
 * @property {string} id
 * @property {'fulfilled' | 'rejected'} type
 */
export function getSMSResult (params) {
  return async () => {
    try {
      const response = await customerApi.getSMSResult(params.id)
      // create csv
      let csv = 'mobile\n'
      response.data.forEach(e => {
        if (params.type === e.status) {
          csv += `${e.mobile || ''}\n`
        }
      })
      // download
      const file = new Blob([csv], { type: 'application/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = params.type === 'fulfilled' ? 'delivered.csv' : 'not-delivered.csv'
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (error) {
      console.log('getSMSResult error', error)
    }
  }
}

/**
 * @function
 * @param {ISMSQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateSMSQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_RECORD_PARAMS,
        payload: { params },
      })
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}
/**
 * @function
 * @returns {}
 */
export function sentSMS (params) {
  return async () => {
    try {
      Object.keys(params).forEach(key => {
        if (params[key] === undefined) {
          delete params[key]
        }
      })
      console.log(params)
      await customerApi.sentSMS(params)
    } catch (error) {
      console.log('sentSMS error', error)
    }
  }
}
/**
 * @function
 * @returns {ISMSRecord}
 */
export function getSMSInformation (params) {
  return async (dispatch) => {
    try {
      const response = await customerApi.getSMSInformation(params)
      return response.data
    } catch (error) {
      console.log('getSMSInformation error', error)
    }
  }
}
