import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import config from '@/config'

/**
 * @typedef ReceiptButtonProps
 * @property {string} orderSerial
 * @property {string} orderId
 * @property {CSSStyleDeclaration} [style]
 * @param {ReceiptButtonProps} props
 * @returns
 */
export default function ReceiptButton (props) {
  const { orderSerial, orderId, style } = props
  const classes = useStyles(props)
  const handleClick = () => window.open(config.receipt + orderId + '?doadmin=1')

  return (
    <Button
      color='primary'
      className={classes.button}
      onClick={handleClick}
      style={style}
    >
      #<span className={classes.span}>{orderSerial}</span>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    whiteSpace: 'pre',
    fontSize: '1.6rem',
  },
  span: {
    userSelect: 'text',
  },
}))
