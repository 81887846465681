import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { actions, useDispatch } from '@/redux'

/**
 * 送出按鈕
 * 1. 按下時跳確認視窗
 * 2. 確認視窗顯示新舊兩個訂單編號
 * 3. 按下確認時確認訂單編號是否存在
 *  a. 若存在則將新的訂單編號更新到外送單
 *  b. 若不存在則跳警告視窗
 * @param {{
 * deliveryOrderId: string
 * lalamoveOrderId: string
 * input: string
 * disabled: boolean
 * handleClose: () => void
 * setLoading: () => void
 * }} props
 * @returns
 */
export default function SubmitButton (props) {
  const { deliveryOrderId, lalamoveOrderId, input, disabled, handleClose, setLoading } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const Content = React.useMemo(() => (
    <div className={classes.content}>
      <Typography className={classes.message}>
        Are you sure you want to reaplce the lalamove order id?
      </Typography>
      <TableContainer className={classes.container}>
        <Table aria-label='simple table'>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell} size='small' component='th' scope='row'>
                ORIGINAL ID
              </TableCell>
              <TableCell className={classes.cell} size='small' align='right'>
                <Typography>{lalamoveOrderId}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell} size='small' component='th' scope='row'>
                NEW ID
              </TableCell>
              <TableCell className={classes.cell} size='small' align='right'>
                <Typography color='secondary'>{input}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ), [lalamoveOrderId, input])

  const handleSubmit = () => {
    dispatch(actions.app.toggleDialog('confirmDialog', null, {
      content: Content,
      formProps: { style: { gridTemplateRows: 'auto auto' } },
      confirmCallback: async () => {
        setLoading(true)
        await dispatch(actions.deliveryOrder.updateLalamoveOrderId(deliveryOrderId, input))
        setLoading(false)
        handleClose()
      },
    }))
  }

  return (
    <Button
      color='primary'
      disabled={disabled}
      onClick={handleSubmit}
      className={classes.button}
    >
      Submit
    </Button>
  )
}

const useStyles = makeStyles((theme, props) => ({
  button: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    padding: 16,
  },
  container: {
    padding: 16,
  },
  cell: {
    borderBottom: 'none',
  },
}))
