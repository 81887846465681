import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import React from 'react'
import format from 'format-number'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'

import TagSelector from './TagSelector'

const addComma = (value) => {
  if (!value) { return 0 }
  return format({ prefix: '', suffix: '' })(value)
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function CustomerDialog (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles(props)
  const { open, data } = useDialog('customerDetail')

  const handleClose = () => {
    if (needUpdate) {
      dispatch(actions.customer.getCustomers())
    }
    dispatch(actions.app.toggleDialog('customerDetail'))
  }

  const [deliveryData, setDeliveryData] = React.useState({})
  const [needUpdate, setNeedUpdate] = React.useState(false)

  React.useEffect(() => {
    const getDeliveryTypes = async () => {
      setNeedUpdate(false)
      const response = await dispatch(actions.dashboard.getDeliveryTypes({ userId: data.id, startedAt: 0 }))
      setDeliveryData(response)
    }
    if (open) {
      getDeliveryTypes()
    }
  }, [open])

  const handleRicecoinClick = ({ mobile }) => {
    dispatch(actions.riceCoin.updateQuery({ mobile: mobile.replace('+852', ''), page: 1 }))
    dispatch(actions.app.toggleDialog('customerDetail'))
    if (location.pathname === '/ricecoin') {
      dispatch(actions.riceCoin.getLedgers())
    } else {
      history.push('/ricecoin')
    }
  }

  const handleReferrerClick = ({ id }) => {
    dispatch(actions.customer.updateQuery({ referrer: id, page: 1 }))
    dispatch(actions.app.toggleDialog('customerDetail'))
    if (location.pathname !== '/customer') {
      history.push('/customer')
    }
  }

  const lastOrder = (createdAt) => {
    if (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD HH:mm')
    }
    return '無訂購紀錄'
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title='Customer'
      renderActions={() => {
        return (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleClose}
          >
            OK
          </Button>
        )
      }}
    >
      <div className={classes.customerDialog}>
        <div>
          <Typography variant='h5' className={classes.subHeader}>
            Basic Information
          </Typography>
          <div>
            <div><b>Id:</b> {data.id}</div>
            <div><b>Mobile:</b> {data.mobile}</div>
            <div><b>Name:</b> {data.name}</div>
            <div><b>Referrer:</b> {data.referrer?.name && <span>{data.referrer?.name}({data.referrer?.mobile})</span>}</div>
            <div><b>Referee:</b> <span className={classes.hover} onClick={() => handleReferrerClick(data)}>{data.referees}</span></div>
            <div><b>RiceCoin:</b> <span className={classes.hover} onClick={() => handleRicecoinClick(data)}>{addComma(data.ricecoin)}</span></div>
            <div><b>Invitation code:</b> {data.referral}</div>
            <div><b>Spending:</b> ${addComma(data.total)}/{data.bought}單</div>
            <div><b>Tags:</b> <TagSelector isUpdate={setNeedUpdate} data={data} /></div>
          </div>
        </div>
        <div>
          <Typography variant='h5' className={classes.subHeader}>
            Order
          </Typography>
          <div><b>Latest order:</b> {lastOrder(data.order?.createdAt)}</div>
          <div><b>總訂購次數:</b> {addComma(deliveryData.count)}</div>
          <div><b>總消費金額:</b> {addComma(deliveryData.amount)}</div>
          <p><b>外送</b></p>
          <ul>
            <li><b>總次數:</b> {addComma(deliveryData.types?.storeDelivery?.count || 0)}</li>
            <li><b>總金額:</b> {addComma(deliveryData.types?.storeDelivery?.amount || 0)}</li>
          </ul>
          <p><b>外帶</b></p>
          <ul>
            <li><b>總次數:</b> {addComma(deliveryData.types?.takeaway?.count || 0)}</li>
            <li><b>總金額:</b> {addComma(deliveryData.types?.takeaway?.amount || 0)}</li>
          </ul>
          <p><b>堂食</b></p>
          <ul>
            <li><b>總次數:</b> {addComma(deliveryData.types?.dineIn?.count || 0)}</li>
            <li><b>總金額:</b> {addComma(deliveryData.types?.dineIn?.amount || 0)}</li>
          </ul>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  customerDialog: {
    display: 'grid',
    // gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
    placeItems: 'start',
    padding: theme.spacing(3),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
    '& > div': {
      background: '#fafafa',
      height: '100%',
      width: '100%',
      padding: theme.spacing(2),
    },
    lineHeight: '1.8',
  },
  subHeader: {
    marginBottom: theme.spacing(2),
  },
  hover: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1675e0',
    },
  },
}))
