import axios from 'axios'

import qs from 'qs'

import config from '@/config'

class DimboxApi {
  constructor (url) {
    this.token = undefined
    this.axios = axios.create({ baseURL: url || config.dimboxApi })
  }

  /**
   *
   * @param {Date} date
   * @returns {{
   * available: number
   * boxes: IDimbox[]
   * }}
   */
  async getDimboxes (date) {
    const response = await this.axios.get(`/c/info/${date}`)
    return response.data
  }

  /**
  *
  * @param {string} cabinetId
  * @param {string} orderId
  * @param {string} boxId
  * @param {'CUSTOMER_APP' | 'CUSTOMER_WEB'} from
  * @param {Date?} testTime
  * @returns
  */
  async openDimbox (cabinetId, orderId, boxId, from, testTime) {
    const query = qs.stringify({ from, testTime })
    const response = await this.axios.post(`/c/${cabinetId}/${orderId}/${boxId}/open?${query}`)
    return response.data
  }
}

export default new DimboxApi()
