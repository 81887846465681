import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import format from 'format-number'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import ControlledTable from '@/components/Table/ControlledTable'
import ReceiptButton from '@/components/ReceiptButton'
import constants from '@/constants'
import hooks from '@/hooks'

/**
 *
 * @param {*} props
 * @returns
 */
export default function RiceCoinTable (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const ledgers = hooks.riceCoin.useLedgers()
  const meta = hooks.riceCoin.usePaginationMeta()
  const params = hooks.riceCoin.useQueryParams()
  const classes = useStyles(props)

  useEffect(() => {
    dispatch(actions.riceCoin.getLedgers())
  }, [])

  const columns = [
    {
      accessor: 'mobile',
      Header: 'Mobile',
      Cell: function myCell ({ value }) { return (<div className={classes.mobile} onClick={() => handleMobileClick(value)}>{value}</div>) },
    },
    {
      accessor: (row, i) => {
        if (row.deliveryType && row.merchantName) {
          return `${row.merchantName} (${constants.app.deliveryType[row.deliveryType]})`
        }
      },
      Header: 'Merchant Name',
    },
    {
      accessor: (row) => {
        if (!row.orderSerial) return <p>Not Found</p>
        return <ReceiptButton orderId={row.orderId} orderSerial={row.orderSerial} />
      },
      Header: 'Serial #',
    },
    {
      accessor: 'amount',
      Header: 'RiceCoin',
      Cell: ({ value }) => format({ prefix: '', suffix: '' })(value),
    },
    {
      accessor: 'createdAt',
      Header: 'Date',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      accessor: 'memo',
      Header: 'Memo',
      Cell: ({ value }) => value && value.replace('poonchoi', '中秋盆菜'),
    },
    { accessor: 'admin', Header: 'Admin' },
  ]

  const handleMobileClick = (mobile) => {
    dispatch(actions.customer.updateQuery({ mobile: mobile.replace('+852', ''), page: 1 }))
    history.push('/customer')
  }

  const onPageChange = async (event, page) => {
    dispatch(actions.riceCoin.updateQuery({ ...params, page: page + 1 }))
    await dispatch(actions.riceCoin.getLedgers())
  }

  return (
    <div style={{ maxWidth: '98vw' }}>
      <ControlledTable
        columns={columns}
        data={ledgers}
        pageSize={meta.perPage}
        currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
        rowCount={meta.total}
        onPageChange={onPageChange}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  mobile: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1675e0',
    },
  },
}))
