import { Button, TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'

/**
 *
 * @param {{
 * open: boolean
 * handleClose: () => void
 * selectedJob: IReportJob
 * }} props
 * @returns
 */
export default function RemarksEditDialog (props) {
  const { open, handleClose, selectedJob } = props
  const [value, setValue] = React.useState(selectedJob.remark)
  const classes = useStyles(props)
  const dispatch = useDispatch()

  React.useEffect(() => {
    setValue(selectedJob.remark)
  }, [selectedJob.remark])

  return (
    <Dialog
      title='Update Remarks'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <>
            <Button
              variant='contained'
              color='primary'
              onClick={async () => {
                await dispatch(actions.report.updateReportJob(selectedJob.id, { remark: value }))
                dispatch(actions.report.getReportJobs())
                handleClose()
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleClose()
              }}
            >
              No
            </Button>
          </>
        )
      }}
    >
      <div className={classes.dialogContent}>
        <TextareaAutosize
          className={classes.textarea}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
  },
  textarea: {
    flex: 1,
    margin: 16,
    padding: 16,
  },
}))
