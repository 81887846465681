import { Table as MuiTable, Paper, TableContainer, TablePagination } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTable } from 'react-table'
import PropTypes from 'prop-types'
import React from 'react'

import TableBody from './TableBody'
import TableHead from './TableHead'

ControlledTable.prototypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  rowCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
}

/**
 *
 * @param {{
 *  columns: ITableColumn[]
 *  data: any[]
 *  rowCount: number
 *  pageSize: number
 *  currentPage: number
 *  onPageChange: () => void
 *  onRowsPerPageChange?: () => void
 *  rowsPerPageOptions?: number[]
 *  tableBodyProps?: object
 * }} props
 * @returns
 */
export default function ControlledTable (props) {
  const { columns, data, rowCount = 0, pageSize = 25, currentPage = 0, onPageChange, onRowsPerPageChange, rowsPerPageOptions = [], tableBodyProps } = props
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })
  const classes = useStyles(props)

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable {...getTableProps()} stickyHeader>
          <TableHead headerGroups={headerGroups} />
          <TableBody
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            rows={rows}
            data={data}
            tableBodyProps={tableBodyProps}
          />
        </MuiTable>
      </TableContainer>
      <TablePagination
        component='div'
        count={rowCount}
        page={currentPage}
        onPageChange={onPageChange}
        rowsPerPage={pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={onRowsPerPageChange}
        classes={{ root: classes.pagination }}
      />
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 880,
  },
  pagination: {
    backgroundColor: '#fafafa',
  },
}))
