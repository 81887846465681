import { Button } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { roles } from '@/libs/common'
import React from 'react'
import Table from '@/components/Table'
import hooks from '@/hooks'

export default function UserTable (props) {
  const dispatch = useDispatch()
  const users = hooks.user.useUsers()
  const systemUsers = users.filter(o => !o.username.match(/^driver\d{8}$/))
  const params = hooks.user.useQueryParams()
  const resultUsers = systemUsers.filter(o => o.username.includes(params.name))

  const classes = useStyles(props)
  const Actions = (user) => {
    if (['root', 'wing'].includes(user.username)) { return '' }
    return (
      <div className={classes.actionsCol}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleEditUser(user)}
        >Edit
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={async () => {
            dispatch(actions.app.toggleDialog('confirmDialog', null, {
              content: 'Are you sure you want to delete this user?',
              confirmCallback: async () => {
                await dispatch(actions.user.deleteUser(user))
                dispatch(actions.user.getUsers())
              },
            }))
          }}
        >Delete
        </Button>
      </div>
    )
  }

  const rolesIdToName = (roleString) => {
    if (!roleString) { return '' }
    return roleString.split(',').map(ruleId => (roles.find(rule => rule.id === ruleId)?.name || ruleId)).join(', ')
  }

  const handleEditUser = (user) => {
    dispatch(actions.app.toggleDialog('editUser', null, user))
  }

  const columns = [
    { accessor: 'username', Header: 'Name' },
    {
      accessor: 'role',
      Header: 'Role',
      Cell: ({ value }) => rolesIdToName(value),
    },
    {
      accessor: row => Actions(row),
      Header: 'Action',
    },
  ]
  React.useEffect(() => {
    dispatch(actions.user.getUsers())
  }, [])

  return (
    <Table
      columns={columns}
      data={resultUsers}
    />
  )
}

const useStyles = makeStyles(theme => ({
  nameCol: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  actionsCol: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))
