import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import PageHeader from '@/components/Page/PageHeader'
import config from '@root/src/config'
import moment from 'moment'
import nodeApi from '@/libs/api/node'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Links (props) {
  const classes = useStyles(props)

  const handleLinkClick = async title => {
    if (title === 'merchant-portal') {
      const { customToken: token } = await nodeApi.getMe().then(o => o.data)
      if (!token) { return window.alert('auth failed') }
      return window.open(`${config.mpUrl}?from=dimorder-admin&token=${token}`)
    } else if (title === 'lalamove-today') {
      return window.open(`https://web.lalamove.com/orders?statuses=FINDING_DRIVER&max=10&start=${moment().format('YYYY-MM-DD')}&end=${moment().format('YYYY-MM-DD')}`)
    }
  }

  return (
    <div className={classes.page}>
      {/* content */}
      <PageHeader text='Links' />
      {/* <div className={classes.btn}>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => handleLinkClick('merchant-portal')}
        >
          Merchant Portal
        </Button>
      </div> */}
      <div className={classes.btn}>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => handleLinkClick('lalamove-today')}
        >
          Lalamove today
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  textField: {
    background: theme.palette.common.white,
  },
  page: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 500px)',
    gap: theme.spacing(1),
    background: 'white',
    maxWidth: '500px',
  },
  controlLine: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto 1fr',
    rowGap: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  pullDownControlLine: {
    gridTemplateColumns: '1fr 0.78fr',
  },
  btn: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectControl: {
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
}))
