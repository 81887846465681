import { ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React from 'react'

/**
 *
 * @param {{
 * title: string
 * expand: boolean
 * onClick: function
 * }} props
 * @returns
 */
export default function ListHeader (props) {
  const { title, expand, onClick: handleClick } = props
  const classes = useStyles(props)

  return (
    <ListItem button onClick={handleClick} className={classes.listHeader}>
      <ListItemText primary={title} primaryTypographyProps={{ className: classes.title }} />
      {expand ? <ExpandLess fontSize='large' /> : <ExpandMore fontSize='large' />}
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  listHeader: { },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
}))
