// import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import {
  Button,
  TextField,
} from '@material-ui/core'
import { TagPicker } from 'rsuite'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { roles } from '@/libs/common'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React from 'react'

const defaultState = {
  id: '',
  role: '',
}

export default function EditUserDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open, data } = useDialog('editUser')
  const [inputState, setInputState] = React.useState(defaultState)
  const [selectedRoles, setSelectedRoles] = React.useState([])
  const roleList = roles.map(role => {
    return {
      value: role.id,
      label: role.name,
    }
  })
  const handleClose = () => {
    dispatch(actions.app.toggleDialog('editUser'))
  }

  React.useEffect(() => {
    if (data.uid) {
      setInputState({
        ...inputState,
        id: data.uid,
      })
      setSelectedRoles(data.role?.split(','))
    }
  }, [open])

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState({ ...inputState, [name]: value })
  }

  const handleSubmit = async () => {
    const param = {
      ...inputState,
      role: selectedRoles.filter(role => role).join(','),
    }
    console.log(param)
    await dispatch(actions.app.toggleDialog('editUser'))
    await dispatch(actions.user.updateUser(param))
    await dispatch(actions.user.getUsers())
  }

  const handleSelect = (e) => {
    e = e || []
    setSelectedRoles(e)
  }
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={`Edit User ${data.username}`}
      renderActions={() => {
        return (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )
      }}
    >
      <div className={classes.box}>
        <div>Username:</div>
        <TextField
          variant='outlined'
          size='small'
          name='username'
          fullWidth
          disabled
          classes={{ root: classes.textField }}
          value={data.username}
          onChange={handleChange}
        />
        <div>Password:</div>
        <TextField
          variant='outlined'
          size='small'
          name='password'
          fullWidth
          type='password'
          classes={{ root: classes.textField }}
          value={inputState.password}
          onChange={handleChange}
        />
        <div>Role:</div>
        <TagPicker
          className={classes.roleSelecter}
          onChange={handleSelect}
          value={selectedRoles}
          data={roleList}
          placeholder='Role'
        />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(1),
    padding: theme.spacing(3),
    alignItems: 'center',
  },
  roleSelecter: {
    minWidth: '100%',
    maxWidth: '300px',
  },
}))
