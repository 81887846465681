import ActionTypes from './ActionTypes'
import customerApi from '@/libs/api/customer'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getCustomers () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getCustomers' },
    })
    try {
      const params = { ...getState().customer.params }
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })
      const response = await customerApi.getCustomers({
        ...params,
        ...params.mobile && { mobile: '+852' + params.mobile },
      })
      const { data, meta } = response

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { meta },
      })
    } catch (error) {
      console.log('getLedgers error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getCustomers' },
      })
    }
  }
}

/**
 * @function
 * @param {string} mobile
 * @returns {ICustomer}
 */
export function getCustomer (mobile) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getCustomer' },
    })
    try {
      const response = await customerApi.getCustomers({
        mobile: mobile,
      })
      const { data } = response

      return data[0]
    } catch (error) {
      console.log('getLedgers error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getCustomer' },
      })
    }
  }
}

/**
 * @function
 * @param {ICustomerQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })

      // 更新params後，重新抓取customers
      dispatch(getCustomers())
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getTags () {
  return async (dispatch, getState) => {
    try {
      // const params = getState().customer.params
      const response = await customerApi.getTags({
        // ...params,
      })
      const { data, meta } = response

      dispatch({
        type: ActionTypes.UPDATE_TAGS,
        payload: { data, meta },
      })
    } catch (error) {
      console.log('getTags error', error)
    }
  }
}

/**
 * @function
 * @param {string} tagName
 * @returns {ThunkFunction}
 */
export function addTags (tagName) {
  return async (dispatch, getState) => {
    try {
      const params = {
        name: tagName,
      }
      const response = await customerApi.addTags(params)
      dispatch(getTags())
      return response.data
    } catch (error) {
      console.log('addUserTags error', error)
    }
  }
}

/**
 * @function
 * @param {string} tagId
 * @returns {ThunkFunction}
 */
export function deleteTag (tagId) {
  return async (dispatch, getState) => {
    try {
      await customerApi.deleteTag(tagId)
    } catch (error) {
      console.log('addUserTags error', error)
    }
  }
}

/**
 * @function
 * @param {string} userId
 * @param {string} tagsId
 * @returns {ThunkFunction}
 */
export function addUserTags (userId, tagsId) {
  return (dispatch, getState) => {
    try {
      const params = {
        tag_ids: [tagsId],
      }
      customerApi.addUserTags(userId, params)
    } catch (error) {
      console.log('addUserTags error', error)
    }
  }
}

/**
 * @function
 * @param {string} userId
 * @param {string} tagsId
 * @returns {ThunkFunction}
 */
export function deleteUserTags (userId, tagsId) {
  return (dispatch, getState) => {
    try {
      customerApi.deleteUserTags(userId, tagsId)
    } catch (error) {
      console.log('deleteUserTags error', error)
    }
  }
}

/**
 * @function
 * @param {string} userId
 * @param {string[]} tags
 * @returns {ThunkFunction}
 */
export function deleteAllUserTags (userId, tags) {
  return async (dispatch, getState) => {
    try {
      await tags.map(async tagsId => {
        return await customerApi.deleteUserTags(userId, tagsId)
      })
    } catch (error) {
      console.log('deleteUserTags error', error)
    }
  }
}

/**
 * @function
 * @param {{ text: string }} param
 * @returns {ThunkFunction}
 */
export function sentNotification (param) {
  return async (dispatch, getState) => {
    try {
      return (await customerApi.sentNotification(param)).status === 204
    } catch (error) {
      console.log('deleteUserTags error', error)
    }
  }
}

/**
 * @function
 * @param {{ name: string }} params
 * @returns {ThunkFunction}
 */
export function updateReferrerName (params) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_REFERRER_NAME,
        payload: params,
      })
    } catch (error) {
      console.log('updateReferrerName error', error)
    }
  }
}
