import { FormControl, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useErrors } from '@/hooks/ErrorsStateContext'
import { useInputState } from '@/hooks/inputStateContext'
import React from 'react'
import _ from 'lodash'

/**
 *
 * @param {*} props
 * @returns
 */
export default function InputControl (props) {
  const { setting, options } = props
  const [inputState, updateInputState] = useInputState()
  const [errors, setErrors] = useErrors()
  const classes = useStyles(props)

  const handleChange = (e) => {
    let { name, value } = e.target
    if (setting.inputOption?.type === 'number') {
      value = Number(value)
    }
    updateInputState(name, value)
    setErrors({ ...errors, [name]: undefined })
  }

  const value = _.get(inputState, setting.paramaterName, '')

  return (
    <FormControl
      variant='outlined'
      margin='dense'
      fullWidth
      classes={{ root: classes.textField }}
    >
      <TextField
        label={setting.label}
        name={setting.paramaterName}
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        value={value}
        onChange={handleChange}
        error={Boolean(errors.code)}
        inputProps={setting.inputOption}
        {...options}
        {...setting.inputProps}
      />
    </FormControl>
  )
}

const useStyles = makeStyles(theme => ({
  textField: {
    background: 'white !important',
  },
}))
