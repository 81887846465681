import { Button, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'

const defaultState = {
  name: '',
  contact: '',
  password: '',
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function AddDriverDialog (props) {
  const { open, handleClose } = props
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [inputState, setInputState] = React.useState(defaultState)
  const [error, setError] = React.useState(null)

  const errorMessage = React.useMemo(() => {
    const defaultMessage = {
      name: undefined,
      contact: undefined,
      password: undefined,
    }
    if (_.includes(error?.message, 'password')) {
      return { ...defaultMessage, password: error?.message }
    }
    if (_.includes(error?.message, 'contact')) {
      return { ...defaultMessage, contact: error?.message }
    }
    return defaultMessage
  }, [error])

  const disabled = React.useMemo(() => {
    if (!inputState.name) return true
    if (!inputState.contact) return true
    return false
  }, [inputState])

  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState(prev => ({ ...prev, [name]: value }))
    setError(null)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const contactPattern = /^[0-9]{8,8}$/
    const passwordPattern = /^[0-9]{6,6}$/
    if (!inputState.contact.match(contactPattern)) {
      setError(new Error('The contact must be 8 digits.'))
      return
    }
    if (!inputState.password.match(passwordPattern)) {
      setError(new Error('The password must be 6 digits.'))
      return
    }
    await dispatch(actions.driver.createDriver({
      name: inputState.name,
      contact: inputState.contact,
      password: inputState.password,
    }))
    handleClose()
  }

  return (
    <Dialog
      title='Add Driver'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disableElevation
            disabled={disabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )
      }}
    >
      <form className={classes.form} autoComplete='off'>
        <TextField
          label='Name'
          name='name'
          type='text'
          required
          variant='outlined'
          size='medium'
          fullWidth
          value={inputState.name}
          onChange={handleChange}
        />
        <TextField
          label='Mobile'
          name='contact'
          type='tel'
          required
          variant='outlined'
          size='medium'
          fullWidth
          value={inputState.contact}
          onChange={handleChange}
          InputProps={{ startAdornment: <InputAdornment position='start'>+852</InputAdornment> }}
          inputProps={{ maxLength: 8 }}
          error={Boolean(errorMessage.contact)}
          helperText={errorMessage.contact}
        />
        <TextField
          label='Password'
          name='password'
          type='text'
          required
          variant='outlined'
          size='medium'
          fullWidth
          value={inputState.password}
          onChange={handleChange}
          inputProps={{ maxLength: 6 }}
          error={Boolean(errorMessage.password)}
          helperText={errorMessage.password}
        />
      </form>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    // gridTemplateColumns: 'repeat(3,1fr) auto',
    gap: theme.spacing(2),
    placeItems: 'center',
    paddingTop: theme.spacing(2),
  },
}))
