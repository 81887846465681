import React from 'react'

import { Provider as MerchantContextProvider } from '@/hooks/merchantContext'
import { actions, useDispatch } from '@/redux'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import config from '@/config'

import CopyMenuButton from './buttons/CopyMenuButton'
import CopyToBetaButton from './buttons/CopyToBetaButton'
import CreateButton from './buttons/CreateButton'
import ExportButton from '@/components/ExportButton'

import CopyMenuDialog from './dialogs/CopyMenuDialog'
import CopyToBetaDialog from './dialogs/CopyToBetaDialog'
import CreateDialog from './dialogs/CreateDialog'
import LinkDialog from './dialogs/LinkDialog'
import MerchantDialog from './dialogs/MerchantDialog'

import MerchantSearchForm from './MerchantSearchForm'
import MerchantTable from './MerchantTable'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Merchant (props) {
  const dispatch = useDispatch()
  const handleExport = () => {
    dispatch(actions.merchant.exportMerchants())
  }
  return (
    <MerchantContextProvider>
      <Page>
        {/* content */}
        <PageHeader text='Merchant'>
          <CreateButton />
          <CopyMenuButton />
          {config.production && <CopyToBetaButton />}
          <ExportButton onClick={handleExport} />
        </PageHeader>
        <MerchantSearchForm />
        <MerchantTable />
        <MerchantDialog />
        <LinkDialog />
        <CreateDialog />
        <CopyMenuDialog />
        <CopyToBetaDialog />
      </Page>
    </MerchantContextProvider>
  )
}
