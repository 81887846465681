import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch } from '@/redux'
import Dialog from '@/components/Dialog'
import deliveryApi from '@/libs/api/delivery'

/**
 *
 * @param {{
 * open: boolean
 * handleClose: () => void
 * selectedOrder?: IDeliveryOrder
 * setSelectedOrderId: () => void
 * setReplaceLalamoveOrderIdDialogOpen: () => void
 * }} props
 * @returns
 */
export default function LalamoveOprationDialog (props) {
  const { open, handleClose, selectedOrder, setSelectedOrderId, setReplaceLalamoveOrderIdDialogOpen } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const deliveryOrderId = selectedOrder?.id
  const lalamoveOrderId = selectedOrder?.providers?.LALAMOVE?.orderId
  const lalamoveOrderStatus = selectedOrder?.providers?.LALAMOVE?.status
  const lalamoveOrderUpdatedAt = selectedOrder?.providers?.LALAMOVE?.updatedAt
  const [serviceType, setServiceType] = React.useState('MOTORCYCLE')
  const [lalamoveOrderData, setLalamoveOrderData] = React.useState({})

  React.useEffect(() => {
    const getLalamoveOrderDetails = async () => {
      const lalamoveOrder = await deliveryApi.getLalamoveOrderDetails(lalamoveOrderId)
      setLalamoveOrderData(lalamoveOrder.data)
    }
    if (lalamoveOrderId) {
      getLalamoveOrderDetails()
    }
  }, [open])

  const renderLalamoveOrder = () => {
    if (!lalamoveOrderId) return null
    return (
      <div className={classes.dialogContent}>
        <TableContainer style={{ padding: '0 24px' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} size='small' component='th' scope='row'>ID</TableCell>
                <TableCell className={classes.tableCell} size='small' align='right'>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography>{lalamoveOrderId}</Typography>
                    <IconButton style={{ marginLeft: 8, padding: 4 }} onClick={() => setReplaceLalamoveOrderIdDialogOpen(true)}>
                      <EditIcon fontSize='small' color='primary' />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell} size='small' component='th' scope='row'>DRIVER ID</TableCell>
                <TableCell className={classes.tableCell} size='small' align='right'>{lalamoveOrderData.driverId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell} size='small' component='th' scope='row'>AMOUNT</TableCell>
                <TableCell className={classes.tableCell} size='small' align='right'>{lalamoveOrderData.priceBreakdown?.currency} {lalamoveOrderData.priceBreakdown?.totalExcludePriorityFee}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  const renderServiceTypeOptions = () => {
    if (_.includes(['PENDING', 'CANCELED', 'REJECTED'], lalamoveOrderStatus)) {
      const handleChange = (e) => setServiceType(e.target.value)
      return (
        <div className={classes.dialogContent}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Service Type</FormLabel>
            <RadioGroup row aria-label='serviceType' name='serviceType' value={serviceType} onChange={handleChange}>
              <FormControlLabel value='MOTORCYCLE' control={<Radio color='primary' />} label='MOTORCYCLE' />
              <FormControlLabel value='VAN' control={<Radio color='primary' />} label='VAN' />
              {/*
            <FormControlLabel value='WALKER' disabled control={<Radio color='primary' />} label='WALKER' />
            <FormControlLabel value='TRUCK550' disabled control={<Radio color='primary' />} label='TRUCK550' />
            */}
            </RadioGroup>
          </FormControl>
        </div>
      )
    }
    return null
  }

  const renderActionButtons = () => {
    const buttons = []
    if (_.includes(['PENDING', 'CANCELED', 'REJECTED'], lalamoveOrderStatus)) {
      buttons.push(
        <Button
          key='Create'
          color='primary'
          onClick={() => {
            dispatch(actions.deliveryOrder.placeLalamoveOrder(deliveryOrderId, serviceType))
            handleClose()
            setSelectedOrderId('')
          }}
        >
          Create
        </Button>,
      )
    }

    if (_.includes(['PENDING', 'ASSIGNING_DRIVER', 'ON_GOING'], lalamoveOrderStatus)) {
      buttons.push(
        <Button
          key='Cancel'
          color='primary'
          disabled={lalamoveOrderStatus === 'ON_GOING' && moment().isSameOrAfter(moment(lalamoveOrderUpdatedAt).add(5, 'minutes'))}
          onClick={() => {
            if (lalamoveOrderStatus === 'PENDING') {
              dispatch(actions.deliveryOrder.updateDeliveryOrder(deliveryOrderId, {
                'providers.LALAMOVE.status': 'CANCELED',
                'providers.LALAMOVE.updatedAt': moment().toISOString(),
              }))
            } else {
              dispatch(actions.deliveryOrder.cancelLalamoveDelivery(lalamoveOrderId))
            }
            handleClose()
            setSelectedOrderId('')
          }}
        >
          Cancel
        </Button>,
      )
    }

    if (lalamoveOrderId) {
      buttons.push(
        <Button
          key='Redirect'
          color='primary'
          disabled={!lalamoveOrderData?.shareLink}
          onClick={() => {
            dispatch(actions.deliveryOrder.redirectToLalamoveOrder(lalamoveOrderId))
            handleClose()
            setSelectedOrderId('')
          }}
        >
          Redirect
        </Button>,
      )
    }

    return buttons
  }

  return (
    <Dialog
      title='Lalamove Oprations'
      open={open}
      handleClose={handleClose}
      renderActions={renderActionButtons}
    >
      <Typography className={classes.status}>{lalamoveOrderStatus}</Typography>
      {renderLalamoveOrder()}
      {renderServiceTypeOptions()}

    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    alignItems: 'center',
  },
  status: {
    textAlign: 'center',
    padding: theme.spacing(2),
    fontWeight: 'bold',
  },
  tableCell: {
    borderBottom: 'none',
  },
}))
