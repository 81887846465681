import { Button, Checkbox } from '@material-ui/core'
import { IoMdPerson } from 'react-icons/io'
import React from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import AVATARS from '@/constants/avatars'
import ControlledTable from '@/components/Table/ControlledTable'

import UpdateDriverRoleDialog from './UpdateDriverRoleDialog'

/**
 * @typedef DriverTableProps
 * @property {IDriver[]} drivers
 */

/**
 *
 * @param {DriverTableProps} props
 * @returns
 */
export default function DriverTable (props) {
  const { drivers } = props
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [selectedDriver, setSelectedDriver] = React.useState({})
  const [updateRoleDialogOpen, setUpdateRoleDialogOpen] = React.useState(false)

  const total = _.size(drivers)

  /**
   * @components
   * @param {IDriver} driver
   * @returns
   */
  function OnDutyCheckbox (driver) {
    const disabled = driver.disabled
    const handleChange = () => {
      dispatch(actions.driver.updateDriver(driver.id, { onDuty: !driver.onDuty }))
    }
    return (
      <Checkbox color='primary' checked={driver.onDuty} onChange={handleChange} disabled={disabled} />
    )
  }

  /**
   * @components
   * @param {IDriver} driver
   * @returns
   */
  function NameBox (driver) {
    const avatar = _.find(AVATARS, avatar => avatar.name === driver.avatar)
    return (
      <div className={classes.avatarDiv}>
        {avatar
          ? <img className={classes.avatar} src={avatar.source} />
          : <IoMdPerson className={cx([classes.avatar, classes.defaultAvatar])} />}
        {driver.name}
      </div>
    )
  }

  /**
   * @components
   * @param {IDriver} driver
   * @returns
   */
  function RoleButton (driver) {
    return (
      <Button
        color='primary'
        onClick={() => {
          setUpdateRoleDialogOpen(true)
          setSelectedDriver(driver)
        }}
      >
        {driver.role ?? 'EMPTY'}
      </Button>
    )
  }

  /**
   * @components
   * @param {IDriver} driver
   * @returns
   */
  function DisableDriverButton (driver) {
    return (
      <Button
        disableElevation
        color='primary'
        variant='contained'
        onClick={() => {
          dispatch(actions.app.toggleDialog('confirmDialog', null, {
            content: `Are you sure to disable driver ${driver.name}? `,
            confirmCallback: async () => {
              await dispatch(actions.driver.enableDriver(driver.id, false))
            },
          }))
        }}
      >
        Disable
      </Button>
    )
  }

  /**
   * @components
   * @param {IDriver} driver
   * @returns
   */
  function EnableDriverButton (driver) {
    return (
      <Button
        disableElevation
        color='secondary'
        variant='contained'
        onClick={() => {
          dispatch(actions.app.toggleDialog('confirmDialog', null, {
            content: `Are you sure to enable driver ${driver.name}? `,
            confirmCallback: async () => {
              await dispatch(actions.driver.enableDriver(driver.id, true))
            },
          }))
        }}
      >
        Enable
      </Button>
    )
  }

  const columns = [
    { Header: 'Name', accessor: row => NameBox(row) },
    { Header: 'Contact', accessor: 'contact' },
    { Header: 'On Duty', accessor: row => OnDutyCheckbox(row) },
    { Header: 'Role', accessor: row => RoleButton(row) },
    { Header: 'Disable', accessor: row => row.disabled ? EnableDriverButton(row) : DisableDriverButton(row) },
  ]

  return (
    <>
      <ControlledTable
        columns={columns}
        data={drivers}
        pageSize={total}
        currentPage={0}
        rowCount={total}
        onPageChange={() => {}}
      />
      <UpdateDriverRoleDialog
        open={updateRoleDialogOpen}
        handleClose={() => {
          setUpdateRoleDialogOpen(false)
          setSelectedDriver({})
        }}
        selectedDriver={selectedDriver}
      />
    </>
  )
}
const useStyles = makeStyles(theme => ({
  avatarDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 50,
    height: 50,
    border: '1px solid #ccc',
    marginRight: '15px',
    borderRadius: '50%',
    padding: 3,
  },
  defaultAvatar: {
    padding: 5,
    color: '#ccc',
  },
}))
