import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import moment from 'moment'

import TableSearchForm from './TableSearchForm'

const firstHalfOfMonth = moment().format('D') < 16
const defaultState = {
  startedAt: firstHalfOfMonth ? moment().subtract(1, 'months').date(16).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
  endedAt: firstHalfOfMonth ? moment().subtract(1, 'months').endOf('months').format('YYYY-MM-DD') : moment().date(15).format('YYYY-MM-DD'),
  deliveryType: ['takeaway', 'storeDelivery'],
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function SearchForm (props) {
  const { handleSearch } = props
  const classes = useStyles(props)
  const [inputState, setInputState] = React.useState(defaultState)
  const [deliveryState, setDeliveryState] = React.useState('takeawayDelivery')
  const handleChange = (event) => {
    const { name, value } = event.currentTarget
    setInputState(prev => { return { ...prev, [name]: value } })
  }

  const handleSearchMiddle = (event) => {
    event.preventDefault()
    handleSearch({
      startedAt: moment(inputState.startedAt).toISOString(),
      endedAt: moment(inputState.endedAt).endOf('day').toISOString(),
      deliveryType: inputState.deliveryType,
    })
  }

  const handleSelect = (event) => {
    const { name, value } = event.target
    let deliveryValue = ''
    switch (value) {
      case 'table':
        deliveryValue = ['table']
        break
      case 'takeawayDelivery':
        deliveryValue = ['takeaway', 'storeDelivery']
        break
    }
    setDeliveryState(value)
    setInputState({ ...inputState, [name]: deliveryValue })
  }

  const handleClear = (event) => {
    event.preventDefault()
    setInputState(defaultState)
    handleSearch({
      startedAt: moment(defaultState.startedAt).toISOString(),
      endedAt: moment(defaultState.endedAt).endOf('day').toISOString(),
    })
  }

  return (
    <TableSearchForm
      handleSearch={handleSearchMiddle}
      handleClear={handleClear}
    >
      <TextField
        label='Start date'
        name='startedAt'
        type='date'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.startedAt}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label='End date'
        name='endedAt'
        type='date'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.endedAt}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch(e)
          }
        }}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='demo-simple-select-outlined-label'>Type</InputLabel>
        <Select
          className={classes.selectControl}
          value={deliveryState}
          onChange={handleSelect}
          label='deliveryType'
          name='deliveryType'
        >
          <MenuItem value='table'>Dine-in</MenuItem>
          <MenuItem value='takeawayDelivery'>Takeaway / Delivery</MenuItem>
        </Select>
      </FormControl>
    </TableSearchForm>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  textField: {
    background: theme.palette.common.white,
  },
  selectControl: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gap: theme.spacing(1),
  },
  fileds: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: theme.spacing(1),
  },
}))
