import ActionTypes from './ActionTypes'
import customerApi from '@/libs/api/customer'
import moment from 'moment'

const getAdminName = (mail) => {
  const [,, name = ''] = mail?.match(/^(.+)\+(.+)@dimorder.com/) ?? []
  return name
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getRefunds () {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'getRefunds' },
    })
    try {
      const param = getState().refund.params
      const params = {
        page: param.page,
        ...param.startedAt && { startedAt: moment(param.startedAt).startOf('day').toISOString() },
        ...param.endedAt && { endedAt: moment(param.endedAt).endOf('day').toISOString() },
      }
      const response = await customerApi.getRefunds(params)
      const { data, meta } = response

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
      dispatch({
        type: ActionTypes.UPDATE_META,
        payload: { meta },
      })
    } catch (error) {
      console.log('getRefunds error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'getRefunds' },
      })
    }
  }
}

/**
 * @function
 * @param {IRefundParams} params
 * @returns {ThunkFunction}
 */
export function postRefund (params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'postRefund' },
    })
    try {
      return await customerApi.postRefund(params)
    } catch (error) {
      console.log('loadNextPage error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'postRefund' },
      })
    }
  }
}

/**
 * @function
 * @param { string } refundId
 * @param {{status: string}} params
 * @returns {ThunkFunction}
 */
export function approveRefund (refundId, params) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'APP/CREATE_API_REQUEST',
      payload: { apiName: 'approveRefund' },
    })
    try {
      return await customerApi.approveRefund(refundId, params)
    } catch (error) {
      console.log('loadNextPage error', error)
    } finally {
      dispatch({
        type: 'APP/FINISH_API_REQUEST',
        payload: { apiName: 'approveRefund' },
      })
    }
  }
}

/**
 * @function
 * @param {IRefundQueryParams} params
 * @returns {ThunkFunction}
 */
export function updateQuery (params) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: ActionTypes.UPDATE_PARAMS,
        payload: { params },
      })

      // 更新params後，重新抓取refunds
      dispatch(getRefunds())
    } catch (error) {
      console.log('loadNextPage error', error)
    }
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function exportRefunds () {
  return async (dispatch, getState) => {
    try {
      const param = getState().refund.params
      const params = {
        ...param.startedAt ? { startedAt: moment(param.startedAt).startOf('day').toISOString() } : { startedAt: moment().subtract(1, 'month').toISOString() },
        ...param.endedAt && { endedAt: moment(param.endedAt).endOf('day').toISOString() },
        limit: Number.MAX_SAFE_INTEGER,
      }
      const response = await customerApi.getRefunds(params)
      const stateMap = {
        done: 'refunded',
      }
      // create csv
      const header = [
        '提交日期',
        '申請人',
        '商戶名稱 Merchant Name',
        '訂單編號 Order Serial',
        '交易日期 Transaction Date',
        '訂單金額 Original Order Amount',
        '退款金額 Refund Amount',
        '退款原因 Refund Reason',
        '負責方 Refund Cost By',
        '接受方 Refund To',
        '退款類型 Refund Type',
        'Payment Method',
        'Refund status',
        'Approved By',
        'Date of Approval',
        'Approver\'s comment',
      ].join(',')
      const rows = response.data.map(e => {
        const columns = [
          e.createdAt ? moment(e.createdAt).format('YYYY-MM-DD HH:mm') : '',
          getAdminName(e.admin) || '',
          e.orderInfo?.merchantName || '',
          e.orderInfo?.orderSerial || '',
          e.orderInfo?.transactionDate ? moment(e.orderInfo?.transactionDate).format('YYYY-MM-DD HH:mm') : '',
          e.orderInfo?.orderAmount || 0,
          e.amount || 0,
          e.reason || '',
          e.costBy || '',
          e.receiver || '',
          e.type || '',
          e.orderInfo?.payment || '',
          stateMap[e.status] || e.status || '',
          getAdminName(e.reviewAdmin) || '',
          e.reviewedAt ? moment(e.reviewedAt).format('YYYY-MM-DD HH:mm') : '',
          e.reviewComment || '',
        ]
        return columns.map(value => `"${value}"`).join(',')
      })
      const csv = [header, ...rows].join('\n')
      // download
      const file = new Blob(['\uFEFF' + csv], { type: 'application/csv;charset=utf-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = 'refund.csv'
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (error) {
      console.log('getRefunds error', error)
    }
  }
}
