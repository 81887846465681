import CollapsibleList from '@/components/CollapsibleList'
import React from 'react'
import SettingControl from '../SettingControl'

const SETTINGS = [
  { label: '餐廳帳號 (shortCode)', paramaterName: 'account.username', type: 'input' },
  { label: '新密碼', paramaterName: 'account.password', type: 'input' },
]

/**
 *
 * @param {*} props
 * @returns
 */
export default function AccountSettings (props) {
  const [expand, setExpand] = React.useState(false)

  return (
    <CollapsibleList title='帳號設定' expand={expand} setExpand={setExpand}>
      {
        SETTINGS.map(setting => (
          <SettingControl key={setting.label} setting={setting} />
        ))
      }
    </CollapsibleList>
  )
}
