import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { TagPicker } from 'rsuite'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import { initialParams } from '@/redux/order/reducer'

import MerchantSearch from '@/components/MerchantSearch'
import TableSearchForm from '@/components/Table/TableSearchForm'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderSearchForm (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [inputState, setInputState] = useState(initialParams)
  const params = useSelector(state => state.order.params)
  const [merchant, setMerchant] = useState()

  useEffect(() => {
    if (params) {
      setInputState(prev => ({ ...prev, ...params }))
    }
  }, [params])

  const handleInputChange = (e) => {
    let { name, value } = e.currentTarget
    if (name === 'from' || name === 'to') {
      value = moment(value).toISOString()
    }
    setInputState(prev => ({ ...prev, [name]: value }))
  }

  const handleSearch = (e) => {
    e.preventDefault()
    dispatch(actions.order.updateQuery({ ...inputState, skip: 0 }))
    dispatch(actions.order.getOrders())
  }

  const handleClear = (e) => {
    e.preventDefault()
    dispatch(actions.order.updateQuery(initialParams))
    setMerchant(null)
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e)
    }
  }

  const getTagValue = (key) => {
    return _.chain(inputState[key]).split(',').without('').value()
  }

  const handleTagPick = (e, key) => {
    e = e || []
    setInputState(prev => ({ ...prev, [key]: e.join() }))
  }

  return (
    <TableSearchForm
      handleSearch={handleSearch}
      handleClear={handleClear}
      className={classes.root}
    >
      <MerchantSearch
        onChange={(o) => {
          setMerchant(o)
          setInputState(prev => ({ ...prev, merchantId: o?.id }))
        }}
        value={merchant}
        formControlProps={{ style: { margin: 0 } }}
      />
      <DeliveryTypeSelector inputState={inputState} setInputState={setInputState} />
      <StatusTagPicker getValue={getTagValue} onTagPick={handleTagPick} />
      <TakeawayStatusTagPicker getValue={getTagValue} onTagPick={handleTagPick} />
      <PlacedFromTagPicker getValue={getTagValue} onTagPick={handleTagPick} />
      <TextField
        label='Serial'
        name='serial'
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={inputState.serial}
        onKeyPress={onEnterPress}
        onChange={handleInputChange}
        InputProps={{ startAdornment: <InputAdornment position='start'>#</InputAdornment> }}
      />
      <TextField
        label='From'
        name='from'
        type='datetime-local'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={moment(inputState.from).format('YYYY-MM-DDTHH:mm')}
        onKeyPress={onEnterPress}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label='To'
        name='to'
        type='datetime-local'
        variant='outlined'
        size='small'
        fullWidth
        classes={{ root: classes.textField }}
        value={moment(inputState.to).format('YYYY-MM-DDTHH:mm')}
        onKeyPress={onEnterPress}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label='Phone'
        name='phone'
        type='tel'
        variant='outlined'
        size='small'
        fullWidth
        inputProps={{ maxLength: 8 }}
        classes={{ root: classes.textField }}
        value={inputState.phone}
        onKeyPress={onEnterPress}
        onChange={handleInputChange}
      />
    </TableSearchForm>
  )
}

/**
   * 根據選擇的 delivery type 篩選訂單
   * 外送 type=storedelivery
   * 外帶 type=takeaway
   * 堂食 type=table
   * 全部 type='' 或不帶
   * @returns
   */
function DeliveryTypeSelector (props) {
  const { inputState, setInputState } = props
  const classes = useStyles(props)
  const value = inputState.type === '' ? '*' : inputState.type // value='*' 用來顯示 ALL
  const handleSelect = (event) => {
    const { name, value } = event.target
    setInputState(prev => ({ ...prev, [name]: value === '*' ? '' : value })) // 不帶 type 後端會給全部 delivery type 的訂單
  }
  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel id='demo-simple-select-outlined-label'>Type</InputLabel>
      <Select
        className={classes.selectControl}
        value={value}
        onChange={handleSelect}
        label='Type'
        name='type'
      >
        <MenuItem value='storedelivery'>SDelivery</MenuItem>
        <MenuItem value='takeaway'>Takeaway</MenuItem>
        <MenuItem value='table'>Dine-in</MenuItem>
        <MenuItem value='*'>ALL</MenuItem>
      </Select>
    </FormControl>
  )
}

function StatusTagPicker ({ getValue, onTagPick }) {
  const key = 'status'
  const value = getValue(key)
  const STATUS_LIST = [
    { value: 'pending', label: 'Pending' },
    { value: 'paid', label: 'Paid' },
    { value: 'cancelled', label: 'Cancelled' },
  ]
  return (
    <TagPicker
      onChange={e => onTagPick(e, 'status')}
      value={value}
      data={STATUS_LIST}
      placeholder='Status'
    />
  )
}

function TakeawayStatusTagPicker ({ getValue, onTagPick }) {
  const key = 'takeawayStatus'
  const value = getValue(key)
  const TAKEAWAY_STATUS_LIST = [
    { value: 'pending', label: 'Pending' },
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'ready', label: 'Ready' },
    { value: 'completed', label: 'Completed' },
    { value: 'delivered', label: 'Delivered' },
    { value: 'cancelled', label: 'Cancelled' },
  ]
  return (
    <TagPicker
      onChange={e => onTagPick(e, key)}
      value={value}
      data={TAKEAWAY_STATUS_LIST}
      placeholder='Takeaway Status'
    />
  )
}

function PlacedFromTagPicker ({ getValue, onTagPick }) {
  const key = 'orderFrom'
  const value = getValue(key)
  const PLACED_FROM_LIST = [
    { value: 'CUSTOMER_APP', label: 'Customer App' },
    { value: 'CUSTOMER_WEB', label: 'Customer Web' },
    { value: 'ADMIN', label: 'Admin' },
    { value: 'SERVER', label: 'Server' },
    { value: 'MERCHANT', label: 'Merchant' },
  ]
  return (
    <TagPicker
      onChange={e => onTagPick(e, 'orderFrom')}
      value={value}
      data={PLACED_FROM_LIST}
      placeholder='From'
    />
  )
}

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiInputBase-formControl': {
      background: theme.palette.common.white,
    },
  },
  formControl: {
    // background: theme.palette.common.white,
  },
  selectControl: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingBottom: '10.5px',
      paddingTop: '10.5px',
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
  },
  fileds: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: theme.spacing(1),
  },
  merchantNameControl: {
    marginTop: theme.spacing(-1),
  },
  root: {
    flex: 1,
  },
}))
