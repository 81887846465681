import * as app from './app/actions'
import * as auth from './auth/actions'
import * as customer from './customer/actions'
import * as dashboard from './dashboard/actions'
import * as deliveryOrder from './deliveryOrder/actions'
import * as dimbox from './dimbox/actions'
import * as driver from './driver/actions'
import * as merchant from './merchant/actions'
import * as order from './order/actions'
import * as posPayLink from './posPayLink/actions'
import * as promoCode from './promoCode/actions'
import * as refund from './refund/actions'
import * as report from './report/actions'
import * as riceCoin from './riceCoin/actions'
import * as setting from './setting/actions'
import * as sms from './sms/actions'
import * as user from './user/actions'

export default {
  app,
  auth,
  customer,
  dashboard,
  deliveryOrder,
  dimbox,
  driver,
  merchant,
  order,
  promoCode,
  refund,
  report,
  riceCoin,
  sms,
  user,
  setting,
  posPayLink,
}
