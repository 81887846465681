import ActionTypes from './ActionTypes'
import moment from 'moment'
import produce from 'immer'

/** @type {IOrderQueryParams} */
export const initialParams = {
  type: 'storedelivery',
  sort: 'createdat|desc',
  timeProp: 'createdat',
  from: moment().startOf('day').toISOString(),
  limit: 10,
  skip: 0,
  orderFrom: 'CUSTOMER_APP,CUSTOMER_WEB,ADMIN,SERVER',
  to: '',
  takeawayStatus: '',
  status: '',
  serial: '',
  phone: '',
  merchantId: '',
}

export const initialTags = {
  data: [],
  meta: {},
}
/** @type {IOrderState} */
export const initialState = {
  isInit: false,
  data: [],
  meta: {
    perPage: 10,
    currentPage: 1,
    total: 0,
  },
  params: initialParams,
  tags: initialTags,
  pendingAlertSoundToggle: false,
}

export default produce(
  /**
   *  @param {IOrderState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.RESET_DATA: {
        return initialState
      }
      case ActionTypes.UPDATE_DATA: {
        const { data } = action.payload
        draft.data = data
        break
      }
      case ActionTypes.UPDATE_META: {
        draft.meta = { ...draft.meta, ...action.payload }
        break
      }
      case ActionTypes.UPDATE_PARAMS: {
        const { params } = action.payload
        draft.params = params
        draft.meta.currentPage = (params.skip / draft.meta.perPage) + 1
        break
      }
      case ActionTypes.UPDATE_PENDING_ALERT_SOUND_TOGGLE: {
        const { data } = action.payload
        draft.pendingAlertSoundToggle = data
        break
      }
      default:
        break
    }
  },
  initialState,
)
