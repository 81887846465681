import { Button, CircularProgress, Switch, Typography } from '@material-ui/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { useMerchant } from '@/hooks/merchantContext'
import ControlledTable from '@/components/Table/ControlledTable'
import React from 'react'

/**
 *
 * @param {*} props
 * @returns
 */
export default function MerchantTable (props) {
  const dispatch = useDispatch()
  const merchants = useSelector(state => state.merchant.data)
  const meta = useSelector(state => state.merchant.meta)
  const params = useSelector(state => state.merchant.params)
  const classes = useStyles(props)
  const [, setMerchant] = useMerchant()

  const QrCodeButtonLoader = () => {
    return (
      <CircularProgress
        color='inherit'
        size={24.5}
      />
    )
  }

  const QrCodeButton = ({ merchant }) => {
    const [loading, setLoading] = React.useState(false)
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        disabled={loading}
        onClick={() => {
          setLoading(true)
          dispatch(actions.merchant.getMerchantQRcode({
            merchantName: merchant.name,
            merchantId: merchant.id,
            tableGroups: merchant.tableGroups,
            setLoading,
          }))
        }}
      >
        {
          loading
            ? <QrCodeButtonLoader />
            : 'QRCODE'
        }
      </Button>
    )
  }

  const QrCodeButtonFromAPI = ({ merchant }) => {
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        onClick={() => {
          dispatch(actions.merchant.getMerchantQRcodeFromAPI(merchant.id))
        }}
      >
        QRCODE(WITH BACKGROUND)
      </Button>
    )
  }

  const EditButton = ({ merchant }) => {
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        onClick={() => {
          setMerchant('openEditDialog', true)
          setMerchant('merchant', merchant)
        }}
      >
        EDIT
      </Button>
    )
  }

  const LinkButton = ({ merchant }) => {
    return (
      <Button
        disableElevation
        variant='contained'
        color='primary'
        onClick={() => {
          setMerchant('openLinkDialog', true)
          setMerchant('merchant', merchant)
        }}
      >
        LINK
      </Button>
    )
  }

  function ActionButtons (merchant) {
    return (
      <div className={classes.actionButtons}>
        <QrCodeButton merchant={merchant} />
        <QrCodeButtonFromAPI merchant={merchant} />
        <EditButton merchant={merchant} />
        <LinkButton merchant={merchant} />
      </div>
    )
  }

  const DisableSwitch = (merchant) => {
    return (
      <Switch checked={!merchant.disabled} onChange={(event) => handleSwitchChange(event, merchant.id)} color='primary' />
    )
  }

  React.useEffect(() => {
    dispatch(actions.merchant.resetMerchants())
    dispatch(actions.merchant.getMerchants())
  }, [])

  const handleSwitchChange = async (event, merchantId) => {
    const { checked } = event.currentTarget
    await dispatch(actions.merchant.disableMerchant(merchantId, !checked))
    dispatch(actions.merchant.getMerchants())
  }

  const onPageChange = async (event, page) => {
    await dispatch(actions.merchant.updateQuery({ ...params, skip: page * meta.perPage }))
    dispatch(actions.merchant.getMerchants())
  }

  const columns = [
    {
      Header: 'Merchant',
      accessor: row => {
        return (
          <div>
            <Typography variant='h6'>{row.name}</Typography>
            <Typography className={classes.caption} variant='caption'>{row.shortCode}</Typography>
            <Typography color='primary'>{row.contact}</Typography>
            <Typography>{row.address}</Typography>
          </div>
        )
      },
    },
    { Header: 'Staff App', accessor: 'setting.staff', Cell: ({ value }) => value ? '啟用' : '關閉' },
    { Header: 'Takeaway', accessor: 'takeawayDisabled', Cell: ({ value }) => !value ? '啟用' : '關閉' },
    { Header: 'Sdelivery', accessor: 'setting.storeDelivery', Cell: ({ value }) => value ? '啟用' : '關閉' },
    { Header: 'Remark', accessor: 'setting.remark' },
    {
      Header: 'Action',
      accessor: row => ActionButtons(row),
    },
    { Header: 'Enabled', accessor: row => DisableSwitch(row) },
  ]

  return (
    <ControlledTable
      columns={columns}
      data={merchants}
      pageSize={meta.perPage}
      currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
      rowCount={meta.total}
      onPageChange={onPageChange}
    />
  )
}

const useStyles = makeStyles(theme => ({
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  caption: {
    marginBottom: theme.spacing(0.5),
  },
}))
