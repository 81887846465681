import ActionTypes from './ActionTypes'
import AppActionTypes from '../app/ActionTypes'
import nodeApi from '@/libs/api/node'
/**
 * @function
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function getSettings () {
  const apiName = 'getSettings'
  return async (dispatch, getState) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    const settingNames = [
      'takeaway', 'storedelivery', 'busymode', 'payme_gateway', 'credit_card_gateway',
    ]
    try {
      const data = await Promise.all(
        settingNames.map(name => nodeApi.getSystemSettings(name)),
      ).then(resolves => {
        return Object.fromEntries(resolves.map((resolve, index) => {
          return [settingNames[index], resolve]
        }))
      })
      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data },
      })
    } catch (error) {
      console.log('getSettings error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}

/**
 * @param {string} settingType
 * @param {object} params
 * @returns {ThunkFunction}
 */
export function putSystemSettings (settingType, params) {
  const apiName = 'putSystemSettings'
  return async (dispatch) => {
    dispatch({ type: AppActionTypes.CREATE_API_REQUEST, payload: { apiName } })
    try {
      await nodeApi.putSystemSettings(settingType, params)
    } catch (error) {
      console.log('putSystemSettings error', error)
    } finally {
      dispatch({ type: AppActionTypes.FINISH_API_REQUEST, payload: { apiName } })
    }
  }
}
