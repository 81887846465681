import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import ExportButton from '@/components/ExportButton'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'

import ChangePageButton from './ChangePageButton'
import DimobxTable from './DimboxTable'
import SummaryTable from './SummaryTable'

/**
 *
 * @param {*} props
 * @returns
 */
export default function Dimbox (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const orders = useSelector(state => state.order.data) ?? []
  const dimboxes = useSelector(state => state.dimbox.dimboxes) ?? []
  const [pageIndex, setPageIndex] = React.useState(0)
  const handleSwitchTable = React.useCallback(() => {
    if (pageIndex) {
      setPageIndex(0)
    } else {
      setPageIndex(1)
    }
  }, [pageIndex])
  const pageTitle = React.useMemo(() => {
    return pageIndex
      ? `(${moment().format('YYYY-MM-DD')}) Dimbox Orders: Summary`
      : `(${moment().format('YYYY-MM-DD')}) Dimbox Orders`
  }, [pageIndex])
  const buttonTitle = React.useMemo(() => {
    return pageIndex ? 'Orders' : 'Summary'
  }, [pageIndex])

  React.useEffect(() => {
    dispatch(actions.order.resetOrders())
    /** @type {IOrderQueryParams} */
    const params = {
      // 找出所有取餐時間為當天 12:00~14:30 的自取訂單
      type: 'takeaway',
      sort: 'pickupat|desc',
      timeProp: 'pickupat',
      from: moment().startOf('d').hours(12).minute(0).toISOString(),
      to: moment().startOf('d').hours(14).minute(30).toISOString(),
      tagName: 'dimbox',
    }
    dispatch(actions.order.getOrders(params))
    dispatch(actions.dimbox.updateDimboxes())
  }, [])

  const dimboxOrders = React.useMemo(() => {
    // 有 dimbox 訂單的話從 dimboxes 中過濾出該訂單的 boxes 並放到訂單內
    return orders.map(order => {
      const boxes = dimboxes.filter(box => box?.orderId === order?.id)
      return { ...order, boxes }
    })
  }, [orders])

  const dimboxOrderSummary = React.useMemo(() => {
    const groupItemNamesByMerchantId = _.reduce(dimboxOrders, (acc, order) => {
      // pending 代表還沒有接單 所以不應該出現在統計內
      if (order.takeawayStatus === 'pending') return acc

      // 把 order.batches 內的 item 壓縮成只有名字的 array
      // 如有同一個 item 有兩個就重複名字兩次，以此類推
      const orderItemNames = _.reduce(order.batches, (acc, batch) => {
        const names = _.reduce(batch.items, (acc, item) => {
          if (!item?.name) return acc
          for (let i = 0; i < item.quantity; i++) {
            acc.push(item.name)
          }
          return acc
        }, [])

        acc.push(...names)
        return acc
      }, [])

      // 以 merchantId 分組歸類
      if (!acc[order.merchantId]) {
        acc[order.merchantId] = {
          merchantId: order.merchantId,
          merchantName: order.merchantName,
          merchantAddress: order.merchantAddress,
          itemNames: orderItemNames,
        }
      } else {
        acc[order.merchantId].itemNames.push(...orderItemNames)
      }
      return acc
    }, {})

    const summary = _.map(groupItemNamesByMerchantId, merchant => {
      const counts = _.countBy(merchant.itemNames)
      const uniques = _.uniq(merchant.itemNames)
      const itemNameWithCounts = _.map(uniques, itemName => itemName + ' x ' + counts[itemName])
      return { ...merchant, itemSummary: itemNameWithCounts }
    })

    return summary
  }, [dimboxOrders])

  const handleExport = React.useCallback(() => {
    if (pageIndex) {
      dispatch(actions.dimbox.exportDimboxOrderSummary(dimboxOrderSummary))
    } else {
      dispatch(actions.dimbox.exportDimboxOrders(dimboxOrders))
    }
  }, [pageIndex])

  return (
    <Page>
      <PageHeader text={pageTitle}>
        <div className={classes.headerButtonWrap}>
          <ChangePageButton title={buttonTitle} onClick={handleSwitchTable} pageIndex={pageIndex} />
          <ExportButton onClick={handleExport} />
        </div>
      </PageHeader>
      {
          pageIndex
            ? <SummaryTable data={dimboxOrderSummary} />
            : <DimobxTable data={dimboxOrders} />
        }
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  headerButtonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
}))
