import { initialParams } from '@/redux/order/reducer'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import VolumeMuteIcon from '@material-ui/icons/VolumeMute'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import _ from 'lodash'
import cx from 'classnames'

import { IconButton } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { useSelector } from 'react-redux'

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderQuickSearch (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const params = useSelector(state => state.order.params)
  const pendingAlertSoundToggle = useSelector(state => state.order.pendingAlertSoundToggle)

  const queries = {
    PENDING_DELIVERY: {
      ...initialParams,
      type: 'storedelivery',
      status: 'paid',
      takeawayStatus: 'pending',
    },
    PENDING_TAKEAWAY: {
      ...initialParams,
      type: 'takeaway',
      status: 'paid',
      takeawayStatus: 'pending',
    },
  }

  const handleQuickSearch = (type) => {
    dispatch(actions.order.updatePendingAlertSoundToggle(true))
    dispatch(actions.order.updateQuery(queries[type]))
    dispatch(actions.order.getOrders())
  }
  const toggleSound = () => {
    dispatch(actions.order.updatePendingAlertSoundToggle(!pendingAlertSoundToggle))
  }

  return (
    <div className={classes.container}>
      <button className={cx(classes.quickSearchButton, { [classes.active]: _.isEqual(params, queries.PENDING_DELIVERY) })} onClick={() => handleQuickSearch('PENDING_DELIVERY')}>Pending Delivery</button>
      <button className={cx(classes.quickSearchButton, { [classes.active]: _.isEqual(params, queries.PENDING_TAKEAWAY) })} onClick={() => handleQuickSearch('PENDING_TAKEAWAY')}>Pending Takeaway</button>
      <IconButton onClick={toggleSound}>
        {
          pendingAlertSoundToggle
            ? <VolumeUpIcon />
            : <VolumeMuteIcon />
        }
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    // marginTop: 8,
    marginBottom: 8,
  },
  quickSearchButton: {
    lineHeight: 3,
    borderRadius: 25,
    padding: '0 8px',
    marginRight: 16,
    '&:hover': {
      backgroundColor: '#faefba',
    },
    '&:active': {
      backgroundColor: '#f5e23b',
    },
  },
  active: {
    backgroundColor: '#FFEB3B',
    '&:hover': {
      backgroundColor: '#FFEB3B',
    },
  },

}))
