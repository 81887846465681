import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import ControlledTable from '@/components/Table/ControlledTable'
import DeliveryOrderProgressBar from '@/components/DeliveryOrderProgressBar'

import CancelDeliveryOrderDialog from './dialogs/CancelDeliveryOrderDialog'
import CopyDeliveryOrderDialog from './dialogs/CopyDeliveryOrderDialog'
import LalamoveOprationDialog from './dialogs/LalamoveOprationDialog'
import RemarksEditDialog from './dialogs/RemarksEditDialog'
import ReplaceLalamoveOrderIdDialog from './dialogs/ReplaceLalamoveOrderIdDialog'
import hooks from '@/hooks'

/**
 *
 * @param {{
 * drivers: IDriver[]
 * driverOrderStatusMap: object
 * deliveryOrders: IDeliveryOrder[]
 * total: number
 * }} props
 * @returns
 */
export default function DeliveryOrderTable (props) {
  const { drivers, driverOrderStatusMap, deliveryOrders, total } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const meta = useSelector(state => state.deliveryOrder.meta)
  const user = hooks.auth.useUser()

  const [copyDeliveryOrderDialogOpen, setCopyDeliveryOrderDialogOpen] = React.useState(false)
  const [cancelDeliveryOrderDialogOpen, setCancelDeliveryOrderDialogOpen] = React.useState(false)
  const [remarksEditDialogOpen, setRemarksEditDialogOpen] = React.useState(false)
  const [lalamoveOprationDialogOpen, setLalamoveOprationDialogOpen] = React.useState(false)
  const [replaceLalamoveOrderIdDialogOpen, setReplaceLalamoveOrderIdDialogOpen] = React.useState(false)

  const [selectedOrderId, setSelectedOrderId] = React.useState('')
  const selectedOrder = React.useMemo(() => {
    return _.find(deliveryOrders, deliveryOrder => deliveryOrder.id === selectedOrderId)
  }, [deliveryOrders, selectedOrderId])

  /**
   *
   * @param {IDeliveryOrder} order
   * @returns
   */
  function DriverSelector (order) {
    const handleChange = (e) => {
      const driverId = e.target.value
      dispatch(actions.deliveryOrder.assignDriver(order.id, driverId))
    }

    const disabled = (
      order.provider !== 'DIMORDER' || // 當 Lalamove ON_GOING 後 provider 會被設成 LALAMOVE，同時將 Dimorder 狀態設為 CANCELED 並清空 driverId，這時不應再讓營運做沒有意義的司機指派，因為 DriverApp 上不會看到 provider 非 DIMORDER 的外送單
      (order.provider === 'DIMORDER' && order.providers.DIMORDER.status === 'COMPLETED') // 當已完成訂單時不可更改 driverId
    )

    return (
      <FormControl variant='outlined' fullWidth className={classes.formControl}>
        <InputLabel margin='dense'>Driver</InputLabel>
        <Select
          className={classes.selectControl}
          classes={{ select: classes.select }}
          value={order.providers.DIMORDER.driverId ?? ''}
          onChange={handleChange}
          label='Driver'
          name='driver'
          margin='dense'
          disabled={disabled}
        >
          {
            _.map(drivers, (driver, index) => {
              const statusMap = driverOrderStatusMap[driver?.id]
              return (
                <MenuItem key={index} value={driver.id} className={classes.driverMenuItem}>
                  <div>
                    <Typography style={{ flex: 1 }}>{driver.name}</Typography>
                    <Typography variant='body2'><i>{driver.contact}</i></Typography>
                  </div>
                  <div className={classes.countsWrap}>
                    <Typography color='secondary'>{_.size(statusMap?.ON_GOING)}</Typography>
                    <Typography className={classes.divider}>/</Typography>
                    <Typography color='secondary'>{_.size(statusMap?.PICKED_UP)}</Typography>
                    <Typography className={classes.divider}>/</Typography>
                    <Typography color='secondary'>{_.size(statusMap?.COMPLETED)}</Typography>
                  </div>
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }

  /**
   *
   * @param {IDeliveryOrder} order
   * @returns
   */
  function CancelButton (order) {
    const handleClick = () => {
      setCancelDeliveryOrderDialogOpen(true)
      setSelectedOrderId(order.id)
    }

    const disabled = () => {
      const conditions = [
        !_.includes(['ASSIGNING_DRIVER', 'ON_GOING'], order.providers?.LALAMOVE?.status),
        !_.includes(['ASSIGNING_DRIVER', 'ON_GOING'], order.providers?.DIMORDER?.status),
      ]
      return _.every(conditions, condition => condition)
    }

    return (
      <Button
        color='primary'
        variant='contained'
        disableElevation
        onClick={handleClick}
        disabled={disabled()}
      >
        Cancel
      </Button>
    )
  }

  /**
   *
   * @param {IDeliveryOrder} order
   * @returns
   */
  function CopyButton (order) {
    const handleClick = () => {
      setCopyDeliveryOrderDialogOpen(true)
      setSelectedOrderId(order.id)
    }
    return (
      <Button
        color='primary'
        variant='contained'
        disableElevation
        onClick={handleClick}
      >
        Copy
      </Button>
    )
  }

  /**
   *
   * @param {IDeliveryOrder} order
   * @returns
   */
  function UrgentButton (order) {
    const handleClick = () => {
      dispatch(actions.deliveryOrder.updateDeliveryOrder(order.id, { urgent: !order.urgent }))
    }
    return (
      <Button
        color={order.urgent ? 'secondary' : 'primary'}
        variant='contained'
        disableElevation
        onClick={handleClick}
      >
        Urgent
      </Button>
    )
  }

  const columns = [
    {
      Header: 'Actions',
      accessor: function ActionsAccessor (row, i) {
        /** @type {IDeliveryOrder} */
        const o = row
        return (
          <div className={classes.accessorColumn}>
            {user.role === 'SUPER_ADMIN' && o.id}
            {CopyButton(o)}
            {CancelButton(o)}
            {UrgentButton(o)}
            {/* {RemoveButton(o)} */}
          </div>
        )
      },
    },
    {
      Header: 'DimOrder',
      accessor: function DimorderProviderAccessor (row, i) {
        /** @type {IDeliveryOrder} */
        const o = row
        return (
          <div className={classes.accessorColumn} style={{ alignItems: 'center' }}>
            {DriverSelector(o)}
            <Typography
              variant='caption'
              color={
                o.provider === 'DIMORDER' &&
                _.includes(['ASSIGNING_DRIVER', 'ON_GOING', 'PICKED_UP', 'COMPLETED'], o.providers.DIMORDER.status)
                  ? 'secondary'
                  : undefined
              }
            >
              <i>{o.providers.DIMORDER.status}</i>
            </Typography>
          </div>
        )
      },
    },
    {
      Header: 'From',
      accessor: function MerchantAccessor (row, i) {
        /** @type {IDeliveryOrder} */
        const o = row
        const starts = [o.start.area, o.start.address, o.start.building, o.start.floorRoom]
        const startAddress = _.chain(starts).without('', undefined).join(', ').value()
        const startTimeEstimated = moment(o.start.timeEstimated).format('YYYY-MM-DD HH:mm')
        return (
          <div className={classes.accessorColumn}>
            <Typography variant='caption'><i>{startTimeEstimated}</i></Typography>
            <Typography variant='body2'><b>{o.start.contactName}</b></Typography>
            <Typography variant='body2'><i>{o.start.contactPhone}</i></Typography>
            <Typography variant='body2'><i>{startAddress}</i></Typography>
          </div>
        )
      },
    },
    {
      Header: 'To',
      accessor: function CustomerAccessor (row, i) {
        /** @type {IDeliveryOrder} */
        const o = row
        const ends = [o.end.area, o.end.address, o.end.building, o.end.floorRoom]
        const endAddress = _.chain(ends).without('', undefined).join(', ').value()
        const endTimeEstimated = moment(o.end.timeEstimated).format('YYYY-MM-DD HH:mm')
        return (
          <div className={classes.accessorColumn}>
            <Typography variant='caption'><i>{endTimeEstimated}</i></Typography>
            <Typography variant='body2'><b>{o.end.contactName}</b></Typography>
            <Typography variant='body2'><i>{o.end.contactPhone}</i></Typography>
            <Typography variant='body2'><i>{endAddress}</i></Typography>
          </div>
        )
      },
    },
    {
      Header: 'Remarks',
      accessor: function RemarksAccessor (row, i) {
        /** @type {IDeliveryOrder} */
        const o = row
        return (
          <div className={classes.accessorColumn} style={{ maxWidth: 300 }}>
            <Typography variant='body2'>
              {o.remarks}
            </Typography>
            <IconButton
              size='small'
              style={{ alignSelf: 'flex-start' }}
              onClick={() => {
                setRemarksEditDialogOpen(true)
                setSelectedOrderId(o.id)
              }}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </div>
        )
      },
    },
  ]

  const handlePageChange = (event, page) => {
    dispatch(actions.deliveryOrder.updateMeta({ currentPage: page + 1 }))
  }
  const handleRowsPerPageChange = (event) => {
    dispatch(actions.deliveryOrder.updateMeta({
      perPage: event.target.value,
      currentPage: 1,
    }))
  }

  return (
    <>
      <ControlledTable
        columns={columns}
        data={deliveryOrders}
        pageSize={meta.perPage}
        currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
        rowCount={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50]}
        tableBodyProps={{
          tableRowProps: {
            renderDeliveryOrderProgressBar: (index) => (
              <DeliveryOrderProgressBar
                deliveryOrder={deliveryOrders[index]}
                setLalamoveOprationDialogOpen={setLalamoveOprationDialogOpen}
                setSelectedOrderId={setSelectedOrderId}
              />
            ),
          },
        }}
      />
      <CopyDeliveryOrderDialog
        open={copyDeliveryOrderDialogOpen}
        handleClose={() => {
          setCopyDeliveryOrderDialogOpen(false)
          setSelectedOrderId('')
        }}
        selectedOrder={selectedOrder}
      />
      <CancelDeliveryOrderDialog
        open={cancelDeliveryOrderDialogOpen}
        handleClose={() => {
          setCancelDeliveryOrderDialogOpen(false)
          setSelectedOrderId('')
        }}
        selectedOrder={selectedOrder}
      />
      <RemarksEditDialog
        open={remarksEditDialogOpen}
        handleClose={() => {
          setRemarksEditDialogOpen(false)
          setSelectedOrderId('')
        }}
        selectedOrder={selectedOrder}
      />
      <LalamoveOprationDialog
        open={lalamoveOprationDialogOpen}
        handleClose={() => {
          setLalamoveOprationDialogOpen(false)
          setSelectedOrderId('')
        }}
        selectedOrder={selectedOrder}
        setReplaceLalamoveOrderIdDialogOpen={setReplaceLalamoveOrderIdDialogOpen}
      />
      <ReplaceLalamoveOrderIdDialog
        open={replaceLalamoveOrderIdDialogOpen}
        handleClose={() => {
          setReplaceLalamoveOrderIdDialogOpen(false)
        }}
        selectedOrder={selectedOrder}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  selectControl: {

  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  accessorColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  countsWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
  },
  divider: {
    color: '#d1d1d1',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  driverMenuItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
