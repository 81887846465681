import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import ReceiptButton from '@/components/ReceiptButton'
import moment from 'moment'

/**
 *
 * @typedef TitleColumnProps
 * @property {IDeliveryOrder} deliveryOrder
 * @param {TitleColumnProps} props
 * @returns
 */
export default function TitleColumn (props) {
  const { deliveryOrder: o } = props
  const classes = useStyles(props)
  const createdAt = moment(o.createdAt).format('YYYY-MM-DD HH:mm')

  function SubtotalRow () {
    const subtotal = o.orderPrices?.subtotal ?? 0 // 0.12 版後端新增的欄位，用以讓 user 可以推測可能有很多東西要運送
    const shouldHighlight = subtotal >= 1500
    const subtotalColor = shouldHighlight ? 'secondary' : 'textPrimary'
    const fontWeightStyle = { fontWeight: shouldHighlight ? 'bold' : undefined }
    if (!subtotal) return null
    return <Typography variant='body1' color={subtotalColor} style={fontWeightStyle}>${subtotal}</Typography>
  }

  return (
    <div className={classes.container}>
      <div className={classes.codeWrap}>
        <Typography variant='h6'><i>{o.code}</i></Typography>
        {Boolean(o.breakBulkSerial) && <Typography variant='h6' color='secondary'><i>&nbsp;#{o.breakBulkSerial}</i></Typography>}
      </div>
      <ReceiptButton orderSerial={o.orderSerial} orderId={o.orderId} style={{ paddingTop: 0, paddingBottom: 0 }} />
      <SubtotalRow />
      <Typography variant='caption'><i>{createdAt}</i></Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  codeWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
