import { createTheme } from '@material-ui/core/styles'
const fontFamily = [
  'Poppins',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Roboto',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

const theme = createTheme({
  // shadows: {
  //   boxShadow: 0,
  // },
  typography: {
    htmlFontSize: 10,
    fontFamily,
    h4: { fontWeight: 'bold' },
    h5: { fontWeight: 'bold' },
  },
  palette: {
    primary: {
      main: '#E89845',
      // contrastText: '#533633',
      contrastText: '#fafafa',
    },
    disabled: {
      main: '#e0e0e0',
      contrastText: '#9e9e9e',
    },
    text: {
      primary: '#533633',
      secondary: '#757575',
    },
    background: {
      default: '#fafafa',
    },
  },
  overrides: {
  },
})

export default theme
