import {
  Button,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { plans } from '@/libs/common'
import { useDialog } from '@/hooks/app'
import Dialog from '@/components/Dialog'
import React from 'react'
import fixPrecision from '@root/src/libs/math'
import * as _ from 'lodash'
const defaultState = {
  merchantName: '',
  plan: '',
  period: '',
  amount: '',
}

export default function AddPosPayLinkDialog (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { open } = useDialog('addPayLink')
  const [inputState, setInputState] = React.useState(defaultState)

  const handleClose = () => {
    dispatch(actions.app.toggleDialog('addPayLink'))
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setInputState({ ...inputState, [name]: value })
  }

  const handleSubmit = async () => {
    // all input states are required
    if ([inputState.merchantName, inputState.plan, inputState.period, inputState.amount].some(o => !o)) {
      return window.alert('Please fill in all fields')
    }
    const amount = +inputState.amount
    if (isNaN(amount) || amount < 0) {
      return window.alert('Amount must be number')
    }
    inputState.amount = +fixPrecision(inputState.amount).toString()
    const param = {
      ...inputState,
      description: [inputState.merchantName, inputState.plan, inputState.period].join('_'),
    }
    await dispatch(actions.app.toggleDialog('addPayLink'))
    await dispatch(actions.posPayLink.createPosPayLink(param)).catch(e => {
      const errorMessage = _.get(e, 'response.data.message') || e
      window.alert(errorMessage)
    })
    await dispatch(actions.posPayLink.getPosPayLinks())
  }

  return (
    <Dialog
      title='Add Pay Link'
      open={open}
      handleClose={handleClose}
      renderActions={() => {
        return (
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )
      }}
    >
      <div className={classes.box}>
        <div className={classes.inputLabel}>Merchant Name*</div>
        <TextField
          variant='outlined'
          size='small'
          name='merchantName'
          fullWidth
          classes={{ root: classes.textField }}
          value={inputState.merchantName}
          onChange={handleChange}
        />
        <div className={classes.inputLabel}>Plan*</div>
        <Select
          value={inputState.plan}
          label='Plan'
          name='plan'
          onChange={(event) => {
            event.currentTaget = event.target
            handleChange(event)
          }}
        >
          {plans.map((o, i) => {
            return <MenuItem key={i} value={o.name}>{o.name}</MenuItem>
          })}
        </Select>
        <div className={classes.inputLabel}>Period*</div>
        <TextField
          variant='outlined'
          size='small'
          name='period'
          fullWidth
          classes={{ root: classes.textField }}
          value={inputState.period}
          onChange={handleChange}
        />
        <div className={classes.inputLabel}>Amount*</div>
        <TextField
          variant='outlined'
          size='small'
          name='amount'
          fullWidth
          classes={{ root: classes.textField }}
          value={inputState.amount}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    maxWidth: '100%',
    width: 500,
    margin: '0 auto',
    '& > *': {
      width: '100%',
    },
  },
  planSelecter: {
    width: '100%',
  },
  inputLabel: {
    paddingTop: 16,
  },
}))
