import ActionTypes from './ActionTypes'
import produce from 'immer'

/** @type {IOrderQueryParams} */
export const initialParams = {
  limit: 10,
  skip: 0,
}
/** @type {IReportState} */
export const initialState = {
  isInit: false,
  job: {
    data: [],
    meta: {
      perPage: 10,
      currentPage: 1,
      total: 0,
    },
    params: initialParams,
  },
}

export default produce(
  /**
   *  @param {IReportState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_JOB_DATA: {
        const { data } = action.payload
        draft.job.data = data
        break
      }
      case ActionTypes.UPDATE_JOB_META: {
        draft.job.meta = { ...draft.job.meta, ...action.payload }
        break
      }
      case ActionTypes.UPDATE_JOB_PARAMS: {
        const { params } = action.payload
        draft.job.params = params
        draft.job.meta.currentPage = (params.skip / draft.job.meta.perPage) + 1
        break
      }
      default:
        break
    }
  },
  initialState,
)
