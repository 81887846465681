import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import moment from 'moment'

const StatisticsBox = (props) => {
  const classes = useStyles(props)
  const data = props.data || {}
  return (
    <div className={classes.box}>
      <h4>{props.title}</h4>
      <p>Quantity: {data.delivered || 0}</p>
      <p>Price: $ {data.price || 0}</p>
    </div>
  )
}

/**
 *
 * @param {*} props
 * @returns
 */
export default function SMSStatistics (props) {
  const dispatch = useDispatch()
  const [statistics, setStatistics] = React.useState({ thisMonth: {}, lastMonth: {} })
  React.useEffect(() => {
    const getSMSOutbound = async () => {
      const param = {
        started_at: moment().subtract(1, 'months').date(1).startOf('day').toISOString(),
        ended_at: moment().endOf('month').endOf('day').toISOString(),
      }
      const thisMonth = moment().format('yyyy_MM')
      const lastMonth = moment().subtract(1, 'months').format('yyyy_MM')
      const result = await dispatch(actions.sms.getSMSOutbound(param))
      setStatistics({ thisMonth: result[thisMonth], lastMonth: result[lastMonth] })
    }
    getSMSOutbound()
  }, [])
  return (
    <div className='d-flex'>
      <StatisticsBox data={statistics.thisMonth} title='This month' />
      <StatisticsBox data={statistics.lastMonth} title='Last month' />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  box: {
    marginRight: '16px',
    marginBottom: '16px',
    padding: '16px 32px',
    background: 'white',
    borderRadius: theme.spacing(3),
  },
}))
