import { FaUser, FaUsers } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { actions, useDispatch, useSelector } from '@/redux'
import ControlledTable from '@/components/Table/ControlledTable'

import TagEditor from './TagEditor'
import TagSelector from './TagSelector'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CustomerTable (props) {
  const classes = useStyles(props)
  const history = useHistory()
  const dispatch = useDispatch()
  const customers = useSelector(state => state.customer.data)
  const meta = useSelector(state => state.customer.meta)
  const params = useSelector(state => state.customer.params)
  const referrerName = useSelector(state => state.customer.referrerName)
  /**
   *
   * @param {ICustomer}
   * @return {JSX.Element}
   */
  const ReferrerCell = ({ referrer, referees, id, name }) => {
    return (
      <>
        {referrer.id && <div onClick={() => handleReferrerClick(referrer.id, referrer.name)} className={classes.referrer}><div className={classes.Icon}>{FaUser()}</div><div className={classes.hover}>{referrer.name}</div></div>}
        {referees !== 0 && <div onClick={() => handleReferrerClick(id, name)} className={classes.referrer}><div className={classes.Icon}>{FaUsers()}</div><div className={classes.hover}>{referees}</div></div>}
      </>
    )
  }
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const handleTagEdit = () => {
    setEditDialogOpen(true)
  }
  const handleTagEditClose = () => {
    setEditDialogOpen(false)
  }
  const tagHeader = () => {
    return (
      <div className='d-flex align-center'>
        <p className='mr-2'> Tags</p>
        <Button
          size='small'
          color='primary'
          variant='outlined'
          onClick={() => handleTagEdit()}
        >
          edit
        </Button>
        <TagEditor open={editDialogOpen} handleClose={handleTagEditClose} />
      </div>
    )
  }

  const columns = [
    {
      Header: function myHeader () {
        return (<div>Name<br />Mobile</div>)
      },
      accessor: function myAccessor (row, i) {
        return (<div onClick={() => handleOpenDialog(row)} className={classes.hover}>{row.name}<br />{row.mobile}</div>)
      },
      id: 1,
    },
    {
      accessor: (row, i) => {
        return `${_.round(row.ricecoin, 2)} RC`
      },
      Header: 'RiceCoin Balance',
      Cell: function ricecoinCell ({ value, row }) {
        return (<div onClick={() => handleRicecoinClick(row.original)} className={classes.hover}>{value}</div>)
      },
    },
    {
      accessor: (row, i) => {
        return `$${row.total} (${row.bought}單)`
      },
      Header: 'Spending',
      Cell: function spendingCell ({ value, row }) {
        return (<div onClick={() => handleSpendingClick(row.original)} className={classes.hover}>{value}</div>)
      },
    },
    {
      Header: 'Referrer',
      accessor: row => ReferrerCell(row),
    },
    {
      accessor: 'createdAt',
      Header: 'Join Date',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      Header: tagHeader,
      accessor: function myAccessor (row, i) {
        return (
          <TagSelector data={row} />
        )
      },
      id: 3,
      width: 300,
      maxWidth: 300,
    },
  ]
  const handleOpenDialog = (data) => {
    dispatch(actions.app.toggleDialog('customerDetail', null, data))
  }

  React.useEffect(() => {
    dispatch(actions.customer.getCustomers())
    dispatch(actions.customer.getTags())
  }, [])

  const onPageChange = (event, page) => {
    dispatch(actions.customer.updateQuery({ ...params, page: page + 1 }))
  }

  const handleRicecoinClick = (data) => {
    const { mobile } = data
    dispatch(actions.riceCoin.updateQuery({ mobile: mobile.replace('+852', ''), page: 1 }))
    history.push('/ricecoin')
  }

  const handleReferrerClick = (id, name) => {
    dispatch(actions.customer.updateReferrerName({ name: name }))
    dispatch(actions.customer.updateQuery({ referrer: id, page: 1 }))
  }

  const handleSpendingClick = (data) => {
    const { mobile } = data
    dispatch(actions.order.updateQuery({ phone: mobile.replace('+852', ''), page: 1 }))
    history.push('/order')
  }

  return (
    <>
      {params.referrer && <p className='mb-1 ml-2'><b>Referred customers from {referrerName}</b></p>}
      <ControlledTable
        columns={columns}
        data={customers}
        pageSize={meta.perPage}
        currentPage={(meta.currentPage - 1) || 0} // TablePagination is zero-based index
        rowCount={meta.total}
        onPageChange={onPageChange}
      />
    </>
  )
}

const useStyles = makeStyles(() => ({
  hover: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#1675e0',
    },
  },
  referrer: {
    display: 'flex',
    alignItems: 'center',
  },
  Icon: {
    width: 24,
    height: 24,
    fontSize: 16,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
