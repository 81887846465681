import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

Page.prototypes = {
  children: PropTypes.node,
}
export default function Page (props) {
  const classes = useStyles(props)
  const { children } = props

  return (
    <div className={classes.page}>
      {children && children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  page: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))
