import { Button, FormControl, Typography } from '@material-ui/core'
import { actions, useDispatch } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useMerchant } from '@/hooks/merchantContext'
import CollapsibleList from '@/components/CollapsibleList'
import SettingControl from '../SettingControl'

const NOTIFY_PLAY_COUNT_OPTIONS = [
  { label: '∞', value: -1 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
]

const SETTINGS = [
  { label: '員工版', paramaterName: 'staff', type: 'switch' },
  { label: 'CRM', paramaterName: 'enableCRM', type: 'switch' },
  { label: '自動確認 (外賣自取)', paramaterName: 'takeawayAutoConfirm', type: 'switch' },
  { label: '使用枱號建立時間（棄用，StaticQRCode 後付款使用第一次 submit 時間，其餘訂單一律使用檯號建立時間）', paramaterName: 'showOrderCreateAt', type: 'switch' },
  { label: '庫存', paramaterName: 'inventory', type: 'switch' },
  { label: '庫存檢查功能', paramaterName: 'checkInventory', type: 'switch' },
  { label: '隱藏「唔該」', paramaterName: 'disableHandUp', type: 'switch' },
  { label: '分類標籤', paramaterName: 'enableCategoryTag', type: 'switch' },
  { label: '餐廳外送', paramaterName: 'storeDelivery', type: 'switch' },
  { label: 'Waiter無時間限制', paramaterName: 'noTimeLimitForWaiterOrder', type: 'switch' },
  { label: '使用訂單建立時間', paramaterName: 'defaultUseOrderCreateTime', type: 'switch' },
  { label: 'Disable Log', paramaterName: 'disableLog', type: 'switch' },
  { label: 'MultiPayment', paramaterName: 'enableMultiPayment', type: 'switch' },
  { label: '任食mode', paramaterName: 'allYouCanEatMode', type: 'switch' },
  { label: '隱藏分枱功能', paramaterName: 'disableSeparateTable', type: 'switch' },
  { label: '酒店模式', paramaterName: 'hotelMode', type: 'switch' },
  { label: '放題不用付款模式(舊merchant使用)', paramaterName: 'hideOrderPayModal', type: 'switch' },
  { label: '快餐模式', paramaterName: 'quickMode', type: 'switch' },
  { label: '圖片點餐模式', paramaterName: 'orderPictureMode', type: 'switch' },
  { label: '啟用堂食動態QR (dynamic)', paramaterName: 'enableDynamicQRCode', type: 'switch' },
  { label: '啟用堂食固定QR (fixed)', paramaterName: 'enableStaticQRCode', type: 'switch' },
  { label: '外賣模式 (T+D)', paramaterName: 'enableTnDmode', type: 'switch' },
  { label: 'QRCode 下單 使用2.0客人版網頁', paramaterName: 'useCustomerWebV2', type: 'switch' },
  { label: '堂食隱藏「現在結帳」按鈕', paramaterName: 'dineinHidePayButton', type: 'switch' },
  { label: '同意分享數據', paramaterName: 'agreeToShareDataToGetCredit', type: 'switch' },
  { label: 'KDS', paramaterName: 'enableKDS', type: 'switch' },
  { label: '提示音次數', paramaterName: 'notifyPlayCount', type: 'select', items: NOTIFY_PLAY_COUNT_OPTIONS },
  { label: '外賣提示音次數', paramaterName: 'takeawayNotifyPlayCount', type: 'select', items: NOTIFY_PLAY_COUNT_OPTIONS },
  { label: '外送提示音次數', paramaterName: 'storeDeliveryNotifyPlayCount', type: 'select', items: NOTIFY_PLAY_COUNT_OPTIONS },
  { label: '訂單完結時間 (分鐘)', paramaterName: 'orderCutOffMins', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '外賣自取最長取餐時間 (分鐘)', paramaterName: 'takeawayMaxPickupMins', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '登入逾時時間 (分鐘)', paramaterName: 'loginSessionTimeout', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '店家電話', paramaterName: 'managerPhone', type: 'input' },
  { label: '報表密碼', paramaterName: 'reportPassword', type: 'input' },
  { label: '大型訂單提醒 ($)', paramaterName: 'bigOrderAlert', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: 'Remark', paramaterName: 'remark', type: 'input' },
  { label: '經緯度', paramaterName: 'geometry', type: 'input' },
]

/**
 *
 * @param {*} props
 * @returns
 */
export default function MerchantSettings (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [merchantState] = useMerchant()
  const [expand, setExpand] = React.useState(false)

  const handleExport = () => {
    dispatch(actions.merchant.exportMenu(merchantState.merchant.id))
  }

  return (
    <CollapsibleList title='Merchant 設定' expand={expand} setExpand={setExpand} className={classes.list}>
      {/* Export Menu */}
      <FormControl variant='outlined' margin='dense' fullWidth>
        <div className={classes.controlLine}>
          <Typography>匯出菜單</Typography>
          <Button
            disableElevation
            variant='contained'
            color='primary'
            onClick={handleExport}
          >匯出
          </Button>
        </div>
      </FormControl>

      {/* Merchant Settings */}
      {
        SETTINGS.map((setting, i) => {
          return (
            <SettingControl key={i} order={i} setting={setting} />
          )
        })
      }
    </CollapsibleList>
  )
}

const useStyles = makeStyles(theme => ({
  settings: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 24,
  },
  controlLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))
