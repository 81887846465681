
import React from 'react'

import CollapsibleList from '@/components/CollapsibleList'
import SettingControl from '../SettingControl'

const SETTINGS = [
  { label: '套餐「不需要」選項置底', paramaterName: 'notRequiredAtBottom', type: 'switch' },
  { label: '最低消費不計折扣', paramaterName: 'minChargeBeforeDiscount', type: 'switch' },
  { label: '最低消費', paramaterName: 'minCharge', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '落單間距 (秒)', paramaterName: 'batchInterval', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '外帶預訂時間 (天)', paramaterName: 'takeawayPreOrder', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '外送預訂時間 (天)', paramaterName: 'storeDeliveryPreOrder', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '外帶最長預訂日子 (天)', paramaterName: 'takeawayPreOrderPeriod', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '外送最長預訂日子 (天)', paramaterName: 'storeDeliveryPreOrderPeriod', type: 'input', inputOption: { type: 'number', min: 0 } },
  { label: '禁止食物備註', paramaterName: 'disableItemRemarks', type: 'switch' },
  { label: '堂食只限app使用', paramaterName: 'dineInForceUsingApp', type: 'switch' },
  { label: '客人下單前確認主機在線', paramaterName: 'confirmMasterOnlineBeforeSubmit', type: 'switch' },
  { label: '自訂客人下單頁面', paramaterName: 'customerLayout', type: 'input' },
  { label: '啓用「你可能會喜歡」', paramaterName: 'enableMenuRecommendation', type: 'switch' },
  { label: '隱藏歷史訂單按鈕', paramaterName: 'disableD2COrderHistoryButton', type: 'switch' },
  { label: '隱藏客人介面上所有價錢（機場貴賓室）', paramaterName: 'disableD2CUIPrice', type: 'switch' },
]

/**
 *
 * @param {*} props
 * @returns
 */
export default function OrderSettings (props) {
  const [expand, setExpand] = React.useState(false)

  return (
    <CollapsibleList title='下單設定' expand={expand} setExpand={setExpand}>
      {
        SETTINGS.map(setting => {
          return (
            <SettingControl key={setting.label} setting={setting} />
          )
        })
      }
    </CollapsibleList>
  )
}
